<!--
 * @Author: zyf
 * @Date: 2024-08-12 09:42:25
 * @LastEditors: zyf
 * @LastEditTime: 2024-09-19 11:28:45
 * @Descripttion: 
-->
<!--
    @name: ques-assemble
    @description：ques-assemble
    @author: ZengWei
    @date: 2022-04-01 11:21
-->
<template>
  <div class="widget-compose">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="sub-ques-type">
        组合题
      </div>
      <div class="q-title">
        <span class="ques-title">{{ element.config.__config__.label }}</span>
        <!-- <span v-if="!element.config.__config__.required" class="select-do">（选做题）</span> -->
        <!-- <span v-if="element.config.__ques__.answerScore" class="score">（{{element.config.__ques__.score}}分）</span> -->
      </div>
      <div
        class="q-title"
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc">
      </div>
    </div>
    <div class="q-option-list" :style="{'padding-left': `${zIndex * 24}px`}">
      <el-form
        ref="rowFormRef"
        :model="element"
      >
        <template v-for="(item, index) in element.child">
          <el-form-item
            :key="`sub${index}`"
            :prop="`child.${index}.value`"
            label-width="0px"
          >
            <QuestionItem :zIndex="zIndex + 1" :element="item" :number="index" :disabled="disabled" @change="calcScore" />
          </el-form-item>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
import formValidate from "@/components/answer/quesParser/scripts/quesParserFormat.js"
export default {
  name: "ques-compose",
  components: {
    QuestionItem: () => import('@/components/answer/quesParser/components/QuestionItem.vue'),
  },
  props: ['element','number','zIndex','disabled'],
  data() {
    return {}
  },
  watch: {
    'element.child': {
      handler(n) {
        this.element.score = 0
        this.element.value = []
        n.map(el => {
          this.element.score += (isNaN(el.score) ? 0 : Number(el.score))
          if (el.field) {
            this.element.value.push(el)
          } else if (Array.isArray(el.value)) {
            this.element.value.push(...el.value)
          }
        })
      },
      deep: true
    }
  },
  created() {
    if(!this.element?.isPaperLog){
       this.initChildQuestion()
    }
  },
  methods: {
    initChildQuestion() {
      this.$set(this.element, 'child', formValidate(this.element.config.__config__.children))
      this.$set(this.element, 'value', [])
    },
    calcScore(){
      this.$emit('change')
    }
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/safetyQuestionItem';
</style>
