var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'select-container': true,
    custom:
      _vm.element.config.__slot__.prepend || _vm.element.config.__slot__?.append,
  }},[(_vm.element.config.__slot__.prepend)?_c('div',{staticClass:"prepend",style:({
      border:
        _vm.disabled || _vm.element.config.disabled ? 'none' : '1px solid #dcdfe6',
    })},[_vm._v(" "+_vm._s(_vm.element.config.__slot__.prepend)+" ")]):_vm._e(),(_vm.disabled || _vm.element.config.disabled)?_c('div',{staticClass:"select-show-content"},[_c('div',{staticClass:"input-show"},[_vm._v(_vm._s(_vm.selectLabel))]),(_vm.element.config.__config__.outerViewer)?_c('el-button',{attrs:{"type":"primary","disabled":_vm.selectLabel === '-'},on:{"click":_vm.openOuterViewer}},[_vm._v("查看 ")]):_vm._e()],1):_c('div',{staticClass:"select-input",staticStyle:{"position":"relative","flex":"1"}},[_c('div',{class:{ 'outer-viewer': _vm.element.config.__config__.outerViewer }},[_c('el-select',{ref:"selectRef",attrs:{"disabled":_vm.disabled || _vm.element.config.disabled,"placeholder":_vm.element.config.placeholder,"clearable":_vm.element.config.clearable,"multiple":_vm.element.config.multiple,"filterable":_vm.element.config.filterable,"collapse-tags":"","loading":_vm.loading},on:{"focus":_vm.dynamicFilter},model:{value:(_vm.element.value),callback:function ($$v) {_vm.$set(_vm.element, "value", $$v)},expression:"element.value"}},[_vm._l((_vm.element.config.__slot__.options),function(val,key){return [(val.label.length > _vm.optionTextNum)?[_c('el-tooltip',{key:key,attrs:{"effect":"dark","content":val.label,"placement":"top"}},[_c('el-option',{key:key,attrs:{"value":_vm.optionValue(val.value),"label":val.label.toString()}},[_vm._v(" "+_vm._s(val.label.toString().substr(0, _vm.optionTextNum) + '...')+" ")])],1)]:[_c('el-option',{key:key,attrs:{"value":_vm.optionValue(val.value),"label":val.label.toString()}},[_vm._v(" "+_vm._s(val.label.toString())+" ")])]]})],2)],1),(_vm.element.config.__config__.outerViewer)?_c('el-button',{staticStyle:{"position":"absolute","top":"0","right":"0"},attrs:{"type":"primary"},on:{"click":_vm.openOuterViewer}},[_vm._v("查看 ")]):_vm._e()],1),(_vm.element.config.__slot__.append)?_c('div',{staticClass:"append",style:({
      border:
        _vm.disabled || _vm.element.config.disabled ? 'none' : '1px solid #dcdfe6',
    })},[_vm._v(" "+_vm._s(_vm.element.config.__slot__.append)+" ")]):_vm._e(),_c('el-dialog',{attrs:{"top":"5vh","custom-class":'common-dialog',"title":"外部内容查看","visible":_vm.outerViewerVisible,"width":_vm.outerViewerWidth,"append-to-body":""},on:{"update:visible":function($event){_vm.outerViewerVisible=$event}}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.outerViewerLoading),expression:"outerViewerLoading"}],staticStyle:{"height":"calc(100vh - 200px)"}},[_c('iframe',{ref:"iframe",staticStyle:{"width":"100%","height":"100%","border":"none"},attrs:{"src":_vm.outerRouter}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }