var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{ref:"childForm",class:{
    'form-parser': _vm.formData.tableStyle,
    'normal-parser': !_vm.formData.tableStyle,
  },attrs:{"size":"medium","model":_vm.element,"show-message":!_vm.formData.tableStyle,"label-width":_vm.formData.labelWidth + 'px',"label-position":_vm.formData.labelPosition}},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table-layout"},[_c('thead',[_c('tr',_vm._l((_vm.tableThData),function(colItem,colIdx){return _c('th',{key:colIdx,style:(_vm.thWidth(colItem))})}),0)]),_c('tbody',_vm._l((_vm.element.config.widget.rows),function(row,rowIdx){return _c('tr',{key:rowIdx},[_vm._l((row.cols),function(widget,colIdx){return [(!widget.merged)?_c('td',{key:widget.id,staticClass:"table-cell",style:({
                width: widget.options.cellWidth + '!important' || '',
                height: widget.options.cellHeight + '!important' || '',
              }),attrs:{"colspan":widget.options.colspan || 1,"rowspan":widget.options.rowspan || 1}},[_c('el-row',_vm._l((widget.widgetList),function(item,index){return _c('el-col',{key:item.config.__config__.formId,attrs:{"span":item.config.__config__.span}},[(_vm.showHideField(item.config.__config__))?_c('el-form-item',{attrs:{"prop":'config.widget.rows.' +
                      rowIdx +
                      '.cols.' +
                      colIdx +
                      '.widgetList.' +
                      index +
                      '.value',"rules":item.validate,"label":_vm.showLabel(item),"label-width":_vm.showLabel(item) === '' ? '0px' : '120px'}},[_c('InputItem',{key:item.config.__config__.formId,attrs:{"element":item,"disabled":_vm.canEditField(item.config.__config__.formId),"formData":_vm.formData,"editFields":_vm.editFields,"first-index":index},on:{"trigger-active":function($event){return _vm.triggerActive(
                          arguments,
                          _vm.element.config.__config__.formId,
                          _vm.tabInd
                        )}}})],1):_vm._e()],1)}),1)],1):_vm._e()]})],2)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }