<!--
 * @Author: zyf
 * @Date: 2024-08-12 09:42:25
 * @LastEditors: zyf
 * @LastEditTime: 2024-08-30 17:07:34
 * @Descripttion: 
-->
<!--
    @name: QuestionItem
    @description：QuestionItem
    @author: ZengWei
    @date: 2022-04-01 11:22
-->
<template>
  <div>
    <component
      :is="getWidgetName(element)"
      :element="element"
      :number="number"
      :zIndex="zIndex"
      :disabled="disabled"
      @change="calcScore"
      @changeVideo="changeVideo"
    />
  </div>
</template>

<script>
import QuesComponents from '@/components/answer/quesParser/components/widget'

export default {
  name: "QuestionItem",
  components: {
    ...QuesComponents
  },
  props: ['element','number','zIndex','disabled'],
  data() {
    return {}
  },
  methods: {
    getWidgetName(widget) {
      return `ques-${widget.config.__ques__.quesType}`
    },
    calcScore(){
      this.$emit('change')
    },
    changeVideo(val){
      this.$emit('changeVideo',val);
    }
  },
}
</script>

<style scoped>
</style>
