var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.initEnd && _vm.propObj)?_c('div',{staticClass:"common-tree",style:(_vm.wrapStyly)},[(_vm.statusConfig.showSearch)?_c('el-input',{staticClass:"search",attrs:{"prefix-icon":"el-icon-search","clearable":"","placeholder":_vm.statusConfig.placeholder},on:{"input":_vm.onFilter},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}):_vm._e(),(_vm.statusConfig.showCheckAll)?_c('section',{staticClass:"check-all"},[_c('el-checkbox',{on:{"change":_vm.onCheckAll},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}},[_vm._v(_vm._s(_vm.statusConfig.checkAllText))])],1):_vm._e(),(false)?[_vm._v(_vm._s(_vm.propObj))]:_vm._e(),_c('el-tree',{ref:"treeContent",staticClass:"tree-content",attrs:{"data":_vm.treeData,"default-expand-all":_vm.statusConfig.defaultExpandAll === undefined ||
        _vm.statusConfig.defaultExpandAll
          ? true
          : false,"expand-on-click-node":_vm.statusConfig.rowExpend === undefined ? true : _vm.statusConfig.rowExpend,"node-key":_vm.propObj.id || 'id',"empty-text":_vm.statusConfig.emptyText,"lazy":_vm.statusConfig.lazy,"load":_vm.loadNode,"highlight-current":"","check-on-click-node":_vm.statusConfig?.checkOnClickNode === undefined
          ? true
          : _vm.statusConfig?.checkOnClickNode,"filter-node-method":_vm.filterNode,"show-checkbox":_vm.statusConfig.showCheckBox,"default-expanded-keys":_vm.defaultExpandKeys,"check-strictly":_vm.statusConfig.checkStrictly === undefined
          ? true
          : _vm.statusConfig.checkStrictly,"props":{
        children: _vm.propObj.children,
        label: function (data, node) {
          return data[_vm.propObj.label];
        },
        disabled: _vm.statusConfig.disabled,
      }},on:{"node-click":_vm.onNodeClick,"check":_vm.onGetCheckNodes,"check-change":_vm.onCheckChange},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c(_vm.statusConfig?.useTooltip ? 'el-tooltip' : 'div',{tag:"component",staticClass:"tooltip-box",class:{
          'current-custom-tree-node':
            _vm.currentKey && data[_vm.propObj.id] && _vm.currentKey == data[_vm.propObj.id],
        },attrs:{"effect":"dark","content":data[_vm.propObj.label] || node.label,"placement":"right","open-delay":200}},[_c('span',{staticClass:"custom-tree-node",class:{
            'current-custom-tree-node':
              _vm.currentKey && data[_vm.propObj.id] && _vm.currentKey == data[_vm.propObj.id],
          },style:({
            'padding-right': !_vm.statusConfig.hideActions ? '25px' : '7px',
          })},[_c('div',{staticClass:"tooltip-box"},[_c('TreeIcon',{attrs:{"config":_vm.element.iconConfig || {},"rowData":data,"type":'pre',"componentData":_vm.subComponentData}}),_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(data[_vm.propObj.label] || node.label)),_c('span',{staticStyle:{"display":"none"}},[_vm._v("-"+_vm._s(_vm.renderCount))])]),(
                _vm.element.countNumberShow &&
                (!_vm.statusConfig.leafCode || !data?.[_vm.statusConfig.leafCode])
              )?_c('span',{staticClass:"tree-count-number",staticStyle:{"z-index":"100"}},[(+_vm.element.countNumberConfig?.numberDataType === 4)?[_c('span',{style:({
                    color:
                      _vm.element.countNumberConfig.masterColorPosition == '前'
                        ? _vm.element.countNumberConfig.masterColor
                        : '',
                  })},[_vm._v(_vm._s(_vm.element.countNumberConfig.preText)+" ")]),(_vm.element.countNumberConfig?.showPreNumber)?[_c('span',{style:({
                      color:
                        _vm.element.countNumberConfig.masterColorPosition == '0'
                          ? _vm.element.countNumberConfig.masterColor
                          : '',
                    })},[_vm._v(_vm._s(data[_vm.element.countNumberConfig?.proField]))]),_c('span',{style:({
                      color:
                        _vm.element.countNumberConfig.masterColorPosition == '/'
                          ? _vm.element.countNumberConfig.masterColor
                          : '',
                    })},[_vm._v("/")])]:_vm._e(),_c('span',{style:({
                    color:
                      _vm.element.countNumberConfig.masterColorPosition == '1'
                        ? _vm.element.countNumberConfig.masterColor
                        : '',
                  })},[_vm._v(_vm._s(data[_vm.element.countNumberConfig?.endField]))])]:(data.__number_count !== undefined)?[_c('span',{style:({
                    color:
                      _vm.element.countNumberConfig.masterColorPosition == '前'
                        ? _vm.element.countNumberConfig.masterColor
                        : '',
                  })},[_vm._v(_vm._s(_vm.element.countNumberConfig.preText)+" ")]),(
                    data.__pre_number_count !== undefined &&
                    data.__pre_number_count !== null
                  )?_c('span',{style:({
                    color:
                      _vm.element.countNumberConfig.masterColorPosition == '0'
                        ? _vm.element.countNumberConfig.masterColor
                        : '',
                  })},[_vm._v(_vm._s(data.__pre_number_count))]):_vm._e(),(
                    data.__pre_number_count !== undefined &&
                    data.__pre_number_count !== null
                  )?_c('span',{style:({
                    color:
                      _vm.element.countNumberConfig.masterColorPosition == '/'
                        ? _vm.element.countNumberConfig.masterColor
                        : '',
                  })},[_vm._v("/")]):_vm._e(),_c('span',{style:({
                    color:
                      _vm.element.countNumberConfig.masterColorPosition == '1'
                        ? _vm.element.countNumberConfig.masterColor
                        : '',
                  })},[_vm._v(_vm._s(data.__number_count))])]:_vm._e()],2):_vm._e(),(
                _vm.judgingArchiAPermission(
                  _vm.actionLimit.archiLimit,
                  _vm.actionLimit.permission
                ) && _vm.judgingUILogic(_vm.actionLimit.UILogic, _vm.subComponentData, data)
              )?[(
                  _vm.statusConfig.appendChild && _vm.validDomLogic('appendChild', data)
                )?_c('el-button',{staticClass:"action-btn plus-child",attrs:{"type":"text","icon":"el-icon-plus"},on:{"click":function($event){_vm.doAction(
                    _vm.actionConfig.find((ele) => ele.key === 'appendChild'),
                    data,
                    $event
                  )}}}):_vm._e(),(_vm.outSideActionList.length)?_vm._l((_vm.outSideActionList),function(outAction,index){return (_vm.validDomLogic(outAction.key, data))?_c('section',{key:index,staticClass:"out-side-actions",on:{"click":function($event){return _vm.doAction(outAction, data, $event)}}},[(outAction.icon && outAction.icon.includes('http'))?_c('el-image',{attrs:{"src":outAction.icon}}):_c('i',{class:['iconfont', outAction.icon],style:({
                      color: outAction.color,
                    })})],1):_vm._e()}):_vm._e(),(!_vm.statusConfig.hideActions)?_c('span',{staticClass:"action-content",class:{
                  'action-content-hover': _vm.statusConfig?.defaultHideAction,
                }},[_c('el-dropdown',{attrs:{"placement":"bottom-end"},on:{"command":_vm.handleCommand}},[_c('TreeIcon',{attrs:{"config":_vm.element.iconConfig || {},"rowData":data,"type":'action',"componentData":_vm.subComponentData},on:{"click":function($event){return _vm.onAction(data)}}}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_vm._l((_vm.actionConfig),function(item,index){return (
                        item.type !== 'appendChild' &&
                        !_vm.specialBtn.includes(item.type) &&
                        !item.outSide &&
                        _vm.validDomLogic(item.type, data)
                      )?_c('el-dropdown-item',{key:index,staticClass:"action-item",style:({
                        color: item.color,
                      }),attrs:{"command":{ key: 'action', value: { item, data } }}},[_vm._v(_vm._s(item.name))]):_vm._e()}),(
                        _vm.judgingArchiAPermission(
                          _vm.deleteLimit.archiLimit,
                          _vm.deleteLimit.permission,
                          _vm.element,
                          {
                            name: '删除',
                          }
                        ) &&
                        _vm.judgingUILogic(
                          _vm.deleteLimit.UILogic,
                          _vm.subComponentData,
                          data
                        )
                      )?_c('el-dropdown-item',{staticClass:"action-item remove",staticStyle:{"color":"#f56c6c"},attrs:{"command":{ key: 'delete', value: data }}},[_vm._v("删除")]):_vm._e()],2)],1)],1):_vm._e()]:_vm._e()],2)])])}}],null,false,63405800)}),(_vm.showSheet && _vm.sheetFormData)?_c('Spreadsheet',{attrs:{"type":_vm.spreadsheetType,"excelUuid":_vm.exportConfig.exportTemplate,"objectUuid":_vm.exportConfig.interfaceUrl || _vm.exportConfig.objectUUID,"viewUuid":_vm.exportConfig.viewUUID,"dataIds":_vm.sheetFormData,"exportType":_vm.exportConfig.exportTypeSheet || 'pdf',"viewSearch":_vm.exportViewSearch,"viewQuery":_vm.exportViewParams,"extraParams":_vm.exportParam || {}},on:{"exportSuccess":_vm.onExportSuccess,"exportError":_vm.onExportError}}):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }