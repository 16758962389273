<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2023-08-29 11:43:15
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-11 16:29:21
 * @FilePath: /dataview-next/src/custom-component/form/CommonCount.vue
 * @Description: 计数器组件
-->
<template>
	<div class="common-count">
		<!-- <h3 class="number">{{ count }}</h3> -->
		<!-- <h3 class="number">{{ componentValue }}</h3> -->
		<ul class="number-list">
			<li
				v-for="(item, index) in numberList"
				:key="index"
				class="item"
				:style="getItemStyle(+item, index === numberList.length - 1)"
			>
				{{ item }}
			</li>
		</ul>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import { removeEventBus, getComponentById, initParams } from '@/utils/tools';

export default {
	name: 'CommonCount',
	props: {
		// 绑定的值
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		fullData: {
			// type: Object
		},
		isGroup: {
			type: Boolean
		},
		groupComponents: {
			type: Array,
			default: () => []
		},
		componentList: {
			default: null
		},
		// 循环映射值
		mapData: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			// count: 88.88,
			numberList: [],
			interval: null,
			componentValue: 0
		};
	},
	inject: ['EDITOR_pageUUID'],
	computed: {
		...mapState(['componentData', 'signComponentData', 'subsidiaryComponentData', 'EDITORDev']),
		// 取值组件列表
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentData || [];
			}
			return this.componentData || [];
		},
		// 组件属性配置
		statusConfig() {
			return this.element?.statusConfig;
		},
		// 数据仓库
		database() {
			return this.element.database;
		},
		// 字段对象
		dataObj() {
			if (!this.database?.containerKey) return {};
			let container = getComponentById(this.subComponentData, this.database.containerKey);
			if (!container && this.isGroup && this.groupComponents.length) {
				container = getComponentById(this.groupComponents, this.database.containerKey);
			}
			if ((!container || !container.containerData) && !this.fullData) return {};
			// 列表情况默认返回一个
			if (Array.isArray(container.containerData) && container.containerData.length) {
				return container.containerData?.[0] || {};
			}
			// 列表有分页和不分页情况
			if (this.fullData) {
				if (Array.isArray(this.fullData)) {
					// 分页
					return this.fullData[0] || {};
				} else if (Array.isArray(this.fullData?.data)) {
					// 不分页
					return this.fullData?.data?.[0] || {};
				}
				return this.fullData;
			}
			const result = container?.containerData || this.fullData;
			return result;
		},
		// 绑定的uuid
		bindUUID() {
			return this.element?.database?.bindUUID ?? '';
		},
		// 用于获取绑定的数据字典(描述文字)
		metaData() {
			if (!this.element?.database) return null;
			const { fieldList, bindUUID } = this.element.database;
			return fieldList.find((ele) => ele.uuid === bindUUID);
		},
		// 数据绑定类型
		databaseType() {
			if (!this.element?.database) return 'value';
			return this.element.database.databaseType ?? 'value';
		},
		// 特殊取值配置
		countConfig() {
			return this.element?.countConfig;
		}
		// 是否编辑器环境
	},
	updated() {
		this.toResolveData();
	},
	mounted() {
		// 配置关联参数的容器才需要监听
		const databaseTrigger = {
			[this.element.id]: (data) => {
				const paramsConfig = this.countConfig.params.paramsConfig;
				if (!Array.isArray(paramsConfig) || !paramsConfig.length) return;
				for (let i = 0; i < paramsConfig.length; i++) {
					if (paramsConfig[i]?.componentId === data.componentId) {
						this.renderCount();
						return;
					}
				}
			}
		};
		eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
	},
	watch: {
		componentValue: {
			handler(newVal) {
				if (this.interval) {
					clearInterval(this.interval);
				}
				/* eslint-disable */
				// 动画
				let stepTime = +this.statusConfig?.stepTime;
				let stepCount = +this.statusConfig?.stepCount;
				if (stepTime > 0 && stepCount > 0) {
					if (+newVal < stepCount * 20 || !newVal) {
						stepCount = 1;
					}
					const times = +newVal / stepCount || 1;
					let startVal = 0;
					this.interval = setInterval(() => {
						if (startVal < newVal - stepCount) {
							startVal += stepCount;
						} else {
							startVal = newVal;
						}
						this.numberList = this.getNumberList(+startVal);
						if (+startVal === +newVal) {
							clearInterval(this.interval);
						}
					}, stepTime / times);
				} else {
					this.numberList = this.getNumberList(+newVal);
				}
			},
			immediate: true
		},
		countConfig: {
			handler() {
				if (!this.EDITORDev) return;
				this.renderCount();
			},
			deep: true
		},
		statusConfig() {
			if (!this.EDITORDev) return;
			this.renderCount();
		}
	},
	created() {
		this.renderCount();
	},
	methods: {
		/**
		 * @description: 渲染数量
		 * @return {Number} count 数字
		 */
		renderCount() {
			if (!this.countConfig || !this.countConfig.custom) {
				this.componentValue = this.getComponentValue();
				return;
			}
			this.componentValue = 0;
			this.getCustomConfig();
		},
		/**
		 * @description: 获取自定义配置取值
		 * @return {Number} 当前取值
		 */
		getCustomConfig() {
			if (!this.countConfig || !this.countConfig?.custom) return;
			const { custom, type, params = {}, config = {} } = this.countConfig;
			const { paramsConfig = [] } = params;
			const { param, canPost } = initParams(
				paramsConfig,
				this.isGroup,
				this.subComponentData,
				this.groupComponents,
				this.EDITOR_pageUUID
			);
			console.log(param, '---param----');
			const { startTime, endTime } = param;
			const startDate = this.getDateObj(startTime);
			const endDate = this.getDateObj(endTime);
			if (!canPost) {
				return;
			}
			if (type === 'date') {
				// 日期
				if (!startDate || !endDate) return;
				this.getDateCount(startDate, endDate, config || {});
				return;
			}
		},
		/**
		 * @description:
		 * @param {Number} time 获取时间间隔
		 * @param {Number} config 更新间隔时间
		 */
		getDateCount(startDate, endDate, { format, between }) {
			if (between && between > 0) {
				setInterval(() => {
					this.componentValue = this.turnTimeToStr(startDate, endDate, format);
				}, between);
				return;
			}
			this.componentValue = this.turnTimeToStr(startDate, endDate, format);
		},
		/**
		 * @description: 格式化时间为
		 * @param {Date} startDate
		 * @param {Date} endDate
		 * @param {String} format
		 */
		turnTimeToStr(startDate, endDate, format) {
			const time = endDate - startDate;
			if (!time || isNaN(time) || time < 0) return 0;
			let result = 0;
			switch (format) {
				case 'YMDhms':
					break;
				case 'YMDhm':
					break;
				case 'YMD':
					break;
				case 'hms':
					result = ``;
					break;
				case 'hm':
					break;
				case 's':
					result = Math.floor(time / 1000);
					break;
				case 'week':
					result = Math.floor(time / 1000);
					break;
				case 'year':
					result = Math.floor(time / 604800000);
					break;
				case 'month':
					// 每月按30天计算
					result = Math.floor(time / 2592000000);
					break;
				case 'day':
					result = Math.floor(time / 86400000);
					break;
				case 'hour':
					result = Math.floor(time / 3600000);
					break;
				default:
					// 默认按天
					result = Math.floor(time / 86400000);
					break;
			}
			return result;
		},
		/**
		 * @description: 获取常规取值的配置
		 * @return {Number} 当前取值
		 */
		getComponentValue() {
			const valueOrigin = this.element?.valueOrign;
			if (valueOrigin === 'fixed') {
				return this.element.propValue;
			}
			if (valueOrigin === 'component') {
				return this.getValueFromComponent(this.element.componentValueConfig);
			}
			if (valueOrigin === 'database') {
				if (!this.dataObj || !this.bindUUID) return 0;
				if (this.databaseType === 'value') {
					// 绑定值
					return this.dataObj[this.bindUUID] || 0;
				}
				if (this.databaseType === 'wordbook' && this.metaData) {
					// 数据字典(描述文字)
					return this.metaData.name || 0;
				}
				return 0;
			}
			return 0;
		},
		/**
		 * @description: 判断字符串是否为一个标准时间格式
		 * @param {any} val
		 * @return {Boolean}
		 */
		getDateObj(obj) {
			let value = obj;
			if (Array.isArray(obj) && obj.length) {
				value = obj[0];
			}
			if (Object.prototype.toString.call(value) === '[object Date]') return value;
			if (!value || Object.prototype.toString.call(value) !== '[object String]') return null;
			if (value === 'now') return new Date();
			try {
				const date = new Date(value);
				return date;
			} catch (err) {
				console.log(err);
				return null;
			}
		},
		/**
		 * @description: 获取数字样式
		 * @param {Number/ any} val
		 * @param {Boolean} isLast 是否最后一位
		 * @return {Object} 样式对象
		 */
		getItemStyle(val, isLast = false) {
			if (!this.statusConfig) return {};
			const {
				fontSize = 64,
				margin = 12,
				// bit = 'auto', // 位数 auto, 不补零  Number 位数需要大于数字长度
				// zeroFill: false, // 补零
				// useBorder: false, // 边框
				borderColor = '#abc8ff',
				borderSize = 1,
				borderMargin = 8,
				borderStyle = 'solid',
				radius = 0
			} = this.statusConfig;
			const { height } = this.element?.style;
			let heightString = `${isNaN(+height) ? height : height + 'px'}`;
			let result = {
				fontSize: `${fontSize}px`,
				padding: `0 ${margin}px`,
				borderRadius: radius > 0 ? `${radius}px` : 0,
				marginRight: `${isLast ? 0 : borderMargin}px`,
				borderColor,
				borderStyle,
				height: heightString,
				lineHeight: heightString
			};
			if (isNaN(val)) {
				return result;
			}
			const borderWidth = `${borderSize > 0 ? borderSize : 0}px`;
			return {
				...result,
				borderWidth: borderWidth,
				heightString: `calc(${heightString - borderWidth})`
			};
		},
		/**
		 * @desc: 暴露数据
		 */
		toResolveData() {
			const { valueOrign = 'fixed', componentValueConfig = {} } = this.element;
			if (valueOrign === 'fixed') {
				// 固定值
				this.element.resolveData = {
					value: this.element.propValue || ''
				};
			} else if (valueOrign === 'component') {
				// 其他组件
				this.element.resolveData = {
					value: this._getValueFromComponent(componentValueConfig)
				};
			} else if (valueOrign === 'database') {
				// 数据仓库
				this.element.resolveData = {
					value: this._getDatabaseData()
				};
			}
			this.$store.commit('updatePageCustomStatus', {
				origin: this.element,
				resolveData: this.element.resolveData
			});
		},
		/**
		 * @description: 来源于组件的值
		 * @param {Object} config
		 * @return {any}
		 */
		getValueFromComponent(config) {
			if (!config || !config.component || !config.field || (!this.componentData && !this.componentList)) return '';
			let component = getComponentById(this.componentList || this.componentData, config.component);
			if (!component && !this.isGroup && this.signComponentData.length) {
				//标记的取值
				component = getComponentById(this.signComponentData, config.component);
			}
			if (!component && this.isGroup && this.groupComponents.length) {
				component = getComponentById(this.groupComponents, config.component);
			}
			if (!component) return '';
			if (config.field === 'propValue') {
				return component.propValue;
			}
			if (!component.customStatus) return '';
			return component.customStatus[config.field];
		},
		/**
		 * @desc: 获取数仓数据
		 */
		_getDatabaseData() {
			if (!this.dataObj || !this.bindUUID) return 0;
			if (this.databaseType === 'value') {
				// 绑定值
				return this.dataObj[this.bindUUID] || 0;
			}
			if (this.databaseType === 'wordbook' && this.metaData) {
				// 数据字典(描述文字)
				return this.metaData.name || 0;
			}
			return '';
		},
		/**
		 * @description: 更具数字获取列表
		 * @param {any/Number} val
		 */
		getNumberList(val) {
			const { bit = 'auto' } = this.statusConfig;
			const list = [];
			const len = (!isNaN(+bit) && bit >= val.toString().length) || isNaN(+val) ? bit : val.toString().length;
			for (let i = 0; i < len; i++) {
				list.push(0);
			}
			if (isNaN(+val) || !val) {
				return list;
			}
			const valArr = (val + '').split('').reverse();
			const listLen = list.length;
			for (let j = 0; j < valArr.length; j++) {
				list.splice(listLen - j - 1, 1, valArr[j]);
			}
			return list;
		}
	},
	beforeDestroy() {
		removeEventBus(['databaseTrigger'], this.element.id);
		if (this.interval) {
			clearInterval(this.interval);
		}
	}
};
</script>

<style lang="less" scoped>
.common-count {
	width: 100%;
	height: 100%;
	.number {
		// letter-spacing: 80px;
		line-height: 84px;
		font-size: 64px;
	}
	.number-list {
		width: 100%;
		display: flex;
		overflow: hidden;
		flex-wrap: nowrap;
		transition: all 0.1s;
		.item {
			width: auto;
			font-size: 64px;
			font-weight: bold;
			line-height: 84px;
			box-sizing: border-box;
			&.is-number {
				// flex: 1;
			}
		}
	}
}
</style>
