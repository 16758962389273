<!--
    @name: widget-treeSelect
    @description：widget-treeSelect
    @author: ZengWei
    @date: 2022-03-25 09:43
-->
<template>
  <div>
    <div v-if="disabled || element.config.disabled" class="input-show">
      {{ treeSelectLabel ? treeSelectLabel : '-' }}
    </div>
    <div v-else>
      <span style="display: none">{{ treeSelectLabel }}</span>
      <el-select
        class="tree-select"
        ref="select"
        v-model="treeSelectValue"
        placeholder="请选择"
        :disabled="disabled || element.config.disabled"
        :multiple="element.config.props.props.multiple"
        collapse-tags
        :filterable="true"
        :filter-method="selectFilter"
        @change="triggerFlowBranch"
        @visible-change="visibleChange"
      >
        <el-option :value="element.value" class="tree-select-option is-current">
          <el-tree
            ref="treeSelect"
            :data="element.config.options"
            :default-expand-all="element.config.__config__?.expand || isExpand"
            :show-checkbox="element.config.props.props.multiple"
            node-key="id"
            highlight-current
            :expand-on-click-node="false"
            :filter-node-method="filterNode"
            @node-click="treeSelectClick"
            @check-change="handleCheckChange"
          >
            <span
              v-if="node.label.toString().length > optionTextNum"
              slot-scope="{ node }"
            >
              <el-tooltip effect="dark" :content="node?.label" placement="top">
                <span>{{
                  node?.label.toString().substr(0, optionTextNum) + '...'
                }}</span>
              </el-tooltip>
            </span>
            <span v-else>{{ node.label.toString() }}</span>
          </el-tree>
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Tree, Tooltip } from 'element-ui';
import formItemMinix from '@/custom-component/form/newParser/scripts/formItemMinix';
import {
  getBodyParams,
  getSearchData,
  optionData,
} from '@/custom-component/form/newParser/scripts/tools';
import { formRequest } from '@/apis/data/form';
import eventBus from '@/plugins/eventBus';

export default {
  name: 'WidgetTreeSelect',
  components: {
    'el-tree': Tree,
    'el-tooltip': Tooltip,
  },
  props: ['element', 'disabled', 'displayData'],
  mixins: [formItemMinix],
  data() {
    return {
      treeSelectValue: '',
      optionTextNum: 20,
      isExpand: false,
    };
  },
  computed: {
    treeSelectLabel() {
      const value = this.element.value;
      const treeData = this.element.config.options;
      const multiple = this.element.config.props.props.multiple;
      let labelData;
      value instanceof Array ? (labelData = []) : (labelData = '');

      function getTree(data) {
        for (let item of data) {
          if (value instanceof Array && value.includes(item.id)) {
            labelData.push(item.label);
          } else if (value == item.id) {
            labelData = item.label;
          }
          if (item.children && item.children.length > 0) {
            getTree(item.children);
          }
        }
      }

      getTree(treeData);
      if (this.$refs.treeSelect) {
        this.$nextTick(() => {
          if (!Array.isArray(value)) {
            this.$refs.treeSelect?.setCurrentKey(+value);
            this.isExpand = true;
            for (
              let i = 0;
              i < this.$refs.treeSelect.store._getAllNodes().length;
              i++
            ) {
              this.$refs.treeSelect.store._getAllNodes()[i].expanded =
                this.isExpand;
            }
          }
        });
      }
      this.treeSelectValue = labelData;
      if (labelData) {
        if (multiple) {
          // 多选
          if (labelData instanceof Array && labelData?.length) {
            return labelData.join(' ; ');
          } else {
            return '-';
          }
        } else {
          return labelData;
        }
      } else {
        return '-';
      }
    },
  },
  created() {
    this.initDynamicData();
    const cascadeFormId = this.element.config.__config__?.cascadeFormId || null;
    if (cascadeFormId) {
      this.$emit(
        'trigger-active',
        cascadeFormId,
        'cascader',
        this.element.value
      );
    }
  },
  mounted() {
    if (this.$refs?.select?.$el) {
      // 根据控件长度，计算最多可以展示多少字符，选项不能超出控件长度 @蒲亚军、蒋冬梅、刘刚、凌志华都提过这个需求
      const selectWidth = this.$refs.select.$el.clientWidth;
      this.optionTextNum = parseInt(selectWidth / 15);
    }
    const value = this.element.value;
    const multiple = this.element.config.props.props.multiple;
    if (multiple && this.$refs.treeSelect) {
      this.$nextTick(() => {
        this.$refs.treeSelect?.setCheckedKeys(value);
      });
    }
  },
  methods: {
    initDynamicData() {
      const filterRule = this.element.config.__config__.filterRule ?? [];
      const objectUuid = this.element.config.__config__.objectUuid;
      const viewUuid = this.element.config.__config__.viewUuid;

      let url = '/api/mapi',
        params;
      let searchData = getSearchData(filterRule, this.parser.getFormIdValue);
      params = {
        __method_name__: 'dataList',
        object_uuid: objectUuid,
        view_uuid: viewUuid,
        search: searchData,
        size: 1000,
        __now_archi_type: this.parser.nowArchiType,
      };
      if (searchData.length) params.search = searchData;
      let bodyParams = getBodyParams(filterRule, this.parser.getFormIdValue);
      if (Object.keys(bodyParams).length) {
        params = Object.assign(params, bodyParams);
      }
      if (objectUuid && viewUuid) {
        formRequest('post', url, params).then((res) => {
          let respData;
          respData = res.data.data;
          if (res.data.data.data) {
            respData = res.data.data.data;
          }

          const tag = this.element.config.__config__.tagIcon;
          const propLabel = this.element.config.props.props.label;
          const propValue = this.element.config.props.props.value;
          const propChild = this.element.config.props.props.children || [];
          const options = optionData(
            respData,
            tag,
            propLabel,
            propValue,
            propChild
          );
          if (options) {
            this.element.config.options = options;
          }
          this.loading = false;
        });
      }
    },
    visibleChange(e) {
      // if (e) {
      //   let selectDom = document.querySelector('.is-current')
      //   setTimeout(() => {
      //     this.$refs.select.scrollToOption({$el: selectDom})
      //   }, 0)
      // }
    },
    treeSelectClick(data) {
      if (!this.element.config.props.props.multiple) {
        eventBus.$emit(
          'TRIGGER_treeSelectChange',
          this.element.config.__config__.formId
        );
        // this.treeSelectValue = data.label
        this.element.value = data.id.toString();
        // treeSelect 单选事件填充
        let selectFill = this.element.config.__config__?.selectFill;
        if (selectFill === undefined) selectFill = true;
        if (selectFill) {
          this.$emit('trigger-active', [], 'selectFill', data.allField);
        }
        // treeSelect 部位参数
        const cascadeFormId =
          this.element.config.__config__?.cascadeFormId || null;
        if (cascadeFormId) {
          this.$emit(
            'trigger-active',
            cascadeFormId,
            'cascader',
            this.element.value
          );
        }
      }
    },
    handleCheckChange() {
      let res = this.$refs.treeSelect.getCheckedNodes(true, true);
      let arr = [];
      res.forEach((item) => {
        arr.push(item.id);
      });
      this.element.value = arr;
    },
    selectFilter(val) {
      this.$refs.treeSelect.filter(val);
    },
    filterNode(value, data, node) {
      if (!value) return true;
      if (data.label) {
        return this.chooseNode(value, data, node);
      }
    },
    // 过滤父节点 / 子节点 (如果输入的参数是父节点且能匹配，则返回该节点以及其下的所有子节点；如果参数是子节点，则返回该节点的父节点。name是中文字符，enName是英文字符.
    chooseNode(value, data, node) {
      if (data.label.indexOf(value) !== -1) {
        return true;
      }
      const level = node.level;
      // 如果传入的节点本身就是一级节点就不用校验了
      if (level === 1) {
        return false;
      }
      // 先取当前节点的父节点
      let parentData = node.parent;
      // 遍历当前节点的父节点
      let index = 0;
      while (index < level - 1) {
        // 如果匹配到直接返回，此处name值是中文字符，enName是英文字符。判断匹配中英文过滤
        if (parentData.data.label.indexOf(value) !== -1) {
          return true;
        }
        // 否则的话再往上一层做匹配
        parentData = parentData.parent;
        index++;
      }
      // 没匹配到返回false
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.tree-select {
  width: 100%;
}
.tree-select-option {
  height: auto;
  background: #ffffff !important;

  :deep(.el-tree) {
    padding-bottom: 15px;

    .el-tree-node__label {
      font-weight: normal;
    }
  }
}
.tree-select-details {
  width: 100%;
  padding: 5px 12px;
  box-sizing: border-box;
  background: #f2f4f7;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #2a2f3d;
  line-height: 27px;
  cursor: default;
}
</style>
