<!--
 * @Author: qinmengyuan
 * @Date: 2024-08-14 16:38:00
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-10-23 10:29:49
 * @FilePath: \dataview-viewer-test\src\custom-component\form\newParser\FormItem\widget-number.vue
-->
<!--
    @name: widget-number
    @description：widget-number
    @author: ZengWei
    @date: 2022-03-25 09:26
-->
<template>
  <div
    :class="{
      'input-number-wrap': true,
      custom:
        element.config.__slot__?.prepend || element.config?.__slot__?.append,
    }"
  >
    <div
      class="prepend"
      v-if="element.config.__slot__?.prepend"
      :style="{
        border:
          disabled || element.config.disabled ? 'none' : '1px solid #dcdfe6',
      }"
    >
      {{ element.config.__slot__?.prepend }}
    </div>
    <div v-if="disabled || element.config.disabled" class="input-show">
      {{ element.value ? element.value : '0' }}
    </div>
    <el-input-number
      v-else
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      :controls="element.config.controls"
      :controls-position="element.config['controls-position']"
      :min="element.config.min"
      :max="element.config.max"
      :precision="element.config.precision"
      :step="element.config.step"
      @change="onChange"
    ></el-input-number>
    <div
      class="append"
      v-if="element.config.__slot__?.append"
      :style="{
        border:
          disabled || element.config.disabled ? 'none' : '1px solid #dcdfe6',
      }"
    >
      {{ element.config.__slot__?.append }}
    </div>
  </div>
</template>

<script>
import formItemMinix from '@/custom-component/form/newParser/scripts/formItemMinix';
import { InputNumber } from 'element-ui';
import { toFixed } from '@/utils/tools';

export default {
  name: 'WidgetNumber',
  components: {
    'el-input-number': InputNumber,
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {};
  },
  methods: {
    onChange(value) {
      this.$emit('change-calc');
      if (value < this.element.config.min) {
        value = this.element.config.min;
      } else if (value > this.element.config.max) {
        value = this.element.config.max;
      }
      if (this.element.config.__config__?.numberType) {
        let type = this.element.config.__config__.numberType;
        let prec = 1;
        let valuePos = value.toString().split('.');
        if (valuePos.length > 1) {
          for (let i = 0; i < valuePos[1].length; i++) {
            prec = prec * 10; // 小数精度处理
          }
        }
        value = Number(value);
        if (type === 1)
          value = Math.floor((value * prec).toPrecision(12)) / prec; // 向下取整
        if (type === 2)
          value = Math.ceil((value * prec).toPrecision(12)) / prec; // 向上取整
        if (type === 3)
          value = Math.round((value * prec).toPrecision(12)) / prec; // 四舍五入
      }
      if (this.element.config.precision) {
        let precision = this.element.config.precision;
        value = toFixed(value, precision); // toFixed 银行家算法 四舍五入存在问题
        if (value.indexOf('NaN') > -1) {
          value = Number('0').toFixed(precision);
        }
      }

      console.log(value, 9999);
      this.element.value = value;
    },
  },
};
</script>

<style lang="less" scoped>
.input-number-wrap {
  display: flex;
  align-items: center;
  .input-show {
    flex: 1;
  }
  :deep(.el-input-number) {
    border: 1px solid #eaebed;
    border-radius: 5px;
    width: 100%;
    input {
      text-align: left;
    }
  }
  .prepend {
    background: #f5f7fa;
    padding: 0 20px;
    border: 1px solid #dcdfe6;
    border-radius: 4px 0 0 4px;
    box-sizing: border-box;
    height: 36px;
  }
  .append {
    background: #f5f7fa;
    padding: 0 20px;
    border: 1px solid #dcdfe6;
    border-radius: 0 4px 4px 0;
    box-sizing: border-box;
    height: 36px;
  }
}
.custom {
  :deep(.el-input-number) {
    border: none;
    flex: 1;
    input {
      text-align: left;
    }
  }
  :deep(.el-input__inner) {
    border-radius: 0 !important;
  }
  .input-show {
    border-radius: 0;
  }
}
</style>
