<!--
 * @Description: 月周选择器
 * @Author: luocheng
 * @Date: 2022-08-01 10:22:50
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-09-18 15:37:19
-->
<template>
  <div class="common-date month-week">
    <CalendarHeader
      :year="year"
      :month="month"
      @setQuickDate="getQuickDate"
    ></CalendarHeader>
    <article class="content" v-if="activeWeek">
      <ul class="date-list" v-if="showDateList && showDateList.length">
        <li
          class="date-item"
          v-for="(item, index) in showDateList"
          :key="index"
          :class="{
            'is-today': item.isTargetWeek,
            'is-active': activeWeek && isActiveWeek(item),
          }"
          @click="onWeek(item)"
        >
          <!-- 基础类型 -->
          <!-- || item.type !== 'target' -->
          <div class="base-item" v-if="calendarType === 'base'">
            {{ item.isTargetWeek ? '本周' : item.desc }}
          </div>
          <!-- 符合类型 -->
          <div
            class="with-data-item"
            v-else-if="
              calendarType === 'withDatabase' ||
              calendarType === 'justTotal' ||
              calendarType === 'showDot'
            "
          >
            <p class="date-text">
              {{ item.isTargetWeek ? '本周' : item.desc }}
            </p>
            <p class="count-text">
              <span
                class="finish-count"
                v-if="calendarType !== 'showDot'"
                :class="{
                  'just-total': calendarType === 'justTotal',
                  'null-count': !+item.finishCount,
                }"
                >{{ item.finishCount }}</span
              >
              <template v-if="calendarType === 'withDatabase'">
                <span
                  class="separate"
                  :class="{
                    'null-count': !+item.totalCount && !+item.finishCount,
                  }"
                  >/</span
                >
                <span
                  class="total-count"
                  :class="{
                    'null-count': !+item.totalCount,
                  }"
                  >{{ item.totalCount }}</span
                >
              </template>
              <template v-if="calendarType === 'showDot'">
                <div
                  class="dot"
                  v-if="+item.finishCount > 0 ? haveData : noData"
                  :style="{
                    background:
                      +item.finishCount > 0 ? haveDataColor : noDataColor,
                  }"
                ></div>
              </template>
            </p>
          </div>
        </li>
      </ul>
      <el-empty v-else description="暂无数据"></el-empty>
    </article>
  </div>
</template>

<script>
import mixin from './mixin';
import CalendarHeader from './CalendarHeader';

export default {
  name: 'MonthWeek',
  mixins: [mixin],
  components: {
    CalendarHeader,
  },
  data() {
    return {
      year: '',
      month: '',
      // 当前周
      currentWeekObj: null,
      // 当前选中周
      activeWeek: null,
      weekList: [],
    };
  },
  created() {
    this.initDate(true);
  },
  methods: {
    /**
     * @desc: 初始时间
     * @param {Boolean} isInit
     */
    initDate(isInit = false) {
      this.weekList = [];
      const now = new Date();
      const date = isInit
        ? new Date()
        : new Date(`${this.year}/${this.month}/1`);
      if (isInit) {
        this.year = date.getFullYear();
        this.month = this.addZero(date.getMonth() + 1);
        const day = date.getDate();
        const week = `第${this.getMonthWeek(this.year, this.month, day)}周`;
        this.currentWeekObj = {
          isTargetWeek: true,
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          date: day,
          totalCount: 0,
          finishCount: 0,
          lessCount: 0,
          desc: week,
          dateString:
            `${new Date().getFullYear()}年第${new Date().getMonth() + 1}月` +
            week,
          result: this.getWeekResult(this.year, this.month, day),
        };
        this.onWeek(this.currentWeekObj);
      }
      if (
        (this.year === now.getFullYear() && +this.month > now.getMonth() + 1) ||
        this.year > now.getFullYear()
      ) {
        return false;
      }
      // 本月数据
      const firstDate = new Date(`${this.year}-${this.month}-1 00:00:00`);
      const endDate = new Date();
      const lastDate = new Date(
        `${this.year}-${endDate.getMonth() + 1}-${endDate.getDate()} 00:00:00`
      );
      const firstDay = firstDate.getDay() || 7;
      if (firstDay === 1) {
        // 第一天为周一
        this.getWeekList(firstDate, lastDate);
      } else {
        // 前几天被上月占用 需要排除
        let startDate = new Date(
          `${this.year}-${this.month}-${7 - firstDay + 2} 00:00:00`
        );
        this.getWeekList(startDate, lastDate);
      }
    },
    /**
     * @desc 获取当天是本月的第几周
     */
    getMonthWeek(year, month, date) {
      /*  
            month = 6 - w = 当前周的还有几天过完(不算今天)  
            year + month 的和在除以7 就是当天是当前月份的第几周  
        */
      let dateNow = new Date(year, parseInt(month) - 1, date);
      let w = dateNow.getDay(); //星期数
      let d = dateNow.getDate();
      return Math.ceil((d + 6 - w) / 7);
    },
    /**
     * @desc: 获取当月可选周列表(截止当前日期)
     */
    getWeekList(start, end) {
      try {
        let startYear = start.getFullYear();
        let startMonth = start.getMonth() + 1;
        const startDay = start.getDate();
        const endDay = end.getDate();
        const endMonth = end.getMonth() + 1;
        const currentCount = this.getMonthDayCount(startYear, startMonth);
        let day = startDay;
        let isEnd = false;
        for (let i = 1; i < 10; i++) {
          if (day > currentCount) {
            isEnd = true;
            // 需向下月借用
            if (startMonth === 12) {
              startMonth = 1;
              startYear = startYear + 1;
            } else {
              startMonth = startMonth + 1;
            }
            day = day - currentCount;
          }
          this.weekList.push({
            isTargetWeek: this.isTargetWeek(startYear, startMonth, day),
            year: startYear,
            month: startMonth,
            date: day,
            desc: `第${i}周`,
            totalCount: 0,
            finishCount: 0,
            lessCount: 0,
            result: this.getWeekResult(startYear, startMonth, day),
            dateString: `${startYear}年第${startMonth}月` + `第${i}周`,
          });
          if (
            (day + 7 >= endDay && endMonth === startMonth) ||
            isEnd ||
            day + 7 > currentCount
          ) {
            break;
          }
          day = day + 7;
        }
      } catch (err) {
        console.log(err, '----err');
        this.weekList = [];
      }
    },
    /**
     * @desc: 获取当前周的开始结束时间
     * @param {String} year
     * @param {String} month
     * @param {String} day
     */
    getWeekResult(year, month, day) {
      const date = new Date(`${year}-${month}-${day}`);
      const index = this.getCurrentWeekDay(date);
      // 周一至周天为 [1, 2, 3, 4, 5, 6, 0]
      let count = index;
      if (index === 0) {
        count = 7;
      }
      if (day - count > 0) {
        // 本周第一天大于1号本月
        const currentMonthCount = this.getMonthDayCount(year, month);
        if (day - count + 7 > currentMonthCount) {
          const { monthStr } = this.getSiblingMonth(year, month, 'next');
          return [
            `${year}-${this.addZero(month)}-${this.addZero(
              day - count + 1
            )} 00:00:00`,
            `${year}-${this.addZero(monthStr)}-${this.addZero(
              day - count + 7 - currentMonthCount
            )} 23:59:59`,
          ];
        }
        return [
          `${year}-${this.addZero(month)}-${this.addZero(
            day - count + 1
          )} 00:00:00`,
          `${year}-${this.addZero(month)}-${this.addZero(
            day - count + 7
          )} 23:59:59`,
        ];
      } else if (day - count < 0) {
        // 开始时间为上个月
        const { yearStr, monthStr, monthCount } = this.getSiblingMonth(
          year,
          month,
          'prev'
        );
        return [
          `${yearStr}-${this.addZero(monthStr)}-${this.addZero(
            monthCount + day - count + 1
          )} 00:00:00`,
          `${year}-${this.addZero(month)}-${this.addZero(
            7 + day - count
          )} 23:59:59`,
        ];
      }
      return [
        `${year}-${this.addZero(month)}-01 00:00:00`,
        `${year}-${this.addZero(month)}-07 23:59:59`,
      ];
    },
    /**
     * @desc: 获取上月数据
     * @param {String} year 计算当年
     * @param {String} month 计算当月
     * @param {String} type 类型
     */
    getSiblingMonth(year, month, type) {
      let yearStr = year;
      let monthStr = type === 'prev' ? month - 1 : month + 1;
      if (type === 'prev') {
        if (month === 1) {
          yearStr = year - 1;
          monthStr = 12;
        }
      } else {
        if (month === 12) {
          yearStr = year + 1;
          monthStr = 1;
        }
      }
      return {
        yearStr,
        monthStr,
        monthCount: this.getMonthDayCount(yearStr, monthStr),
      };
    },
    /**
     * @desc: 返回当前时间为周几
     */
    getCurrentWeekDay(date) {
      if (typeof date !== 'object' || !(date instanceof Date)) return null;
      return date.getDay();
    },
    /**
     * @desc: 全解设置日期
     * @param {Object} dateObj
     */
    getQuickDate(dateObj) {
      this.year = dateObj.year;
      this.month = dateObj.month;
      this.initDate();
    },
    /**
     * @desc: 选择月份
     */
    onWeek(monthObj) {
      this.activeWeek = monthObj;
      const { dateString, year, month } = monthObj;
      this.$emit('setDate', {
        date: monthObj.result,
        week: monthObj.desc,
        dateString,
        year,
        month,
      });
    },
    /**
     * @desc: 是否为当前选中的周
     * @param {Object} item
     */
    isActiveWeek(item) {
      const itemResult = item.result;
      const activeResult = this.activeWeek.result;
      return JSON.stringify(itemResult) === JSON.stringify(activeResult);
    },
    /**
     * @desc: 是否为当前周
     */
    isTargetWeek(cYear, cMonth, cDay) {
      const { year, month, date } = this.currentWeekObj;
      return year === cYear && month === cMonth && cDay + 7 > date;
    },
  },
};
</script>

<style lang="less" scoped>
@import './common.less';
.common-date {
  &.month-week {
    .date-list {
      justify-content: flex-start;
    }
  }
}
.month-week {
  .date-item {
    width: 25% !important;
  }
}
</style>
