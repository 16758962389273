<!--
    @name: ques-blank
    @description：ques-blank
    @author: ZengWei
    @date: 2022-04-01 11:19
-->
<template>
  <div class="widget-blank">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="sub-ques-type">
        填空题
      </div>
      <div class="q-title">
        <span class="ques-title">{{ element.config.__config__.label }}</span>
        <!-- <span v-if="!element.config.__config__.required" class="select-do">（选做题）</span> -->
        <span v-if="element.config.__ques__.answerScore" class="score">（{{element.config.__ques__.score}}分）</span>
      </div>
      <div
        class="q-title"
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc">
      </div>
    </div>
    <div class="q-option-list">
      <div class="q-option-ul">
        <el-row :gutter="15">
          <el-col
            v-for="(option,index) in element.config.__slot__.options"
            :key="index"
            :span="element.config.__ques__.optSpan"
          >
            <div class="blank-row">
              <p class="blank-title">{{ option.label }}</p>
              <el-input type="text" v-model="option.value" :disabled="disabled" @change="blankChange" placeholder="请输入"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-if="disabled && element.config.__ques__.answerScore" class="analysis-box" :class="element?.isCorrect ? 'correct-analysis' : 'error-analysis'">
      <div class="analysis-title">答案解析：</div>
      <div class="analysis-content">
        {{ element.config.__ques__.analysis ? element.config.__ques__.analysis : '无' }}
      </div>
    </div>
  </div>
</template>

<script>
import {Input} from "element-ui";
import quesWidgetMinix from "@/components/answer/quesParser/scripts/quesWidgetMinix.js";

export default {
  name: "ques-blank",
  components: {
    'el-input': Input,
  },
  mixins:[quesWidgetMinix],
  props: ['element','number','disabled'],
  data() {
    return {}
  },
  methods: {
    blankChange(){
      const blank = []
      const options = this.element.config.__slot__.options
      for (const item of options) {
        blank.push(item.value)
      }
      this.element.value = blank
      this.calcScore(blank) // 计算得分
      this.$emit('change')
    },
    calcScore(value){
      const config = this.element.config.__ques__
      const options = this.element.config.__slot__.options
      let score = 0
      let rightNum = 0 //回答正确数量

      if(config.scoreType === 1 && config.scoreTypeTwo === 1){
        // 全部答对，答案完全相同
        for (const index in value){
          const rightStr = options[index].right
          if(rightStr === value[index]){
            rightNum++
          }
          if(rightNum === options.length){
            score = config.score
          }
        }
      } else if(config.scoreType === 1 && config.scoreTypeTwo === 2){
        // 全部答对，包含答案
        for (const index in value){
          const rightStr = options[index].right
          const rightArr = rightStr.split(';')
          if(rightArr.includes(value[index])){
            rightNum++
          }
          if(rightNum === options.length){
            score = config.score
          }
        }
      } else if(config.scoreType === 2 && config.scoreTypeTwo === 1){
        // 答对几项得几分，答案完全相同
        for (const index in value){
          const rightStr = options[index].right
          if(rightStr === value[index]){
            score += parseFloat(options[index].score)
          }
        }
      } else if(config.scoreType === 2 && config.scoreTypeTwo === 2){
        // 答对几项得几分，包含答案
        for (const index in value){
          const rightStr = options[index].right
          const rightArr = rightStr.split(';')
          if(rightArr.includes(value[index])){
            score += parseFloat(options[index].score)
          }
        }
      }
      this.element.score = score
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/safetyQuestionItem';
</style>
