<!--
 * @Description: 基础树
 * @Author: luocheng
 * @Date: 2021-09-01 09:40:17
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-10-07 10:21:55
-->
<template>
  <div class="common-tree" v-if="initEnd && propObj" :style="wrapStyly">
    <el-input
      v-if="statusConfig.showSearch"
      v-model="name"
      prefix-icon="el-icon-search"
      clearable
      :placeholder="statusConfig.placeholder"
      class="search"
      @input="onFilter"
    ></el-input>
    <!-- :current-node-key="currentKey" -->
    <section class="check-all" v-if="statusConfig.showCheckAll">
      <el-checkbox v-model="checkAll" @change="onCheckAll">{{
        statusConfig.checkAllText
      }}</el-checkbox>
    </section>
    <template v-if="false">{{ propObj }}</template>
    <el-tree
      :data="treeData"
      @node-click="onNodeClick"
      :default-expand-all="
        statusConfig.defaultExpandAll === undefined ||
        statusConfig.defaultExpandAll
          ? true
          : false
      "
      :expand-on-click-node="
        statusConfig.rowExpend === undefined ? true : statusConfig.rowExpend
      "
      ref="treeContent"
      class="tree-content"
      :node-key="propObj.id || 'id'"
      :empty-text="statusConfig.emptyText"
      :lazy="statusConfig.lazy"
      :load="loadNode"
      highlight-current
      :check-on-click-node="
        statusConfig?.checkOnClickNode === undefined
          ? true
          : statusConfig?.checkOnClickNode
      "
      :filter-node-method="filterNode"
      :show-checkbox="statusConfig.showCheckBox"
      :default-expanded-keys="defaultExpandKeys"
      :check-strictly="
        statusConfig.checkStrictly === undefined
          ? true
          : statusConfig.checkStrictly
      "
      :props="{
        children: propObj.children,
        label: function (data, node) {
          return data[propObj.label];
        },
        disabled: statusConfig.disabled,
      }"
      @check="onGetCheckNodes"
      @check-change="onCheckChange"
    >
      <!-- isLeaf: function (data, node) {
				return !data[propObj.children] || !data[propObj.children].length;
			}, -->
      <!-- 此处不用全等是因为可能后端返回数据类型会不一致 -->
      <component
        :is="statusConfig?.useTooltip ? 'el-tooltip' : 'div'"
        effect="dark"
        :content="data[propObj.label] || node.label"
        placement="right"
        :open-delay="200"
        class="tooltip-box"
        slot-scope="{ node, data }"
        :class="{
          'current-custom-tree-node':
            currentKey && data[propObj.id] && currentKey == data[propObj.id],
        }"
      >
        <span
          class="custom-tree-node"
          :style="{
            'padding-right': !statusConfig.hideActions ? '25px' : '7px',
          }"
          :class="{
            'current-custom-tree-node':
              currentKey && data[propObj.id] && currentKey == data[propObj.id],
          }"
        >
          <div class="tooltip-box">
            <TreeIcon
              :config="element.iconConfig || {}"
              :rowData="data"
              :type="'pre'"
              :componentData="subComponentData"
            ></TreeIcon>
            <span class="label">
              {{ data[propObj.label] || node.label
              }}<span style="display: none">-{{ renderCount }}</span>
            </span>
            <span
              v-if="
                element.countNumberShow &&
                (!statusConfig.leafCode || !data?.[statusConfig.leafCode])
              "
              class="tree-count-number"
              style="z-index: 100"
            >
              <!-- 行字段映射 -->
              <template v-if="+element.countNumberConfig?.numberDataType === 4">
                <span
                  :style="{
                    color:
                      element.countNumberConfig.masterColorPosition == '前'
                        ? element.countNumberConfig.masterColor
                        : '',
                  }"
                  >{{ element.countNumberConfig.preText }}
                </span>
                <template v-if="element.countNumberConfig?.showPreNumber">
                  <span
                    :style="{
                      color:
                        element.countNumberConfig.masterColorPosition == '0'
                          ? element.countNumberConfig.masterColor
                          : '',
                    }"
                    >{{ data[element.countNumberConfig?.proField] }}</span
                  >
                  <span
                    :style="{
                      color:
                        element.countNumberConfig.masterColorPosition == '/'
                          ? element.countNumberConfig.masterColor
                          : '',
                    }"
                    >/</span
                  >
                </template>
                <span
                  :style="{
                    color:
                      element.countNumberConfig.masterColorPosition == '1'
                        ? element.countNumberConfig.masterColor
                        : '',
                  }"
                  >{{ data[element.countNumberConfig?.endField] }}</span
                >
              </template>
              <!-- 常规 -->
              <template v-else-if="data.__number_count !== undefined">
                <span
                  :style="{
                    color:
                      element.countNumberConfig.masterColorPosition == '前'
                        ? element.countNumberConfig.masterColor
                        : '',
                  }"
                  >{{ element.countNumberConfig.preText }}
                </span>
                <span
                  :style="{
                    color:
                      element.countNumberConfig.masterColorPosition == '0'
                        ? element.countNumberConfig.masterColor
                        : '',
                  }"
                  v-if="
                    data.__pre_number_count !== undefined &&
                    data.__pre_number_count !== null
                  "
                  >{{ data.__pre_number_count }}</span
                >
                <span
                  :style="{
                    color:
                      element.countNumberConfig.masterColorPosition == '/'
                        ? element.countNumberConfig.masterColor
                        : '',
                  }"
                  v-if="
                    data.__pre_number_count !== undefined &&
                    data.__pre_number_count !== null
                  "
                  >/</span
                >
                <span
                  :style="{
                    color:
                      element.countNumberConfig.masterColorPosition == '1'
                        ? element.countNumberConfig.masterColor
                        : '',
                  }"
                  >{{ data.__number_count }}</span
                >
              </template>
            </span>
            <template
              v-if="
                judgingArchiAPermission(
                  actionLimit.archiLimit,
                  actionLimit.permission
                ) && judgingUILogic(actionLimit.UILogic, subComponentData, data)
              "
            >
              <el-button
                v-if="
                  statusConfig.appendChild && validDomLogic('appendChild', data)
                "
                type="text"
                icon="el-icon-plus"
                class="action-btn plus-child"
                @click="
                  doAction(
                    actionConfig.find((ele) => ele.key === 'appendChild'),
                    data,
                    $event
                  )
                "
              ></el-button>
              <!-- 独立操作 -->
              <template v-if="outSideActionList.length">
                <!-- eslint-disable -->
                <section
                  class="out-side-actions"
                  v-for="(outAction, index) in outSideActionList"
                  :key="index"
                  v-if="validDomLogic(outAction.key, data)"
                  @click="doAction(outAction, data, $event)"
                >
                  <el-image
                    :src="outAction.icon"
                    v-if="outAction.icon && outAction.icon.includes('http')"
                  ></el-image>
                  <i
                    v-else
                    class=""
                    :class="['iconfont', outAction.icon]"
                    :style="{
                      color: outAction.color,
                    }"
                  ></i>
                </section>
              </template>
              <!-- 收起的操作 -->
              <span
                class="action-content"
                :class="{
                  'action-content-hover': statusConfig?.defaultHideAction,
                }"
                v-if="!statusConfig.hideActions"
              >
                <el-dropdown placement="bottom-end" @command="handleCommand">
                  <TreeIcon
                    :config="element.iconConfig || {}"
                    :rowData="data"
                    :type="'action'"
                    @click="onAction(data)"
                    :componentData="subComponentData"
                  ></TreeIcon>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      class="action-item"
                      :command="{ key: 'action', value: { item, data } }"
                      v-for="(item, index) in actionConfig"
                      v-if="
                        item.type !== 'appendChild' &&
                        !specialBtn.includes(item.type) &&
                        !item.outSide &&
                        validDomLogic(item.type, data)
                      "
                      :key="index"
                      :style="{
                        color: item.color,
                      }"
                      >{{ item.name }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="
                        judgingArchiAPermission(
                          deleteLimit.archiLimit,
                          deleteLimit.permission,
                          element,
                          {
                            name: '删除',
                          }
                        ) &&
                        judgingUILogic(
                          deleteLimit.UILogic,
                          subComponentData,
                          data
                        )
                      "
                      class="action-item remove"
                      style="color: #f56c6c"
                      :command="{ key: 'delete', value: data }"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </template>
          </div>
        </span>
      </component>
    </el-tree>
    <!-- 导出 -->
    <Spreadsheet
      v-if="showSheet && sheetFormData"
      :type="spreadsheetType"
      :excelUuid="exportConfig.exportTemplate"
      :objectUuid="exportConfig.interfaceUrl || exportConfig.objectUUID"
      :viewUuid="exportConfig.viewUUID"
      :dataIds="sheetFormData"
      :exportType="exportConfig.exportTypeSheet || 'pdf'"
      @exportSuccess="onExportSuccess"
      :viewSearch="exportViewSearch"
      :viewQuery="exportViewParams"
      @exportError="onExportError"
      :extraParams="exportParam || {}"
    ></Spreadsheet>
  </div>
</template>

<script>
import {
  Image,
  Tree,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Tooltip,
  Checkbox,
} from "element-ui";
import {
  getComponentById,
  doEEActionHandle,
  getQueryValue,
} from "@/utils/tools";
import { mapState } from "vuex";
import { dataInterface } from "@/apis/data/index";
import eventBus from "@/plugins/eventBus";
import {
  judgingArchiAPermission,
  flatTree,
  judgingUILogic,
  initParams,
  removeEventBus,
} from "@/utils/tools";
import Spreadsheet from "@/custom-component/common/Entry";
import treeCountMixin from "./treeCountMixin";
import TreeIcon from "./TreeIcon";

export default {
  name: "CommonTree",
  components: {
    "el-tree": Tree,
    "el-image": Image,
    "el-dropdown": Dropdown,
    "el-dropdown-item": DropdownItem,
    "el-dropdown-menu": DropdownMenu,
    "el-tooltip": Tooltip,
    "el-checkbox": Checkbox,
    Spreadsheet,
    TreeIcon,
  },
  mixins: [treeCountMixin],
  props: {
    // 绑定的值
    element: {
      type: Object,
      default: () => {},
      required: true,
    },
    // 渲染的数据
    containerData: {
      type: [Array, Object],
      required: false,
      default: () => [],
    },
    isGroup: {
      type: Boolean,
    },
    groupComponents: {
      type: Array,
      default: () => [],
    },
    componentList: {
      default: null,
    },
  },
  inject: ["EDITOR_pageUUID"],
  data() {
    return {
      isInit: true,
      name: "",
      initEnd: false,
      // 当前节点
      currentKey: "",
      propObj: null,
      metadata: [],
      // 全选
      checkAll: false,
      // 树形图节点的扁平化ID
      flatNodes: [],
      renderEnd: false,
      // 导出配置
      exportConfig: null,
      sheetFormData: null,
      showSheet: false,
      spreadsheetType: "export",
      exportViewParams: {},
      exportViewSearch: [],
      treeData: [],
      isFirstInit: true,
      oldContainerData: null,
      exportParam: {},
      // 默认展开
      defaultExpandKeys: [],
      // 隔离选中ID
      singleSelectIds: [],
      checkData: null,
      // 次数
      renderCount: 0,
      // 特殊事件(不需要再行内显示)
      specialBtn: ["rowClick", "check", "$selectedNode", "$uncheckNode"],
    };
  },
  mounted() {
    this.doDefaultClick();
    // 导出
    eventBus.$on("exportData", (exportConfig) => {
      if (!exportConfig || exportConfig.componentId !== this.element.id) return;
      this.exportConfig = exportConfig;
      this.doExport(exportConfig);
    });
    const doComponentBehavior = {
      [this.element.id]: (config) => {
        const { component, list = [] } = config;
        if (component !== this.element.id) return false;
        list.forEach((ele) => {
          const { behaviors, params } = ele;
          const { param = {}, canPost } = initParams(
            params,
            this.isGroup,
            this.componentList || this.componentData,
            this.groupComponents,
            this.EDITOR_pageUUID
          );
          if (canPost) {
            // 调用行为方法
            behaviors.forEach((funName) => {
              try {
                eval(this[funName])(param);
              } catch (err) {
                console.log(err);
              }
            });
          }
        });
      },
    };

    const reRenderComponentView = {
      [this.element.id]: ({ componentId }) => {
        // 触发刷新
        if (componentId !== this.element?.database?.containerKey) return;
        this.treeData = [];
        this.initEnd = false;
        this.$nextTick(() => {
          this.treeData = this.getTreeData();
          this.initEnd = true;
          this.renderCount++;
        });
      },
    };
    eventBus.$on("doComponentBehavior", doComponentBehavior[this.element.id]);
    eventBus.$on(
      "reRenderComponentView",
      reRenderComponentView[this.element.id]
    );
  },
  computed: {
    ...mapState(["componentData", "subsidiaryComponentData"]),
    // 数据源配置
    database() {
      return this.element && this.element.database;
    },
    // 渲染数据
    // treeData() {
    // 	if (this.element.valueOrign === 'fixed') {
    // 		// 固定值
    // 		return this.propValue || [];
    // 	} else if (this.element.valueOrign === 'database') {
    // 		// 来源数据仓库
    // 		if (!this.database || !this.database.containerKey) return [];
    // 		let container = getComponentById(this.componentList || this.componentData,
    // 			this.database.containerKey
    // 		);
    // 		if (!container && this.isGroup && this.groupComponents.length) {
    // 			container = getComponentById(this.groupComponents, this.database.containerKey);
    // 		}
    // 		if (
    // 			!container ||
    // 			!container.containerData ||
    // 			!Array.isArray(container.containerData)
    // 		) {
    // 			return [];
    // 		}
    //     return this.sendNumberDataToTree(container.containerData)
    //     // return container.containerData
    // 		// return container.containerData;
    // 	}
    // 	return [];
    // },
    // 组件配置
    statusConfig() {
      return this.element ? this.element.statusConfig || {} : {};
    },
    // 字段列表
    // fieldList() {
    // 	const list =
    // 		this.element &&
    // 		this.element.database &&
    // 		this.element.database.fieldList;
    // 	if (!list || !Array.isArray(list) || !list.length) return [];
    // 	return list;
    // },
    // 操作配置
    actionConfig() {
      return this.element && this.element.actionConfig;
    },
    // 整体操作前权限控制
    actionLimit() {
      return (
        this.element?.actionLimit || {
          permission: [], // 权限
          archiLimit: [], // 架构限制
        }
      );
    },
    // 删除权限控制
    deleteLimit() {
      return (
        this.element?.deleteLimit || {
          permission: [], // 权限
          archiLimit: [], // 架构限制
        }
      );
    },
    // 默认展开
    defaultSelectAll() {
      return this.statusConfig?.defaultSelectAll;
    },
    // 取值组件列表
    subComponentData() {
      if (this.EDITOR_pageUUID) {
        return (
          this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData ||
          this.componentList ||
          this.componentData ||
          []
        );
      }
      return this.componentList || this.componentData || [];
    },
    // 独立显示操作
    outSideActionList() {
      if (!Array.isArray(this.actionConfig)) return [];
      const result = [];
      this.actionConfig.forEach((ele) => {
        if (
          ![...this.specialBtn, "appendChild"].includes(ele.key) &&
          ele.outSide
        ) {
          result.push(ele);
        }
      });
      return result;
    },
    wrapStyly() {
      const styleObj = {};
      if (this?.element?.style?.background) {
        styleObj["--bgColor"] = this.element.style.background;
      }
      if (this?.element?.style?.color) {
        styleObj["--fontColor"] = this.element.style.color;
      }
      if (this.statusConfig?.rowHoverBG) {
        styleObj["--rowHoverBG"] = this.statusConfig.rowHoverBG;
      }
      if (this.statusConfig?.rowHoverColor) {
        styleObj["--rowHoverColor"] = this.statusConfig.rowHoverColor;
      }
      if (this.statusConfig?.rowActiveBG) {
        styleObj["--rowActiveBG"] = this.statusConfig.rowActiveBG;
      }
      return styleObj;
    },
  },
  watch: {
    countDataList: {
      handler() {
        this.isFirstInit = true;
        this.treeData = this.getTreeData();
        this.doDefaultClick();
      },
      immediate: false,
      deep: true,
    },
    // 修复子节点不显示问题<配置项延迟生效>
    propObj(newVal) {
      if (newVal) {
        this.initEnd = true;
        if (this.isFirstInit) {
          this.treeData = this.getTreeData();
          this.doDefaultClick();
        }
      }
    },
    database: {
      handler() {
        let container = getComponentById(
          this.subComponentData,
          this.database.containerKey
        );
        if (!container && this.isGroup && this.groupComponents.length) {
          container = getComponentById(
            this.groupComponents,
            this.database.containerKey
          );
        }
        this.metadata = container?.metadata || {};
        // 兼容复制出来配置的情况
        this.element.metadata = this.metadata;
        if (this.isFirstInit) {
          this.treeData = this.getTreeData();
          this.doDefaultClick();
        }
      },
      deep: true,
      immediate: true,
    },
    metadata: {
      handler() {
        if (!this.propObj && this.metadata) {
          const result = {};
          const { label, children } = this.statusConfig;
          // 保存字段数据刷新
          const fieldList = [];
          for (let key in this.metadata) {
            if (this.metadata[key] === label) {
              result.label = key;
            }
            if (this.metadata[key] === children) {
              result.children = key;
            }
            if (
              this.metadata[key] === "parent_id" ||
              this.metadata[key] === "父级id"
            ) {
              result.parentId = key;
            }
            if (this.metadata[key] === "id") {
              const idUUID = this.getIdUUID();
              result.id = idUUID;
            }
            if (this.metadata[key] === "type") {
              result.type = key;
            }
            this.propObj = this.propObj || result;
            fieldList.push({
              name: this.metadata[key],
              uuid: key,
            });
          }
          this.$store.commit("modifyComponent", {
            component: {
              ...this.element,
              database: {
                ...this.element.database,
                fieldList,
              },
            },
            containerId: null,
            isModify: true,
            pageUUID: this.EDITOR_pageUUID,
          });
          this.propObj = result;
        }
      },
      deep: true,
      immediate: true,
    },
    treeData: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;
        this.initNumberCount();

        // 数据改变保持筛选 本地筛选
        if (this.statusConfig.showSearch && this.name) {
          this.$nextTick(() => {
            this.onFilter();
          });
        }
        if (
          JSON.stringify(newVal) !== JSON.stringify(oldVal) ||
          !newVal ||
          !newVal.length
        ) {
          if (
            this.treeData &&
            Array.isArray(this.treeData) &&
            this.treeData.length
          ) {
            const idUUID = this.getIdUUID();
            // 判断是否为同一棵树
            if (this.checkData && newVal[0][idUUID] === oldVal[0][idUUID]) {
              this.onNodeClick(this.checkData, 1);
              const idUUID = this.getIdUUID();
              const keyIndex = this.defaultExpandKeys.findIndex(
                (ele) => ele === this.checkData[idUUID]
              );
              if (keyIndex !== -1) {
                this.defaultExpandKeys.splice(keyIndex, 1);
              } else if (this.checkData[idUUID]) {
                this.defaultExpandKeys.push(this.checkData[idUUID]);
              }
            } else {
              if (
                this.statusConfig.defaultSelectFirst ||
                this.statusConfig.defaultSelectFirst === undefined
              ) {
                this.onNodeClick(this.treeData[0], 1);
                // const idUUID = this.getIdUUID();
                const keyIndex = this.defaultExpandKeys.findIndex(
                  (ele) => ele === this.treeData[0][idUUID]
                );
                // 首次选择第一项选中状态
                this.$nextTick(() => {
                  try {
                    this.$refs?.treeContent?.setCurrentNode(this.treeData[0]);
                  } catch (err) {
                    console.log(err, "---err--");
                  }
                });
                if (keyIndex !== -1) {
                  this.defaultExpandKeys.splice(keyIndex, 1);
                } else if (this.treeData[0][idUUID]) {
                  this.defaultExpandKeys.push(this.treeData[0][idUUID]);
                }
              }
            }
          } else {
            if (
              this.statusConfig.defaultSelectFirst ||
              this.statusConfig.defaultSelectFirst === undefined
            ) {
              this.onNodeClick(null);
              if (oldVal && oldVal.length) {
                // 刷新数据
                this.$store.commit("modifyComponent", {
                  component: {
                    ...this.element,
                    resolveData: {},
                  },
                  containerId: null,
                  isModify: true,
                  pageUUID: this.EDITOR_pageUUID,
                });
                eventBus.$emit("databaseTrigger", {
                  componentId: this.element.id,
                  isSearch: true,
                  action: "any",
                  output: {},
                });
              }
            }
          }
        }
        // 扁平化获取树形图节点
        if (this.statusConfig.showCheckBox && this.statusConfig.showCheckAll) {
          this.flatNodes =
            flatTree(this.treeData, this.propObj.children || "children") || [];
        }
        // 全选
        this.$nextTick(() => {
          if (this.defaultSelectAll) {
            const checkNodes = this.$refs?.treeContent?.getCheckedNodes();
            //  && !this.renderEnd  在编辑选然后此判断为false  故无法全选
            if (checkNodes && checkNodes.length !== this.flatNodes.length) {
              this.checkAll = true;
              this.renderEnd = true;
              this.onCheckAll(true);
            }
          }
        });
      },
      deep: true,
      immediate: true,
    },
    // 默认展开
    // defaultSelectAll: {
    // 	handler(newVal, oldVal) {
    // 		if (newVal === oldVal) return;
    // 		console.log("----默认全选----")
    // 		this.$nextTick(() => {
    // 			const checkNodes = this.$refs?.treeContent?.getCheckedNodes();
    // 			if (checkNodes && checkNodes.length !== this.flatNodes.length && !this.renderEnd) {
    // 				this.checkAll = true;
    // 				this.renderEnd = true;
    // 				this.onCheckAll(true)
    // 			}
    // 		})
    // 	},
    // 	immediate: true,
    // 	deep: true
    // },
    componentData: {
      handler() {
        // 当依赖的树形图取值容器变化后需要刷新数据
        const { valueOrign } = this.element;
        if (valueOrign === "database") {
          let container = getComponentById(
            this.subComponentData,
            this.database.containerKey
          );
          if (!container && this.isGroup && this.groupComponents.length) {
            container = getComponentById(
              this.groupComponents,
              this.database.containerKey
            );
          }
          if (
            container &&
            this.oldContainerData &&
            container?.containerData &&
            JSON.stringify(this.oldContainerData) !==
              JSON.stringify(container?.containerData)
          ) {
            this.isFirstInit = true;
          }
        }
        if (this.isFirstInit) {
          this.treeData = this.getTreeData();
          this.doDefaultClick();
        }
      },
      immediate: true,
      deep: true,
    },
    subComponentData: {
      handler() {
        // 当依赖的树形图取值容器变化后需要刷新数据
        const { valueOrign } = this.element;
        if (valueOrign === "database") {
          let container = getComponentById(
            this.subComponentData,
            this.database.containerKey
          );
          if (!container && this.isGroup && this.groupComponents.length) {
            container = getComponentById(
              this.groupComponents,
              this.database.containerKey
            );
          }
          const { containerData } = container;
          if (
            this.oldContainerData &&
            containerData &&
            JSON.stringify(this.oldContainerData) !==
              JSON.stringify(containerData)
          ) {
            this.isFirstInit = true;
          }
        }
        if (this.isFirstInit) {
          this.treeData = this.getTreeData();
          this.doDefaultClick();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    judgingArchiAPermission,
    judgingUILogic,
    /**
     * @description: 获取id的uuid
     */
    getIdUUID() {
      let idUUID = this.statusConfig.idUUID || "";
      // let idCount = 0;
      if (!idUUID) {
        for (let key in this.metadata) {
          if (this.metadata[key] === "id") {
            // idCount++;
            idUUID = key;
          }
        }
        // 编辑器提示
        // if (['/modify-page', '/page-view'].includes(this.$route.path)) {
        // 	if (idCount === 0) {
        // 		setTimeout(() => {
        //       this.$message.warning(`组件【${this.element.name}】数据接口无【id】字段返回，请检查接口配置！`);
        // 		}, 0);
        // 		return '';
        // 	}
        // 	if (idCount > 1) {
        // 		return '';
        // 	}
        // }
      }
      // if (idCount > 1) {
      // 	setTimeout(() => {
      // 		this.$message.warning(`组件【${this.element.name}】数据接口存在【${idCount}】个【id】字段返回，请检查接口配置！以保证准确性！`);
      // 	}, 0);
      // }
      return idUUID;
    },
    /**
     * @desc: 默认选中暴露数据，触发操作
     */
    doDefaultClick() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (
            this.treeData &&
            Array.isArray(this.treeData) &&
            this.treeData.length
          ) {
            // TO DO  ....
            // 进行默认选中，避免样式问题
            // this.$nextTick(() => {
            // 	if (this.treeData.length) {
            // 		try {
            // 			const idUUID = this.getIdUUID()
            // 			this.$refs.treeContent.setCurrentKey(this.treeData?.[0]?.[idUUID]);
            // 		} catch (err) {
            // 			console.log(err);
            // 		}
            // 	}
            // });
          } else {
            if (
              this.statusConfig.defaultSelectFirst ||
              this.statusConfig.defaultSelectFirst === undefined
            ) {
              this.onNodeClick(null, true);
            }
          }
        }, 300);
      });
    },
    /**
     * @description: 获取树形图数据（非请求）
     */
    getTreeData() {
      if (this.element.valueOrign === "fixed") {
        // 固定值
        this.isFirstInit = false;
        return this.propValue || [];
      } else if (this.element.valueOrign === "database") {
        // 来源数据仓库
        if (!this.database || !this.database.containerKey) return [];
        let container = getComponentById(
          this.subComponentData,
          this.database.containerKey
        );
        if (!container && this.isGroup && this.groupComponents.length) {
          container = getComponentById(
            this.groupComponents,
            this.database.containerKey
          );
        }
        if (!Array.isArray(container?.containerData)) {
          return [];
        }
        const result = this.sendNumberDataToTree(container.containerData);
        if (
          JSON.stringify(result) !== JSON.stringify(container.containerData)
        ) {
          this.oldContainerData = container.containerData;
          // 成功初始化了数据
          this.isFirstInit = false;
        }
        return result;
      }
      return [];
    },
    /**
     * @desc: Dom渲染逻辑
     */
    validDomLogic(key, data) {
      const btnConfig = this.actionConfig.find((ele) => ele.key === key);
      if (!btnConfig) return false;
      return (
        judgingArchiAPermission(btnConfig.archiLimit, btnConfig.permission) &&
        judgingUILogic(btnConfig.UILogic, this.subComponentData, data)
      );
    },
    /**
     * @desc: 点击节点
     * @param {Object} data 选中的节点对象
     */
    onNodeClick(data) {
      this.checkData = data;
      // 如果是可选择则不再触发单几点情况
      if (this.statusConfig.showCheckBox) {
        // 点击隔离选中@廖总 2023.8.3
        if (this.statusConfig.nodeSelectSingle) {
          this.doSingleSelect(data);
        }
        return;
      }
      const rowClick = this.element.actionConfig.find(
        (ele) => ele.key === "rowClick"
      );
      if (!this.propObj || !data) {
        return false;
      }

      // 方法有问题 树组件每次都会刷新获取展开数据 TODO：保持展开配置需重新写 // 第一次默认展开放在初始
      // const idUUID = this.getIdUUID();
      // const keyIndex = this.defaultExpandKeys.findIndex(ele => ele === data[idUUID]);
      // if (keyIndex !== -1) {
      //   this.defaultExpandKeys.splice(keyIndex, 1)
      // } else {
      //   this.defaultExpandKeys.push(data[idUUID]);
      // }
      this.currentKey = data?.[this.propObj.id];
      let resolveData = data;
      if (!data) {
        resolveData = {
          ...this.element.metadata,
        };
        this.$set(resolveData, this.propObj?.id, -1);
      }
      this.element.resolveData = resolveData;
      this.$store.commit("updatePageCustomStatus", {
        origin: this.element,
        resolveData,
      });
      this.$store.commit("modifyComponent", {
        component: {
          ...this.element,
          resolveData,
        },
        containerId: null,
        isModify: true,
        pageUUID: this.EDITOR_pageUUID,
      });
      if (
        rowClick &&
        rowClick.eventList &&
        rowClick.eventList.length &&
        judgingArchiAPermission(
          this.actionLimit.archiLimit,
          this.actionLimit.permission
        ) &&
        judgingUILogic(this.actionLimit.UILogic, this.subComponentData) &&
        this.validDomLogic("rowClick", data)
      ) {
        // 触发行点击事件
        const comEvents = rowClick.eventList || [];
        for (let i = 0; i < comEvents.length; i++) {
          const {
            pattern,
            eventList = [],
            specialEventList = [],
          } = comEvents[i];
          if (pattern === undefined) {
            if (comEvents[i].key === "click") {
              comEvents[i].effects.forEach((effect) => {
                this.$store.commit("triggerEvents", {
                  config: {
                    ...comEvents[i],
                    ...effect,
                  },
                  element: this.element,
                  EDITOR_pageUUID: this.EDITOR_pageUUID,
                });
              });
            }
          } else {
            const result = pattern === "special" ? specialEventList : eventList;
            result.forEach((ele) => {
              if (ele.key === "click") {
                const { actionType } = ele;
                if (actionType === "eeAction") {
                  this.doEEAction(ele);
                } else if (actionType === "pageAction") {
                  ele.effects.forEach((effect) => {
                    this.$store.commit("triggerEvents", {
                      config: {
                        ...ele,
                        ...effect,
                      },
                      element: this.element,
                      EDITOR_pageUUID: this.EDITOR_pageUUID,
                    });
                  });
                }
              }
              // 触发组件行为
              const { behaviors = [] } = ele;
              behaviors.forEach((behavior) => {
                this.$store.commit("triggerEvents", {
                  config: {
                    behavior,
                    isBehavior: true,
                  },
                  element: this.element,
                  EDITOR_pageUUID: this.EDITOR_pageUUID,
                });
              });
            });
          }
        }
      }
      setTimeout(() => {
        eventBus.$emit("databaseTrigger", {
          componentId: this.element.id,
          isSearch: true,
          action: "any",
          output: resolveData,
        });
        // 提交状态改变
        this.element.resolveData = resolveData;
        this.$store.commit("updatePageCustomStatus", {
          origin: this.element,
          resolveData,
        });
      }, 0);
    },
    /**
     * @description: 触发后端事件
     * @return {*}
     */
    doEEAction(action) {
      window.$EditorDebug.startEEAction(this.element);
      const { objectUUID, viewUUID, eventName, eeType, interfaceUrl } = action;
      if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
        this.$message.error("事件配置错误！");
        window.$EditorDebug.failEEAction(this.element);
        return false;
      }
      this.loading = true;
      const sourceParams = this.getBindParams(action.sourceList);
      // 蓝图
      if (eeType === "blueprint") {
        dataInterface(sourceParams, `/api${interfaceUrl}`)
          .then((res) => {
            if (res.status === 200 && res.data.code === 200) {
              this.$message.success("操作成功！");
              window.$EditorDebug.successEEAction(
                this.element,
                `/api${interfaceUrl}`
              );
              doEEActionHandle(res.data?.__adds__);
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            window.$EditorDebug.errorEEAction(
              this.element,
              `/api${interfaceUrl}`
            );
          });
        return true;
      }
      // 数仓
      const data_id = sourceParams?.data_id;
      dataInterface({
        __method_name__: "customEventCall",
        object_uuid: objectUUID,
        view_uuid: viewUUID,
        ...sourceParams,
        data_id, // 参数配置
        event: eventName,
      })
        .then((res) => {
          if (res.status === 200 && res.data.code === 200) {
            this.$message.success("操作成功！");
            window.$EditorDebug.successEEAction(this.element, eventName);
            doEEActionHandle(res.data?.__adds__);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          window.$EditorDebug.errorEEAction(this.element, eventName);
        });
    },
    /**
     * @desc: 后端事件获取绑定参数
     * @param {Object} comp 组件数据
     * @return {Object}
     */
    getBindParams(sourceConfig) {
      if (!Array.isArray(sourceConfig)) return {};
      const sourceParams = {};
      for (let i = 0; i < sourceConfig.length; i++) {
        const {
          componentId,
          field,
          key,
          originType = "",
          urlParamKey = "",
          systemKey = "",
          systemCode = "",
          fixedValue = "",
          statusCode = "",
        } = sourceConfig[i];
        if (originType === "url" && urlParamKey) {
          // 从url获取参数
          const result = getQueryValue(urlParamKey);
          this.$set(sourceParams, key, result);
        } else if (originType === "system") {
          // 系统参数
          try {
            let obj = sessionStorage.getItem(systemKey);
            if (!obj) {
              obj = localStorage.getItem(systemKey);
            }
            if (!obj) break;
            const result = JSON.parse(obj);
            if (
              result &&
              Object.prototype.toString.call(result) === "[object Object]"
            ) {
              const queryVal = result[systemCode];
              this.$set(sourceParams, key, queryVal);
            }
          } catch (err) {
            console.log(err, "99999999");
          }
        } else if (originType === "fixed") {
          // 固定值
          this.$set(sourceParams, key, fixedValue);
        } else if (originType === "pageStatus") {
          // 页面状态
          const statusCodeValue =
            this._PageCustomStatus[statusCode] === undefined
              ? this._APPCustomStatus[statusCode] || null
              : this._PageCustomStatus[statusCode];
          this.$set(sourceParams, key, statusCodeValue || "");
        } else if (componentId && field && key) {
          // 普通从组件获取
          let sourceComponent = getComponentById(
            this.subComponentData,
            componentId
          );
          if (!sourceComponent && this.isGroup && this.groupComponents.length) {
            sourceComponent = getComponentById(
              this.groupComponents,
              componentId
            );
          }
          const componentName = componentId.toString().split("-")[0];
          if (
            field === "DATAVIEWSELECT" &&
            [
              "CommonTree",
              "CommonTableContainer",
              "CommonLoopContainer",
            ].includes(componentName)
          ) {
            this.$set(sourceParams, key, sourceComponent?.resolveData || []);
          } else {
            const result = sourceComponent?.resolveData?.[field] || "";
            this.$set(sourceParams, key, result);
          }
        }
      }
      return sourceParams;
    },
    /**
     * @description: 执行点击选中隔离效果
     */
    doSingleSelect(data) {
      if (!data) return;
      this.singleSelectIds = [];
      const { id = "", children = "children" } = this.propObj;
      if (!this.statusConfig?.checkStrictly) {
        this.getIds([data], id, children);
      } else {
        this.singleSelectIds.push(data?.[id]);
      }
      this.$refs.treeContent?.setCheckedKeys(this.singleSelectIds);
      this.onResolveKeys(this.singleSelectIds);
    },
    /**
     * @description: 获取当前选中IDs
     * @param {Array} data 节点数据类表
     * @param {String} idUUID
     * @param {String} childrenUUID
     */
    getIds(data, idUUID, childrenUUID) {
      if (!idUUID || !childrenUUID) return;
      data.forEach((ele) => {
        if (ele?.[idUUID]) {
          this.singleSelectIds.push(ele[idUUID]);
        }
        const children = ele?.[childrenUUID];
        if (Array.isArray(children)) {
          this.getIds(children, idUUID, childrenUUID);
        }
      });
    },
    /**
     * @desc: 懒加载方法
     * @param {Object} node 节点对象
     * @param {Function} resolve
     */
    async loadNode(node, resolve) {
      if (this.isInit) {
        this.isInit = false;
        return resolve({});
      }
      const result = await this.getChildren(node);
      return resolve(result);
    },
    /**
     * @desc: 设置label的说明方法
     * @param {Object} data
     */
    setLabel(data) {
      return data[this.propObj.label];
    },
    /**
     * @desc: 懒加载子树节点
     * @param {Object} node 节点
     */
    getChildren(node) {
      const value = node.data[this.propObj.id];
      if (!value) return [];
      return new Promise((resolve, reject) => {
        const {
          search = [],
          param = {},
          canPost,
        } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.subComponentData,
          this.groupComponents,
          this.EDITOR_pageUUID
        );
        if (!canPost) {
          resolve([]);
          return;
        }
        dataInterface({
          __method_name__: "dataList",
          search: [
            ...search,
            {
              field_uuid: this.propObj.parentId,
              ruleType: "eq",
              value,
            },
          ],
          ...param,
          object_uuid: this.database.objectData.uuid,
          view_uuid: this.database.viewData.uuid,
        })
          .then((res) => {
            if (res.status === 200 && res.data.code === 200 && res.data.data) {
              resolve(this.getListFromRes(res) || []);
            }
          })
          .catch((err) => {
            console.log(err);
            reject([]);
          });
      });
    },
    /**
     * @desc: 根据请求返回获取列表结构
     * @param {Object} res 请求返回的数据
     */
    getListFromRes(res) {
      if (res.data && Array.isArray(res.data)) {
        return res.data;
      }
      if (res.data && typeof res === "object") {
        return this.getListFromRes(res.data);
      }
      return [];
    },
    /**
     * @desc: 触发过滤
     */
    onFilter() {
      if (this.$refs && this.$refs.treeContent) {
        this.$refs.treeContent.filter(this.name);
      }
    },
    /**
     * @desc: 前端过滤函数 暂时废弃后端筛选启用前端本地筛选
     */
    filterNode(value, data) {
      if (!value) return true;
      return data[this.propObj.label].indexOf(value) !== -1;
    },
    /**
     * @desc: 搜索
     */
    onSearch() {
      // 前端筛选
      eventBus.$emit("databaseTrigger", {
        componentId: this.element.id,
        parentId: this.database.containerKey,
        action: "list",
        isSearch: true,
        isInit: true,
        output: {
          search: [
            {
              field_uuid: this.propObj.label,
              ruleType: "like",
              value: this.name || "",
            },
          ],
        },
      });
    },
    /**
     * @desc: 点击操作扩展小点
     * @param {Object} data 所点击行的数据对象
     */
    onAction() {
      // console.log(data, '---data');
    },
    /**
     * @desc: 新增
     */
    onPlus(e) {
      e.preventDefault();
      e.stopPropagation();
      const eventObj = this.actionConfig.find((ele) => ele.key === "add");
      const comEvents = eventObj.eventList || [];
      for (let i = 0; i < comEvents.length; i++) {
        const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
        const result = pattern === "special" ? specialEventList : eventList;
        result.forEach((ele) => {
          if (ele.key === "click") {
            ele.effects.forEach((effect) => {
              this.$store.commit("triggerEvents", {
                config: {
                  ...ele,
                  ...effect,
                },
                element: this.element,
                EDITOR_pageUUID: this.EDITOR_pageUUID,
              });
            });
          }
        });
      }
      return false;
    },
    /**
     * @desc: 触发操作
     * @param {Object} item 操作
     * @param {Object} data 当前行数据
     */
    doAction(item, data, e) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      this.onNodeClick(data);
      const comEvents = item.eventList || [];
      for (let i = 0; i < comEvents.length; i++) {
        const {
          pattern = undefined,
          eventList = [],
          specialEventList = [],
        } = comEvents[i];
        if (pattern === undefined) {
          if (comEvents[i].key === "click") {
            comEvents[i].effects.forEach((effect) => {
              this.$store.commit("triggerEvents", {
                config: {
                  ...comEvents[i],
                  ...effect,
                },
                element: this.element,
                EDITOR_pageUUID: this.EDITOR_pageUUID,
              });
            });
          }
          break;
        }
        const result = pattern === "special" ? specialEventList : eventList;
        result.forEach((ele) => {
          const { actionType } = ele;
          if (ele.key === "click") {
            if (actionType === "eeAction") {
              this.doEEAction(ele);
            } else if (actionType === "pageAction") {
              ele.effects.forEach((effect) => {
                this.$store.commit("triggerEvents", {
                  config: {
                    ...ele,
                    ...effect,
                  },
                  element: this.element,
                  EDITOR_pageUUID: this.EDITOR_pageUUID,
                });
              });
            }
            // 触发组件行为
            const { behaviors = [] } = ele;
            behaviors.forEach((behavior) => {
              this.$store.commit("triggerEvents", {
                config: {
                  behavior,
                  isBehavior: true,
                },
                element: this.element,
                EDITOR_pageUUID: this.EDITOR_pageUUID,
              });
            });
          }
        });
      }
      return false;
    },
    /**
     * @desc: 删除
     */
    onRemove(data) {
      if (!this.database?.objectData?.uuid || !data) {
        return false;
      }
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const idUUID = this.getIdUUID();
          dataInterface({
            __method_name__: "deleteData",
            object_uuid: this.database.objectData.uuid,
            data_id: data && data[idUUID],
          })
            .then((res) => {
              if (res.status === 200 && res.data.code === 200) {
                this.onSearch();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
      return false;
    },
    /**
     * @desc: 切换树形图选中
     * @param {Boolean} checkAll 是否全选
     */
    onCheckAll(checkAll) {
      this.$nextTick(() => {
        if (this.$refs.treeContent) {
          this.$refs.treeContent.setCheckedNodes(
            checkAll ? this.flatNodes : []
          );
          setTimeout(() => {
            this.onResolveKeys(checkAll ? this.flatNodes : []);
          }, 0);
        }
      });
    },
    /**
     * @desc: 获取当前选中节点
     * @param {Array} checkedNodes 当前节点
     * @param {Array} checkedKeys 选中的节点key列表
     * @param {Array} halfCheckedNodes 半选节点
     * @param {Array} halfCheckedKeys 班璇节点key 列表
     */
    onGetCheckNodes(currentNode, result) {
      const { showCheckBox, showCheckAll } = this.statusConfig;
      if (!showCheckBox) return;
      // , halfCheckedKeys, halfCheckedNode
      const { checkedKeys, checkedNodes } = result;
      if (showCheckAll) {
        this.checkAll = this.flatNodes.length === checkedNodes.length;
      }
      const { resolveFullNode = false, resolveCodes = "" } =
        this.statusConfig || {};
      let resolveKeys = checkedKeys || [];
      if (resolveFullNode) {
        if (resolveCodes && typeof resolveCodes === "string") {
          const codes = resolveCodes.split(";");
          resolveKeys = checkedNodes.map((ele) => {
            const item = {};
            codes.forEach((key) => {
              item[key] = ele[key];
            });
            return item;
          });
        } else {
          resolveKeys = checkedNodes;
        }
      }
      // 将所有选中的数据暴露出去
      this.onResolveKeys(resolveKeys);
      // 触发事件
      const checkEvent = this.element.actionConfig.find(
        (ele) => ele.key === "check"
      );
      if (
        checkEvent &&
        checkEvent.eventList &&
        checkEvent.eventList.length &&
        judgingArchiAPermission(
          this.actionLimit.archiLimit,
          this.actionLimit.permission
        ) &&
        judgingUILogic(this.actionLimit.UILogic, this.subComponentData) &&
        this.validDomLogic("check", checkedNodes)
      ) {
        // 触发行点击事件
        const comEvents = checkEvent.eventList || [];
        for (let i = 0; i < comEvents.length; i++) {
          const {
            pattern,
            eventList = [],
            specialEventList = [],
          } = comEvents[i];
          if (pattern === undefined) {
            if (comEvents[i].key === "click") {
              comEvents[i].effects.forEach((effect) => {
                this.$store.commit("triggerEvents", {
                  config: {
                    ...comEvents[i],
                    ...effect,
                  },
                  element: this.element,
                  EDITOR_pageUUID: this.EDITOR_pageUUID,
                });
              });
            }
          } else {
            const result = pattern === "special" ? specialEventList : eventList;
            result.forEach((ele) => {
              if (ele.key === "click") {
                const { actionType } = ele;
                if (actionType === "eeAction") {
                  this.doEEAction(ele);
                } else if (actionType === "pageAction") {
                  ele.effects.forEach((effect) => {
                    this.$store.commit("triggerEvents", {
                      config: {
                        ...ele,
                        ...effect,
                      },
                      element: this.element,
                      EDITOR_pageUUID: this.EDITOR_pageUUID,
                    });
                  });
                }
              }
              // 触发组件行为
              const { behaviors = [] } = ele;
              behaviors.forEach((behavior) => {
                this.$store.commit("triggerEvents", {
                  config: {
                    behavior,
                    isBehavior: true,
                  },
                  element: this.element,
                  EDITOR_pageUUID: this.EDITOR_pageUUID,
                });
              });
            });
          }
        }
      }
    },
    /**
     * @description: 节点选中状态发生变化时
     * @param {Object} node
     * @param {Boolean} isChecked
     * @param {Boolean} childrenCheck 子节点是否包含选中
     * @return {void}
     */
    onCheckChange(nodeData, isChecked) {
      const eventKey = isChecked ? "$selectedNode" : "$uncheckNode";
      const eventObj = this.element.actionConfig.find(
        (ele) => ele.key === eventKey
      );
      if (
        eventObj &&
        eventObj.eventList &&
        eventObj.eventList.length &&
        judgingArchiAPermission(
          this.actionLimit.archiLimit,
          this.actionLimit.permission
        ) &&
        judgingUILogic(this.actionLimit.UILogic, this.subComponentData) &&
        this.validDomLogic(eventKey, nodeData)
      ) {
        // 触发行点击事件
        const comEvents = eventObj.eventList || [];
        for (let i = 0; i < comEvents.length; i++) {
          const {
            pattern,
            eventList = [],
            specialEventList = [],
          } = comEvents[i];
          if (pattern === undefined) {
            if (comEvents[i].key === "click") {
              comEvents[i].effects.forEach((effect) => {
                this.$store.commit("triggerEvents", {
                  config: {
                    ...comEvents[i],
                    ...effect,
                  },
                  element: this.element,
                  EDITOR_pageUUID: this.EDITOR_pageUUID,
                });
              });
            }
          } else {
            const result = pattern === "special" ? specialEventList : eventList;
            result.forEach((ele) => {
              if (ele.key === "click") {
                const { actionType } = ele;
                if (actionType === "eeAction") {
                  this.doEEAction(ele);
                } else if (actionType === "pageAction") {
                  ele.effects.forEach((effect) => {
                    this.$store.commit("triggerEvents", {
                      config: {
                        ...ele,
                        ...effect,
                      },
                      element: this.element,
                      EDITOR_pageUUID: this.EDITOR_pageUUID,
                    });
                  });
                }
              }
              // 触发组件行为
              const { behaviors = [] } = ele;
              behaviors.forEach((behavior) => {
                this.$store.commit("triggerEvents", {
                  config: {
                    behavior,
                    isBehavior: true,
                  },
                  element: this.element,
                  EDITOR_pageUUID: this.EDITOR_pageUUID,
                });
              });
            });
          }
        }
      }
    },
    /**
     * @desc: 选中框的情况将选中的id暴露并触发更新
     * @param {Array} resolveData
     */
    onResolveKeys(resolveData = {}) {
      // 相同暴露值无需刷新
      // if (resolveData && this.element?.resolveData) {
      // 	if (JSON.stringify(resolveData) === JSON.stringify(this.element?.resolveData)) {
      // 		return;
      // 	}
      // }
      this.$store.commit("modifyComponent", {
        component: {
          ...this.element,
          resolveData,
        },
        containerId: null,
        isModify: true,
        pageUUID: this.EDITOR_pageUUID,
      });
      setTimeout(() => {
        eventBus.$emit("databaseTrigger", {
          componentId: this.element.id,
          isSearch: true,
          action: "any",
          output: resolveData,
        });
      }, 0);
    },
    /**
     * @desc: 导出
     * @param {Object} config 配置
     */
    async doExport(config) {
      const loading = this.$loading({
        text: "导出中....",
      });
      window.$EditorDebug.startExport(this.element);
      const { exportType = "all" } = config;
      const idUUID = this.getIdUUID();
      if (!idUUID) {
        loading.close();
        this.$message.error("导出失败！数据验证错误。");
        window.$EditorDebug.failExport(this.element);
        return;
      }
      /* eslint-disable */
      // exportType 导出配置 all 全部， targetRow 当前选中行
      // 最终导出数据
      // 导出当前点击行
      const { resolveData } = this.element;
      if (exportType === "targetRow") {
        if (!resolveData) {
          loading.close();
          this.$message.error("暂无数据可供导出！");
          window.$EditorDebug.failExport(this.element, "暂无数据可供导出！");
          return;
        }
        // 本页
        this.sheetFormData = resolveData[idUUID];
      } else if (exportType === "all") {
        this.sheetFormData = "all";
      }
      if (!this.sheetFormData) {
        loading.close();
        this.$message.error("导出配置错误！");
        window.$EditorDebug.failExport(this.element, "导出配置错误！");
        return false;
      }
      // 参数
      if (config?.params) {
        const { param = {} } = initParams(
          config.params,
          this.isGroup,
          this.subComponentData,
          this.groupComponents,
          this.EDITOR_pageUUID
        );
        this.exportParam = param;
      }
      this.showSheet = true;
    },
    /**
     * @desc: 导出成功
     */
    onExportSuccess() {
      this.$loading().close();
      this.showSheet = false;
      this.$message.success("导出成功！");
      this.exportParam = {};
      window.$EditorDebug.successExport(this.element);
    },
    /**
     * @desc: 导出错误
     * @param {String} msg 错误信息
     */
    onExportError(msg) {
      this.$loading().close();
      this.showSheet = false;
      this.$message.error(msg || "导出失败！");
      this.exportParam = {};
      window.$EditorDebug.errorExport(this.element);
    },
    /**
     * @description: 选择行（行为）
     */
    onSelectItem(param) {
      if (!param?.key || !this.treeData.length) return;
      // 将默认选中置空
      // this.defaultExpandKeys = [];
      const idUUID = this.getIdUUID();
      const row = this.getRowByKey(this.treeData, param.key, idUUID);
      if (row) {
        this.onNodeClick(row);
      }
      // 视图层多行选中问题
      this.$refs.treeContent.setCurrentKey(param?.key);
    },
    /**
     * @description: 改变行中某字段数据(行为)
     * @param {Object} param 参数对象
     * @return {void}
     */
    changeDataValue(param = {}) {
      if (!param?.rowKey || !this.treeData.length) return;
      const { field, rowKey, value } = param;
      const idUUID = this.getIdUUID();
      // let row = this.getRowByKey(this.treeData, rowKey, idUUID);
      // console.log(row, '-----行数据-----');
      // if (!row) return;
      // const newRow = {
      // 	...row,
      // 	[field]: value
      // }
      const { hasChange = false, list } = this.modifyDataByKey(
        this.treeData,
        rowKey,
        idUUID,
        {
          [field]: value,
        }
      );
      if (!hasChange) return;
      this.treeData = list;
    },
    /**
     * @description: 根据行Key获取行
     * @param {Array} arr 行数据
     * @param {String} key 字段key
     * @param {String} idUUID id的uuid
     * @return {Object/Null} 查询结果
     */
    getRowByKey(arr, key, idUUID) {
      // 此处不用全等因为会涉及类型问题
      let result = arr.find((ele) => ele?.[idUUID] == key);
      if (result) return result;
      for (let i = 0; i < arr.length; i++) {
        const children = arr[i]?.[this.propObj?.children] || [];
        if (children && children.length) {
          result = this.getRowByKey(children, key, idUUID);
          if (result) {
            return result;
          }
        }
      }
      return null;
    },
    /**
     * @description: 根据行Key修改树形数据
     * @param {Array} arr 数组
     * @param {String} key 需要修改的行标识
     * @param {String} idUUID id的uUI的
     * @param {Object} fieldObj 新的数据
     * @return {Object} 结果
     */
    modifyDataByKey(arr, key, idUUID, fieldObj = {}) {
      if (!Array.isArray(arr)) return [];
      const index = arr.findIndex((ele) => ele[idUUID] === key);
      if (index !== -1) {
        const list = arr;
        list.splice(index, 1, {
          ...list[index],
          ...fieldObj,
        });
        return {
          hasChange: true,
          list,
        };
      }
      for (let i = 0; i < arr.length; i++) {
        const children = arr[i]?.[this.propObj?.children];
        if (Array.isArray(children)) {
          const result = this.modifyDataByKey(children, key, idUUID, fieldObj);
          if (result.hasChange) {
            const item = {
              ...arr[i],
              [this.propObj?.children]: result.list,
            };
            arr.splice(i, 1, item);
            return {
              hasChange: true,
              list: arr,
            };
          }
        }
      }
      return {
        hasChange: false,
        list: arr,
      };
    },
    /**
     * @description: 点击菜单项触发的事件回调
     * @param {Object} info 菜单对象
     * @return {void}
     */
    handleCommand(info) {
      const { key, value } = info;
      if (key === "delete") {
        this.onRemove(value);
      } else {
        const { item, data } = value;
        this.doAction(item, data);
      }
    },
  },
  beforeDestroy() {
    eventBus.$off("exportData");
    if (!this.EDITOR_pageUUID) {
      // EDITOR_loopResolve 为循环容器特有
      removeEventBus(
        ["reRenderComponentView", "doComponentBehavior"],
        this.element.id
      );
    }
  },
};
</script>

<style lang="less" scoped>
.common-tree {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow-x: hidden;
  :deep(.el-tree-node) {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  :deep(.el-tree-node__children) {
    overflow: visible;
  }
  :deep(.el-tree-node__content) {
    height: 30px;
    > span {
      line-height: 30px;
    }
    .el-tree-node__expand-icon {
      z-index: 300;
    }
  }
  :deep(.el-tree-node:focus) > .el-tree-node__content {
    background: transparent;
  }
  :deep(
      .el-tree--highlight-current
        .el-tree-node.is-current
        > .el-tree-node__content
    ) {
    background-color: #e8f4ff;
    border-radius: 2px;
  }
  .el-tree-node > .el-tree-node__children {
    overflow: initial;
  }
  .search {
    margin-bottom: 5px;
  }
  .check-all {
    text-align: left;
    box-sizing: border-box;
    padding: 6px 24px;
    border-bottom: 1px solid #f2f3f5;
    :deep(.el-checkbox__input.is-checked+.el-checkbox__label) {
      color: var(--themeColor) !important;
    }
  }
  .tree-content {
    flex: 1;
    width: 100%;
    overflow: visible;
    overflow-y: auto;
  }
  .custom-tree-node {
    flex: 1;
    position: relative;
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    // justify-content: space-between;
    font-size: 14px;
    padding-right: 25px;
    &.current-custom-tree-node {
      position: relative;
      &::after {
        content: "";
        height: 100%;
        width: 100%;
        width: calc(100% + 60px);
        position: absolute;
        left: -30px;
        top: 0;
        background: #e8f4ff;
        // z-index: -1;
      }
      &:hover {
        .tree-count-number {
          display: none;
        }
      }
    }
    span {
      line-height: 26px;
    }
    .label {
      flex: 1;
      text-align: left;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px; // 廖总觉得小了，改成14px, 如果后面觉得大了就改回12px
      z-index: 100;
    }
    .plus-child {
      display: block;
      margin-right: 4px;
      z-index: 100;
      // display: none;
    }
    .out-side-actions {
      margin-right: 4px;
      z-index: 100;
      .iconfont {
        font-size: 16px;
        line-height: 32px;
      }
      .el-image {
        height: 14px;
        width: 14px;
        margin-top: 9px;
        border-radius: 2px;
      }
    }
    .action-content {
      // display: none;
      display: block;
      position: absolute;
      right: 3px;
      top: 0;
      padding: 0 2px;
      overflow: visible;
      z-index: 1000;
      &.action-content-hover {
        display: none;
      }
      &:hover {
        .action-list {
          display: block;
        }
      }
      .action-btn {
        color: @theme;
        padding: 0;
        &.plus-child {
          z-index: 10;
        }
      }
      .action-list {
        position: absolute;
        background: #fff;
        display: none;
        right: 14px;
        top: 10px;
        width: auto;
        min-width: 60px;
        border: 1px solid @borderColor;
        border-radius: 4px;
        background: #fff;
        z-index: 1000;
        box-shadow: 5px 5px 20px 5px rgba(72, 134, 255, 0.1);
        .el-button--small,
        .el-button--small.is-round {
          padding: 0 !important;
        }
        .action-item {
          font-size: 12px;
          box-sizing: border-box;
          padding-top: 3px 5px;
          border-bottom: 1px solid @borderColor;
          line-height: 30px;
          color: @theme;
          font-family: PingFangSC, PingFangSC-Medium;
          text-align: center;
          &:last-of-type {
            border-bottom: none;
          }
          &:hover {
            background: #f2f3f5;
            font-weight: bold;
          }
          &.remove {
            color: @dangerColor;
          }
        }
      }
    }

    &:hover {
      .plus-child,
      .action-content {
        display: block;
      }
    }
  }
  .el-button--small,
  .el-button--small.is-round {
    padding: 4px 0;
    color: #0f62fe;
    line-height: 16px;
    font-weight: bold;
    font-size: 14px;
  }
  .el-tree-node__content {
    &:hover {
      .tree-count-number {
        display: none;
      }
    }
  }
  .tooltip-box {
    width: 100%;
    z-index: 4;
    display: flex;
    align-items: center;
    // hidden后会导致树形图选中样式错误
    overflow: hidden;
    &.current-custom-tree-node {
      position: relative;
      &::after {
        content: "";
        height: 100%;
        width: 100%;
        width: calc(100% + 60px);
        position: absolute;
        left: -30px;
        top: 0;
        background: #e8f4ff;
        // z-index: -1;
      }
      &:hover {
        .tree-count-number {
          display: none;
        }
      }
    }
  }
}
</style>
<style lang="less">
// 仅在大屏容器下生效
.screen-container {
  .common-tree {
    // 声明input
    .el-input__inner {
      background: var(--bgColor);
      color: var(--fontColor);
    }
    // 树的背景
    .el-tree {
      background: var(--bgColor);
      color: var(--fontColor);
    }
    // 树节点的hover效果
    .el-tree-node__content {
      &:hover {
        background: var(--rowHoverBG);
        color: var(--rowHoverColor);
      }
    }
    // 节点的点击后效果
    .el-tree--highlight-current .el-tree-node.is-current {
      & > .el-tree-node__content {
        background: var(--rowActiveBG);
        color: var(--rowHoverColor);
        &:hover {
          background: var(--rowHoverBG);
        }
      }
    }
    // after 会出现覆盖白色，去掉
    .current-custom-tree-node {
      &::after {
        background: transparent !important;
      }
    }
  }
}
</style>
