<!--
 * @Author: qinmengyuan
 * @Date: 2024-09-03 11:23:21
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-09-24 10:49:37
 * @FilePath: \dataview-viewer-test\src\custom-component\form\newParser\FormItem\widget-time-range.vue
-->
<!--
    @name: widget-time-range
    @description：widget-time-range
    @author: ZengWei
    @date: 2022-03-25 09:37
-->
<template>
  <div>
    <div v-if="disabled || element.config.disabled" class="input-show">
      {{ getTimeRange }}
    </div>
    <el-time-picker
      v-else
      v-model="element.value"
      is-range
      :disabled="disabled || element.config.disabled"
      :range-separator="element.config['range-separator']"
      :start-placeholder="element.config['start-placeholder']"
      :end-placeholder="element.config['end-placeholder']"
      :value-format="element.config['value-format']"
      :placeholder="element.config.placeholder"
      style="width: 100%"
    >
    </el-time-picker>
  </div>
</template>

<script>
import { TimePicker } from 'element-ui';
import formItemMinix from '@/custom-component/form/newParser/scripts/formItemMinix';

export default {
  name: 'WidgetTimeRange',
  components: {
    'el-time-picker': TimePicker,
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {};
  },
  computed: {
    getTimeRange() {
      if (typeof this.element.value === 'string') {
        let value = this.element.value.split(',');
        return `${value[0]} - ${value[1]}`;
      } else if (
        Array.isArray(this.element.value) &&
        this.element.value.length > 1
      ) {
        let value = this.element.value.join(' - ');
        return value;
      } else {
        return '-';
      }
    },
  },
  methods: {},
  mounted() {
    if (Array.isArray(this.element.value)) {
      if (this.element.value?.length < 2 && !this.disabled) {
        this.element.value = ['', ''];
      }
    }
  },
};
</script>

<style scoped>
.time-range-show {
  width: 100%;
  height: 32px;
  padding: 0 12px;
  box-sizing: border-box;
  background: #f2f4f7;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2a2f3d;
  cursor: default;
}
</style>
