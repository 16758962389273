/*
 * @Description: 业务组件
 * @Author: luocheng
 * @Date: 2021-10-15 14:53:28
 * @LastEditors: '张星' you@example.com
 * @LastEditTime: 2023-07-03 11:42:09
 */
// 业务组件
const distActionConfig = {
	use: true,
	useDepend: false,
	dependLogic: [],
	type: 'detail',
	eventList: [],
	permission: [], // 权限
	archiLimit: [], // 架构限制
	paramsConfig: [], // 附加参数
}
const business = {
	type: 'business',
	title: '业务组件',
	show: true,
	order: 3,
	componentList: [
		{
			component: 'CommonSelectPerson',
			type: 'selectPerson',
			name: '选择人员',
			icon: 'icon-renyuan',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				filter: true,
				filterText: '请输入关键字',
				label: 'label',
				key: 'id',
				children: 'children'
			},
			style: {
				rotate: 0
			},
			actionConfig: [
				{
					key: 'confirm',
					effect: false,
					effectType: '',
					canCustom: false,
					postType: '', // 请求类型
					buttonType: 'primary',
					text: '确认',
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
				},
				{
					key: 'cancel',
					effect: false, // 是否有附加作用
					effectType: '', // 附加操作类型 请求类型
					canCustom: false, // 是否可以删除
					buttonType: '',
					text: '取消',
					postType: '', // 请求类型
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
				}
			]
		},
		{
			component: 'CommonFlow',
			type: 'flowEngine',
			name: '旧版流程',
			icon: 'icon-liuchengtu',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			style: {
				height: '500',
				width: '',
				rotate: 0
			},
			flowConfig: {
				configType: '',
				object_uuid: '',
				view_uuid: '',
				form_uuid: '',
				customConfig: { // 自定义组件取值来源
					componentId: '',
					object_uuid_field: '',
					view_uuid_field: '',
					_uuid_field: '',
				}
			}
		},
    {
      component: 'NewCommonFlow',
      type: 'flowEngine',
      name: '新版流程',
      icon: 'icon-liuchengtu',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
      },
      style: {
        height: '500',
        width: '',
        rotate: 0
      },
      flowConfig: {
        configType: '',
        object_uuid: '',
        view_uuid: '',
        form_uuid: '',
        is_template: false, // 是否模板流程
        customConfig: { // 自定义组件取值来源
          componentId: '',
          object_uuid_field: '',
          view_uuid_field: '',
          _uuid_field: '',
        }
      }
    },
		{
			component: 'CommonCalendar',
			type: 'calendar',
			name: '日历',
			icon: 'icon-rili',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				type: 'date', // 日历类型
				calendarType: 'base', // 基础日历
				headerFormat: 'sundayEnd', // 日历头部类型
				valueFormat: 'YMDhms', // 值类型
				startYear: 2008, // 开始年份
				totalTaking: 'odd', // 总数默认取偶数
				finishTaking: 'even', // 已完成默认取奇数
				haveData: true,
				noData: true,
				haveDataColor:'rgb(251, 98, 92)',
				noDataColor:'rgb(59, 203, 206)'
			},
			style: {
				height: '',
				width: '100%',
				rotate: 0
			},
			countConfig: {
				finish_object_uuid: '',
				finish_view_uuid: '',
				finish_field_uuid: '',
				finish_aggregate: '4',
				finishParams: {
					userOutParams: false,
					paramsConfig: []
				},
				total_object_uuid: '',
				total_view_uuid: '',
				total_field_uuid: '',
				total_aggregate: '4',
				totalParams: {
					userOutParams: false,
					paramsConfig: []
				}
			}
		},
		{
			component: 'CommonNetDisk',
			type: 'container',
			name: '网盘',
			icon: 'icon-wangpan',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: -1,
			statusConfig: {
				displayShow: true,
				partName: '', // 当前模块名称
				isShow: true, // 是否显示
				newFolder: true, // 新建文件夹
				canUpload: true, // 上传文件
				pushFile: true, // 添加文件(从功能网盘)
				showSearch: true, // 使用搜索
				changeModel: false, // 切换显示模式
				showActions: true, // 显示操作
				showInfoBtn: true, // 显示详情按钮
				showModifyBtn: true, // 显示编辑按钮
				showShareBtn: true, // 显示分享按钮
				showDownloadBtn: true, // 显示下载按钮
				showMoveBtn: true, // 显示移动按钮
				showRemoveBtn: true, // 显示删除按钮
				multiple: false, // 多文件上传
				batchAction: false // 批量操作
			},
			style: {
				height: '100%',
				width: '100%',
				rotate: 0
			},
			actionConfig: {
				upload: {
					...distActionConfig,
					name: '上传文件'
				},
				create: {
					...distActionConfig,
					name: '新增'
				},
				plus: {
					...distActionConfig,
					name: '添加文件'
				},
				details: {
					...distActionConfig,
					name: '详情'
				},
				download: {
					...distActionConfig,
					name: '下载'
				},
				share: {
					...distActionConfig,
					name: '分享'
				},
				move: {
					...distActionConfig,
					name: '移动'
				},
				rename: {
					...distActionConfig,
					name: '重命名'
				},
				delete: {
					...distActionConfig,
					name: '删除'
				},
			},
			businessConfig: { // 业务自定义配置
				objectUUID: '', // 对象
				defaultView: '', // 默认视图
				detailsView: '', // 详情视图不填则使用默认视图
			},
			operationConfig: {
				fileViewUUID: '', // 文件视图UUID
				withClass: false,
				objectUUID: '',
				viewUUID: '',
				idUUID: '',
				labelUUID: '',
				childrenUUID: '',
				typeKey: ''
			}
		},
		{
			component: 'CommonConfigPage',
			type: 'configPage',
			name: '配置页',
			icon: 'icon-peizhi',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true
			},
			style: {
				height: '100%',
				width: '100%',
				rotate: 0,
				overflowY: 'auto'
			},
			actionConfig: {
				needAction: false,
				permission: [], // 权限
				archiLimit: [], // 架构限制
				edit: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'edit',
					name: '编辑',
					btnType: 'text',
					color: '#67C23A',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			}
		},
		{
			component: 'CommonStatTableOne',
			type: 'container',
			name: '统计列表',
			icon: 'icon-liebiao',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true,
				planKey: '',
				actualKey: '',
				fixedColumn: false,
				showSelect: false
			},
			style: {
				height: '100%',
				width: '100%',
				rotate: 0,
				overflowY: 'hidden'
			},
			actionConfig: {}
		},
		{
			component: 'CommonStatTableTwo',
			type: 'container',
			name: '统计列表-1',
			icon: 'icon-liebiao',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true,
				fixedColumn: false
			},
			style: {
				height: '100%',
				width: '100%',
				rotate: 0,
				overflowY: 'hidden'
			},
			actionConfig: {}
		},
		{
			component: 'CommonProjectMap',
			type: 'container',
			name: '项目分布',
			icon: 'icon-a-GISdiqiu',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true,
			},
			style: {
				height: 500,
				width: 400,
				rotate: 0,
				overflowY: 'hidden'
			},
			actionConfig: {
				clickProject: {
					label: '点击项目',
					type: 'clickProject',
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
					UILogic: []
				}
			},
			behaviors: [
				{
					label: '测试行为',
					value: 'testBehavior',
					desc: '测试需要参数： id: 11123'
				}
			]
		},
		// {
		// 	component: 'AIOTTabs',
		// 	type: 'AIOTTabs',
		// 	name: '模型显示Tab',
		// 	icon: 'icon-tabxuanxiangka',
		// 	isLock: false, // 是否锁定
		// 	needCommonStyle: false,
		// 	propValue: '',
		// 	span: -1,
		// 	statusConfig: {
		// 		displayShow: true,
		// 		isShow: true,
		// 		fixedColumn: false
		// 	},
		// 	style: {
		// 		height: '100%',
		// 		width: '100%',
		// 		rotate: 0,
		// 		overflowY: 'hidden',
		// 	}
		// },
		// 迷你卡片
		{
			component: 'MiniCard',
			type: 'MiniCard',
			name: '迷你图标卡片',
			icon: 'icon-tubiao',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true,
				title: '测试路由',
				description: '测试描述',
				iconUrl: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.uimaker.com%2Fuploads%2Fallimg%2F140114%2F1_140114095705_3.jpg&refer=http%3A%2F%2Fwww.uimaker.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1659007588&t=f6a364010fc31312bdd48f2a918172e3',
				route: '',
				pageUUID: '',
				menuType: 0
			},
			style: {
				height: 78,
				width: 256,
				rotate: 0,
			}
		},
		// 卡片
		{
			component: 'LargeCard',
			type: 'LargeCard',
			name: '图标卡片',
			icon: 'iconzhengfangxing',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true,
				title: '工单系统',
				description: '项目模型管理查看编辑修改',
				miniIconUrl: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.uimaker.com%2Fuploads%2Fallimg%2F140114%2F1_140114095705_3.jpg&refer=http%3A%2F%2Fwww.uimaker.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1659007588&t=f6a364010fc31312bdd48f2a918172e3',
				largeIconUrl: 'https://img2.baidu.com/it/u=2113220341,3117661873&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
				type: 'default',
				route: '',
				pageUUID: '',
				menuType: 0
			},
			style: {
				height: 372,
				width: 200,
				rotate: 0,
				// width:532,
				// height:176
			}
		},
		// {
		// 	component: 'CommonMonitorSign',
		// 	type: 'monitorSign',
		// 	name: '监控标记',
		// 	icon: 'icon-jiankong',
		// 	isLock: false, // 是否锁定
		// 	needCommonStyle: false,
		// 	propValue: '',
		// 	span: -1,
		// 	statusConfig: {
		// 		displayShow: true,
		// 		isShow: true,
		// 		fixedColumn: false
		// 	},
		// 	monitorConfig: {
		// 		token: "",
		// 		id: "",
		// 		type: 'ys7',
		// 		device_serial: "",
		// 		channel_id: "",
		// 		signBg: "#FFFFFF",
		// 		signCol: "#409EFF",
		// 	},
		// 	behaviors: [
		// 		{
		// 			label: '监控标记显示',
		// 			value: 'showMonitorSigns',
		// 			desc: ''
		// 		},
		// 	],
		// 	style: {
		// 		height: '100%',
		// 		width: '100%',
		// 		rotate: 0,
		// 	}
		// },
		// {
		// 	component: 'CommonTowerSign',
		// 	type: 'towerSign',
		// 	name: '塔吊标记',
		// 	icon: 'icon-tadiao',
		// 	isLock: false, // 是否锁定
		// 	needCommonStyle: false,
		// 	propValue: '',
		// 	span: -1,
		// 	statusConfig: {
		// 		displayShow: true,
		// 		isShow: true,
		// 		fixedColumn: false
		// 	},
		// 	towerConfig: {
		// 		towerLong: 63,
		// 		armBg: "rgba(33, 106, 208, 0.6)",
		// 		signBg: "#FFFFFF",
		// 		signCol: "#409EFF",
		// 	},
		// 	behaviors: [
		// 		{
		// 			label: '塔吊标记显示',
		// 			value: 'showTowerSigns',
		// 			desc: ''
		// 		},
		// 	],
		// 	style: {
		// 		height: '100%',
		// 		width: '100%',
		// 		rotate: 0,
		// 	}
		// },
		{
			component: 'CommonDateButton',
			type: 'button',
			name: '日期新增',
			icon: 'icon-riqishijian',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '新增',
			style: {
				width: 78
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				type: 'primary', // primary / success / warning / danger / info / text
				size: '', // medium / small / mini
				plain: false,
				round: false,
				circle: false,
				loading: false,
				disabled: false,
				icon: '',
				autofocus: false,
				outId: '',
				needTime: false,
				dateFormat: 'date',
				valueFormat: 'yyyy-MM-dd hh:mm:ss',
				pickerType: 'date',
				straddleYear: false,
				advance: false
			},
			actionConfig: {} // 筛选参数
		},
		{
			component: 'CommonSubitemTemplate',
			type: 'subitemPage',
			name: '分部分项(标准库)页面',
			icon: 'icon-fenbufenxiang',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			style: {
				width: '100%',
				height: '100%'
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				title: '标准用表',
				type: 'table', // 类型
				needTreeTerminal: false,
			},
			database: {
				objectUUID: 'object62830dfd61652',
				viewUUID: 'view62845e89b1dfa'
			},
			paramsConfig: [
				{
					paramType: 'param', // 请求类型
					code: 'table_type', //  字段code
					fieldUUID: '', // 字段UUID
					isRequired: true, // 是否必填字段
					originType: 'fixed', // 参数来源. 组件 component， URL参数 url
					componentId: '', // 来源组件 Id
					componentKey: '', // 组件字段key
					urlKey: '', // url参数的key
					ruleType: '', // 逻辑类型
					fixedValue: '监理表格' // 固定值
				}
			]
		},
		{
			component: 'CommonPlatformDynamics',
			type: 'business',
			name: '平台动态',
			icon: 'icon-dongtai',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			style: {
				width: '100%',
				height: '100%',
			},
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'row', // flex 主轴方向
				flexWrap: false, // 是否换行
				fixedSize: false, // 大屏固定尺寸显示
				isAbsolute: false,
				absoluteTop: 0,
				absoluteLeft: 0,
				zIndex: 10
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			database: {
				objectUUID: 'object62830dfd61652',
				viewUUID: 'view62845e89b1dfa'
			},
			paramsConfig: [
				{
					paramType: 'param', // 请求类型
					code: 'table_type', //  字段code
					fieldUUID: '', // 字段UUID
					isRequired: true, // 是否必填字段
					originType: 'fixed', // 参数来源. 组件 component， URL参数 url
					componentId: '', // 来源组件 Id
					componentKey: '', // 组件字段key
					urlKey: '', // url参数的key
					ruleType: '', // 逻辑类型
					fixedValue: '监理表格' // 固定值
				}
			]
		},
		{
			component: 'BindComponent',
			type: 'container',
			name: '绑定组件',
			icon: 'iconfujiguanlian',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			style: {
				width: '100%',
				height: '100%',
			},
			specialStyle: {
				zIndex: 100
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			bindConfig: {
				type: 'bim',
				isBtn: true,
				appSecret:process.env.VUE_APP_MODEL_APPSECRET,
				appId:process.env.VUE_APP_MODEL_APPID
			},
			actionConfig: {
				needAction: true,
				setData: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'setData',
					name: '确定',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				back: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'back',
					name: '返回',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
			}
		},
		{
			component: 'CommonGantt',
			type: 'container',
			name: '甘特图',
			icon: 'icongis_moxingguanli',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			style: {
				width: '100%',
				height: '100%',
			},
			specialStyle: {
				zIndex: 100
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			ganttConfig : {
				textField:'',//名称显示字段
				frontLineField:'',//前锋线fielduuid字段判断
				startDateField:'',//计划开始时间fielduuid
				endDateField:'',//计划结束时间fielduuid
				checkbox:false,//显示多选框
        bimField:'',//绑定的bim模型fielduuid
        gisField:'',//绑定的gis模型fielduuid
        taskColField:'',//绑定的任务颜色fielduuid
			},
			actionConfig: {
				needAction: true,
				clickTask: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'clickTask',
					name: '点击任务',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				checkTask: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'checkTask',
					name: '选中任务',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				addTask: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'addTask',
					name: '新增任务',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				detailTask: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'detailTask',
					name: '任务详情',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				editTask: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'editTask',
					name: '编辑任务',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				deleteTask: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'deleteTask',
					name: '删除任务',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
			},
			behaviors: [
				{
					label: '仅显示表格数据',
					value: 'onOnlyTable',
					desc: '仅显示表格数据'
				},
				{
					label: '仅显示甘特图',
					value: 'onOnlyGantt',
					desc: '只显示甘特图部分'
				},
				{
					label: '重置/刷新',
					value: 'onReset',
					desc: '刷新或重置甘特图数据'
				},
				{
					label: '隐藏任务',
					value: 'onHideTask',
					desc: ''
				},
				{
					label: '适应屏幕',
					value: 'onZoomFit',
					desc: '甘特图进度适应调整'
				},
				{
					label: '缩放甘特图显示比例',
					value: 'onZoomIn',
					desc: ''
				},
				{
					label: '放大甘特图缩放比例',
					value: 'onZoomOut',
					desc: ''
				},
				{
					label: '滚动到指定的甘特图位置',
					value: 'onSrcollToGantt',
					desc: '参数配置事件名task_id'
				},
				{
					label: '开启前锋线',
					value: 'onShowFrontLine',
					desc: '无参数配置'
				},
				{
					label: '关闭前锋线',
					value: 'onHideFrontLine',
					desc: '无参数配置'
				},
				{
					label: '搜索名称',
					value: 'onFilterName',
					desc: '参数配置事件名name'
				},
				{
					label: '筛选任务时间段',
					value: 'onFilterDate',
					desc: '参数配置事件名start_date、end_date'
				},
			]
		},
		{
			component: 'CommonArchiTree',
			type: 'business',
			name: '组织架构树',
			icon: 'icon-shuxingliebiao',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			style: {
				width: '100%',
				height: '100%',
			},
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'column', // flex 主轴方向
				flexWrap: false, // 是否换行
				fixedSize: false, // 大屏固定尺寸显示
				isAbsolute: false,
				absoluteTop: 0,
				absoluteLeft: 0,
				zIndex: 10
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			database: {
				objectUUID: 'object62830dfd61652',
				viewUUID: 'view62845e89b1dfa'
			},
			paramsConfig: [],
			actionConfig: [
				{
					key: 'rowClick',
					type: 'rowClick',
					name: '点击行',
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			]
		},
		{
			component: 'SyncPlanModel',
			type: 'button',
			name: '同步模型',
			icon: 'icon-anniu',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '同步模型',
			valueOrign:'database',
			style: {
				height: '',
				width: '',
				lineHeight: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0,
				fontSize: 12
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				type: 'primary', // primary / success / warning / danger / info / text
				size: '', // medium / small / mini
				plain: false,
				round: false,
				circle: false,
				loading: false,
				disabled: false,
				icon: '',
				autofocus: false,
				outId: ''
			},
			syncConfig: {
				textName:'同步模型',//按钮名称
				modelField:'',//模型字段
				codeField:'',//code字段
			},
			behaviors: [
				{
					label: '同步进度计划模型',
					value: 'onSyncPlanModel',
					desc: '参数配置名为schedule_id,即进度计划id'
				},
			]
		},
	]
};

export default business;
