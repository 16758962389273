<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2024-05-27 10:55:25
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2024-09-26 13:55:58
 * @FilePath: \dataview-next\src\custom-component\functionCom\Rocker.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="rocker-wrap">
    <div class="rocker-inner-wrap">
      <div class="radius-block">
        <div class="roker-container">
          <div id="nipple_container" v-ptzDirectives="handlePTZMove" >
          </div>
        </div>
      </div>
      <div class="btn-arr">
        <span>
          <el-button class="action-button" type="text" @click="handleScaleChange('scaleup')">
            <i class="iconfont iconbim_jia"/>
          </el-button>
        </span>
        <span>
          <el-button class="action-button" type="text" @click="handleScaleChange('scaledown')">
            <i class="iconfont iconbim_jian"/>
          </el-button>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import nipplejs from 'nipplejs';
import Minin from './mixin'
import { dataInterface } from '@/apis/data/index'
import { getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';
export default{
  props: {
    // 循环映射值
		mapData: {
			type: Object,
			default: () => {}
		}
  },
  mixins: [Minin],
  directives: {
    /**
     * 云台控制指令
     */
    ptzDirectives: {
      inserted: (el, binding) => {
        const options = {
          mode: 'static',
          size: 100,
          force: 0.2,
          pressure: 0.1,
          color: 'ddd',
          position: {
            left: '50%',
            top: '50%'
          },
          zone: el
        };
        const manager = nipplejs.create(options);
        manager.on('move', (evt, data) => {
          binding.value(evt, data);
        });
        manager.on('end', (evt, data) => {
          binding.value(evt, data);
        });
      }
    }
  },
  data() {
    return {
      PTZTimer: null,
      actionLoading: false,
      url: '/api/hikvision/CameraControlling',
      outURL: '/api/hikvision/wvp/Controlling '
    }
  },
  computed: {
    cameraIndexCode() {
      switch(this.element.valueOrign) {
        case 'fixed': {
          return this.element.propValue;
        }
        case 'component': {
          return this._getValueFromComponent(this.element.componentValueConfig)
        }
        case 'database': {
          if(this.dataObj && this.bindUUID) {
            if(this.databaseType === 'value') {
              return this.getBindValue();
            } else if(this.databaseType === 'wordbook' && this.metaData) {
              return this.metaData.name
            }
          }
          break;
        }
        case 'valueMap': {
          return this._getValueFromMap()
        }
      }
      return null;
    },
    outerNet() {
      return !!this.element.statusConfig.outerNet
    },
    ...mapState(['componentData', 'signComponentData', 'subsidiaryComponentData']),
		// 取值组件列表
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentData || [];
			}
			return this.componentData || [];
		},
		// 数据仓库
		database() {
			return this.element.database;
		},
		// 字段对象
		dataObj() {
			if (!this.database?.containerKey) return {};
			let container = getComponentById(this.subComponentData, this.database.containerKey);
			if (!container && this.isGroup && this.groupComponents.length) {
				container = getComponentById(this.groupComponents, this.database.containerKey);
			}
			if ((!container || !container.containerData) && !this.fullData) return {};
			// 列表情况默认返回一个
			if (Array.isArray(container.containerData) && container.containerData.length) {
				return container.containerData?.[0] || {};
			}
			// 列表有分页和不分页情况
			if (this.fullData) {
				if (Array.isArray(this.fullData)) {
					// 分页
					return this.fullData[0] || {};
				} else if (Array.isArray(this.fullData?.data)) {
					// 不分页
					return this.fullData?.data?.[0] || {};
				}
				return this.fullData;
			}
			const result = container?.containerData || this.fullData;
			return result;
		},
		// 绑定的uuid
		bindUUID() {
			return this.element?.database?.bindUUID ?? '';
		},
		// 用于获取绑定的数据字典(描述文字)
		metaData() {
			if (!this.element?.database) return null;
			const { fieldList, bindUUID } = this.element.database;
			return fieldList.find((ele) => ele.uuid === bindUUID);
		},
		// 数据绑定类型
		databaseType() {
			if (!this.element?.database) return 'value';
			return this.element.database.databaseType ?? 'value';
		}
  },
  methods: {
    /**
     * @description: 云台移动
     * @param {*} ins
     * @param {*} data
     * @return {*}
     */    
    handlePTZMove(ins, data) {
      if(data && data.direction) {
        const { angle } = data.direction;
        if(angle) {
          this.handlePTZEvent(angle)
        }
      }
    },
    /**
     * @description: 缩放变化
     * @param {*} ins
     * @return {*}
     */    
    handleScaleChange(ins) {
      this.handlePTZEvent(ins)
    },
    handlePTZEvent(instruct) {
      if(this.PTZTimer || this.actionLoading) return;
      this.doPTZAction(instruct);
      this.PTZTimer = setTimeout(() => {
        this.PTZTimer = null;
      }, 1000)
    },
    doPTZAction(instruct) {
      const REQDATA = {
        cameraIndexCode: this.cameraIndexCode,
        command: '',
        action: ''
      }
      if(!REQDATA.cameraIndexCode) {
        this.$message.error('未绑定摄像头id');
        return;
      }
      switch(instruct) {
        case 'up': {
          REQDATA.command = this.outerNet ? 'up': 'UP';
          break
        }
        case 'down': {
          REQDATA.command = this.outerNet ? 'down': 'DOWN';
          break
        }
        case 'left': {
          REQDATA.command = this.outerNet ? 'left': 'LEFT';
          break
        }
        case 'right': {
          REQDATA.command = this.outerNet ? 'right': 'RIGHT';
          break
        }
        case 'right-top': {
          REQDATA.command = this.outerNet ? 'upright': 'RIGHT_UP';
          break
        }
        case 'right-bottom': {
          REQDATA.command = this.outerNet ? 'downright': 'RIGHT_DOWN';
          break
        }
        case 'left-top': {
          REQDATA.command = this.outerNet ? 'upleft': 'LEFT_UP';
          break
        }
        case 'left-bottom': {
          REQDATA.command = this.outerNet ? 'downleft': 'LEFT_DOWN';
          break
        }
        case 'scaleup': {
          REQDATA.command = this.outerNet ? 'zoomin': 'ZOOM_IN';
          break
        }
        case 'scaledown': {
          REQDATA.command = this.outerNet ? 'zoomout': 'ZOOM_OUT';
          break
        }
      }
      if(!REQDATA.command) {
        this.$message.error('非法操作');
        return;
      }
      this.startAction(REQDATA);
    },
    startAction(config) {
      this.actionLoading = true;
      config.action = 0
      const url = this.outerNet ? this.outURL : this.url;
      if(this.outerNet) {
        config.monitor_id = config.cameraIndexCode;
      }
      dataInterface(config, url, 'post').then((res) => {
        if(res.data.code === 200) {
          setTimeout(() => {
            this.endAction(config)
          }, 999)
        } else {
          this.actionLoading = false;
        }
      }).catch(() => {
        this.actionLoading = false;
      })
    },
    endAction(config) {
      config.action = 1
      if(this.outerNet) {
        config.command = 'stop'
      }
      const url = this.outerNet ? this.outURL : this.url;
      dataInterface(config, url, 'post').then(() => {
      }).catch(() => {
      }).finally(() => {
        this.actionLoading = false;
      })
    },
    getBindValue() {
			if (['resolveLength', 'fullLength'].includes(this.bindUUID)) {
				let container = getComponentById(this.subComponentData, this.database.containerKey);
				if (!container && this.isGroup && this.groupComponents.length) {
					container = getComponentById(this.groupComponents, this.database.containerKey);
				}
				if (!container) return 0;
				const { containerData, resolveData } = container;
				if (this.bindUUID === 'resolveLength') {
					return Array.isArray(resolveData) ? resolveData.length : 0;
				}
				if (this.bindUUID === 'fullLength') {
					return  Array.isArray(containerData) ? containerData.length : 0;
				}
			}
			return this.dataObj[this.bindUUID] || null;
		},
    /**
		 * @desc: 暴露数据
		 */
		toResolveData() {
			const { valueOrign = 'fixed', componentValueConfig = {} } = this.element;
			if (valueOrign === 'fixed') {
				// 固定值
				this.element.resolveData = {
					value: this.element.propValue || ''
				};
			} else if (valueOrign === 'component') {
				// 其他组件
				this.element.resolveData = {
					value: this._getValueFromComponent(componentValueConfig)
				};
			} else if (valueOrign === 'database') {
				// 数据仓库
				this.element.resolveData = {
					value: this._getDatabaseData()
				};
			}
			this.$store.commit('updatePageCustomStatus', {
        origin: this.element,
        resolveData: this.element.resolveData
      });
		},
    /**
		 * @desc: 获取数仓数据
		 */
		_getDatabaseData() {
			if (!this.dataObj || !this.bindUUID) return '';
			if (this.databaseType === 'value') {
				// 绑定值
				return this.dataObj[this.bindUUID] || null;
			}
			if (this.databaseType === 'wordbook' && this.metaData) {
				// 数据字典(描述文字)
				return this.metaData.name || '';
			}
			return '';
		},
    /**
		 * @desc: 获取组件来源绑定值
		 * @param {Object} config
		 */
		_getValueFromComponent(config) {
			if (!config || !config.component || !config.field || (!this.subComponentData && !this.componentList)) return '';
			let component = getComponentById(this.componentList || this.subComponentData, config.component);
			if(!component && !this.isGroup && this.signComponentData.length){
				//标记的取值
				component = getComponentById(this.signComponentData, config.component);
			}
			if(!component && this.isGroup && this.groupComponents.length) {
				component = getComponentById(this.groupComponents, config.component);
			}
			if (!component) return '';
			if (config.field === 'propValue') {
				return component.propValue;
			} else if(component.resolveData && component.resolveData[config.field]){
				return component.resolveData[config.field]
			}
			if (!component.customStatus) return '';
			return component.customStatus[config.field];
		},
    /**
		 * @desc: 获取映射值
		 */
		_getValueFromMap() {
			const { bindField } = this.element?.loopMapConfig || {};
			if (!bindField) return null;
			const { renderData } = this.mapData || {};
			if (!renderData) return null;
			return renderData?.[bindField] || null;
		}
  }
}
</script>
<style lang="less">
  .rocker-wrap {
    width: 100%;
    height: 100%;
    .rocker-inner-wrap{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .action-button{
        padding: 0;
        color: #000;
      }
      .radius-block {
        position: relative;
        width: 100px;
        height: 100px;
        margin: 10px auto;
        overflow: hidden;
        cursor: pointer;
        .roker-container {
          display: flex;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          position: relative;
          #nipple_container {
            position: relative;
            width: 100%;
            height: 100%;
            cursor: pointer;
            .front {
              background: red;
              user-select: none;
            }
            .back {
              background-image: url("../video/images/yuntai.png");
              background-size: cover;
              background-position: center;
              opacity: unset !important;
            }
            .nipple{
              opacity: unset !important;
            }
          }
        }
      }
      .btn-arr{
        width: 26px;
        height: 86px;
        background: #FFFFFF;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin-left: 20px;
      }
    }
  }
</style>