var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-date calendar-year"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"calendar-header"},[_c('span',{on:{"click":function($event){return _vm.changeYearList(0)}}},[_vm._v("<<")]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.currenYearObj.year))]),_c('span',{on:{"click":function($event){return _vm.changeYearList(1)}}},[_vm._v(">>")])]),(_vm.showDateList && _vm.showDateList.length)?_c('ul',{staticClass:"date-list"},_vm._l((_vm.showDateList),function(item,index){return _c('li',{key:index,staticClass:"date-year date-item month-item",class:{
          'is-today': item.isTarget,
          'is-active': _vm.currenYearObj && _vm.currenYearObj.year === item.year,
        },on:{"click":function($event){return _vm.onYear(item)}}},[(_vm.calendarType === 'base')?_c('div',{staticClass:"base-item"},[_vm._v(" "+_vm._s(item.isTarget ? '今年' : item.year)+" ")]):(
            _vm.calendarType === 'withDatabase' ||
            _vm.calendarType === 'justTotal' ||
            _vm.calendarType === 'showDot'
          )?_c('div',{staticClass:"with-data-item"},[_c('p',{staticClass:"date-text"},[_vm._v(" "+_vm._s(item.isTarget ? '今年' : item.year)+" ")]),_c('p',{staticClass:"count-text"},[(_vm.calendarType !== 'showDot')?_c('span',{staticClass:"finish-count",class:{
                'just-total': _vm.calendarType === 'justTotal',
                'null-count': !+item.finishCount,
              }},[_vm._v(_vm._s(item.finishCount))]):_vm._e(),(_vm.calendarType === 'withDatabase')?[_c('span',{staticClass:"separate",class:{
                  'null-count': !+item.totalCount && !+item.finishCount,
                }},[_vm._v("/")]),_c('span',{staticClass:"total-count",class:{
                  'null-count': !+item.totalCount,
                }},[_vm._v(_vm._s(item.totalCount))])]:_vm._e(),(_vm.calendarType === 'showDot')?[(+item.finishCount > 0 ? _vm.haveData : _vm.noData)?_c('div',{staticClass:"dot",style:({
                  background:
                    +item.finishCount > 0 ? _vm.haveDataColor : _vm.noDataColor,
                })}):_vm._e()]:_vm._e()],2)]):_vm._e()])}),0):_c('el-empty',{attrs:{"description":"暂无数据"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }