<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2023-10-26 15:26:05
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2024-11-05 16:34:00
 * @FilePath: /dataview-next/src/custom-component/functionCom/h265/Index.vue
 * @Description: 
-->
<template>
  <div class="h265-player" :styleObj="styleObj">
    <div :id="playerId" class="glplayer"></div>
    <footer class="actions">
      <section class="left">
        <span class="action-item" v-if="playing" @click.stop="onResume">
          <i class="iconfont iconkaishi" ></i>
        </span>
        <span class="action-item" v-else-if="!playing" @click.stop="onPlay">
          <i class="iconfont iconzanting"></i>
        </span>
      </section>
      <section class="right">
        <span class="action-item" @click.stop="onFullScreen">
          <i v-if="isFull" class="iconfont icondaping-feiquanping"></i>
          <i v-else class="iconfont icondaping-quanping"></i>
        </span>
      </section>
    </footer>
  </div>
</template>

<script>
import { getComponentId } from '@/utils/tools';
import { Player } from './player';

export default {
  name: 'H265Player',
  props: {
    // 播放链接
    url: {
      type: String,
      default: ''
    },
    // 自动播放
    autoPlay: {
      type: Boolean,
      default: true
    },
    // 尺寸
    styleObj: {
      type: Object,
      default: () => {
        return {
          height: 300,
          width: 500
        }
      }
    }
  },
  data() {
    return {
      playerId: getComponentId('h265'),
      instance: null,
      // 播放状态
      playing: true,
      // 是否全屏
      isFull: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      let extInfo = {
        moovStartFlag: true,
        autoPlay: this.autoPlay, // 自动播放
      };
      if (this.url?.includes('.mp4')) {
        // MP4配置
        extInfo = {
          ...extInfo,
          moovStartFlag: true,// map4需要开启
          coreProbePart: 0.1, //探测Mp4媒体信息，间隔：“0.0~1.0”，传统的Mp4文件，moov盒子在mdat盒子前面，使用0.1（10%）（仅适用于Bak播放器核心模式（测试版））
          // cacheLength: 265, // mp4 设置为265
          readyShow: true, // 
        };
      }
      const player = new Player({
        presetConfig: {
          ...this.styleObj,
          player: this.playerId,
          extInfo
        }
      });
      player.init(this.url);
      player.instance.do();
      this.instance = player.instance;
      this.instance.onPlayState = state => {
        // 播放器状态改变
        this.playing = state;
        // TIPS 下面的全屏切换并不可靠，如果是hvec下必是video, 那么目前只有这个最可靠。
        const videoElement = this.$el.querySelector('video');
        if(videoElement) {
          videoElement.addEventListener('fullscreenchange', () => {
            if(document.fullscreenElement) {
              this.$emit('videoAction', 'fullScreen')
            } else {
              this.$emit('videoAction', 'exitFullScreen')
            }
          })
        }
      };
    });
  },
  methods: {
    /**
     * @description: 播放
     */
    onPlay() {
      this.instance.play();
    },
    /**
     * @description: 暂停
     */
    onResume() {
      this.instance.pause();
    },
    /**
     * @description: 切换全屏
     */
    onFullScreen() {
      if (this.isFull) {
        this.instance.closeFullScreen();
        this.isFull = false;
      } else {
        this.instance.fullScreen();
        this.isFull = true;
      }
    }
  },
  beforeDestroy() {
    try {
      this.instance.release();
    } catch(err) {
      console.log(err);
    }
  }
}
</script>

<style lang="less" scoped>
.h265-player {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  &>section {
    height: 24px;
    line-height: 24px;
  }
  &:hover {
    .actions {
      display: flex;
    }
  }
  .actions {
    // display: flex;
    display: none;
    position: absolute;
    bottom: 0;
    height: 40px;
    width: 100%;
    padding: 8px 12px;
    box-sizing: border-box;
    // background: linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.9));
    background: rgba(256, 256, 256, .1);
    align-items: center;
    justify-content: space-between;
    transition: all .2s;
    section {
      display: flex;
    }
    .action-item{
      color: #fff;
      height: 24px;
      line-height: 24px;
      .iconfont{
        font-size: 24px;
        line-height: 24px;
      }
      &:hover{
        cursor: pointer;
        color: #4093ff;
      }
    }
    .right{
      .action-item{
        & + .action-item {
          margin-left: 16px;
        }
      }
    }
  }
}
</style>