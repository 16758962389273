<!--
 * @Description: 年周选择器
 * @Author: luocheng
 * @Date: 2022-08-01 10:23:37
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-09-18 15:37:32
-->
<template>
  <div class="common-date year-week">
    <CalendarHeader
      :headerType="'year'"
      :year="year"
      @setQuickDate="getQuickDate"
    ></CalendarHeader>
    <article class="content" v-if="activeWeek">
      <ul class="date-list" v-if="showDateList && showDateList.length">
        <li
          class="date-item"
          v-for="(item, index) in showDateList"
          :key="index"
          :class="{
            'is-today': item.isTargetWeek,
            'is-active': activeWeek && isActiveWeek(item),
          }"
          @click="onWeek(item)"
        >
          <!-- 基础类型 -->
          <!-- || item.type !== 'target' -->
          <div class="base-item" v-if="calendarType === 'base'">
            {{ item.isTargetWeek ? '本周' : item.desc }}
          </div>
          <!-- 符合类型 -->
          <div
            class="with-data-item"
            v-else-if="
              calendarType === 'withDatabase' ||
              calendarType === 'justTotal' ||
              calendarType === 'showDot'
            "
          >
            <p class="date-text">
              {{ item.isTargetWeek ? '本周' : item.desc }}
            </p>
            <p class="count-text">
              <span
                class="finish-count"
                v-if="calendarType !== 'showDot'"
                :class="{
                  'just-total': calendarType === 'justTotal',
                  'null-count': !+item.finishCount,
                }"
                >{{ item.finishCount }}</span
              >
              <template v-if="calendarType === 'withDatabase'">
                <span
                  class="separate"
                  :class="{
                    'null-count': !+item.totalCount && !+item.finishCount,
                  }"
                  >/</span
                >
                <span
                  class="total-count"
                  :class="{
                    'null-count': !+item.totalCount,
                  }"
                  >{{ item.totalCount }}</span
                >
              </template>
              <template v-if="calendarType === 'showDot'">
                <div
                  class="dot"
                  v-if="+item.finishCount > 0 ? haveData : noData"
                  :style="{
                    background:
                      +item.finishCount > 0 ? haveDataColor : noDataColor,
                  }"
                ></div>
              </template>
            </p>
          </div>
        </li>
      </ul>
      <el-empty v-else description="暂无数据"></el-empty>
    </article>
  </div>
</template>

<script>
import mixin from './mixin';
import CalendarHeader from './CalendarHeader';
import { formatTime } from '@/utils/tools';

export default {
  name: 'YearWeek',
  mixins: [mixin],
  components: {
    CalendarHeader,
  },
  data() {
    return {
      year: '',
      weekList: [],
      currentWeek: null,
      activeWeek: null,
    };
  },
  created() {
    this.initDate(true);
  },
  methods: {
    /**
     * @desc: 获取数据
     * @param {Boolean} isInit
     */
    initDate(isInit = false) {
      this.weekList = [];
      const now = new Date();
      if (isInit) {
        this.year = now.getFullYear();
        const day = now.getDate();
        console.log(day);
        this.currentWeek = {
          year: this.year,
          month: now.getMonth() + 1,
          date: now.getDate(),
          desc: `第${this.getYearWeek(this.year, now.getMonth() + 1, day)}周`,
          result: this.getWeekDuring(
            this.year,
            now.getMonth() + 1,
            now.getDate()
          ),
          dateString:
            `${this.year}年` +
            `第${this.getYearWeek(this.year, now.getMonth() + 1, day)}周`,
        };
        this.onWeek(this.currentWeek);
      }
      if (this.year > now.getFullYear()) {
        return;
      }
      const firstDay = new Date(`${this.year}-01-01`).getDay() || 7;
      let startObj = new Date(`${this.year}-01-01 00:00:00`);
      if (firstDay !== 1) {
        // 非周一
        startObj = new Date(`${this.year}-01-${7 - firstDay + 2} 00:00:00`);
      }
      let startYear = now.getFullYear();
      // 年度结束时间
      const endTime = new Date(`${this.year + 1}-01-01 00:00:00`).getTime();
      let currentDate = null;
      let weekEndTime = null;
      for (let i = 0; i < 80; i++) {
        currentDate = new Date(startObj.getTime() + i * 7 * 86400000);
        weekEndTime = currentDate.getTime() + 7 * 86400000;
        if (weekEndTime > endTime) {
          // 超过本年
          startYear = startYear + 1;
          break;
        }
        this.weekList.push({
          isTargetWeek: this.isTargetWeek(
            this.year,
            currentDate.getMonth() + 1,
            currentDate.getDate()
          ),
          year: this.year,
          month: currentDate.getMonth() + 1,
          desc: `第${i + 1}周`,
          result: this.getWeekDuring(
            this.year,
            currentDate.getMonth() + 1,
            currentDate.getDate()
          ),
          dateString: `${this.year}年` + `第${i + 1}周`,
        });
        if (weekEndTime > now.getTime()) {
          break;
        }
      }
    },
    /**
     * @desc 获取当前日期是本年的第几周
     *
     */
    getYearWeek(year, month, day) {
      console.log(year, month, day);
      var date1 = new Date(year, parseInt(month) - 1, day), //当前日期
        date2 = new Date(year, 0, 1), //当年第一天
        // d是当前日期是今年第多少天
        d = Math.round((date1.valueOf() - date2.valueOf()) / 86400000);
      // d + 当前年的第一天的周差距的和在除以7就是本年第几周
      return Math.ceil((d + (date2.getDay() + 1 - 1)) / 7);
    },
    /**
     * @desc: 快捷切换
     * @param {Object} dateObj
     */
    getQuickDate(dateObj) {
      this.year = dateObj.year;
      this.initDate();
    },
    /**
     * @desc: 获取一周的开始结束时间段
     */
    getWeekDuring(year, month, date) {
      const dateObj = new Date(`${year}-${month}-${date} 00:00:00`);
      const day = dateObj.getDay() || 7;
      const startTime = dateObj.getTime() - (day - 1) * 86400000;
      const endTime = dateObj.getTime() + (7 - day + 1) * 86400000 - 1000;
      return [
        formatTime(startTime) + ' 00:00:00',
        formatTime(endTime) + ' 23:59:59',
      ];
    },
    /**
     * @desc: 是否为当前周
     */
    isTargetWeek(cYear, cMonth, cDay) {
      const { year, month, date } = this.currentWeek;
      return year === cYear && month === cMonth && cDay + 7 > date;
    },
    /**
     * @desc: 获取周
     * @param {Object} item
     */
    onWeek(item) {
      this.activeWeek = item;
      const { dateString, year, month } = item;
      this.$emit('setDate', {
        date: item.result,
        week: item.desc,
        dateString,
        year,
        month,
      });
    },
    /**
     * @desc: 是否为当前选中的周
     * @param {Object} item
     */
    isActiveWeek(item) {
      const itemResult = item.result;
      const activeResult = this.activeWeek.result;
      return JSON.stringify(itemResult) === JSON.stringify(activeResult);
    },
  },
};
</script>

<style lang="less" scoped>
@import './common.less';
.common-date {
  &.year-week {
    .date-list {
      justify-content: flex-start;
    }
  }
}
.year-week {
  .date-item {
    width: 20% !important;
  }
}
</style>
