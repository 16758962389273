<!--
 * @Description: 文本组件 V0.0.1
 * @Author: luocheng
 * @Date: 2021-08-09 11:59:52
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-08-27 18:03:28
-->
<template>
  <p
    class="common-text"
    :ref="element.id"
    :class="[ellipsisClass,autoScrollClass]"
    :style="style"
  >
    <!-- 固定 -->
    <template v-if="element.valueOrign === 'fixed'">{{
      toFixedNumber(textVal || nullText)
    }}</template>
    <!-- 来源于其他组件 -->
    <template v-if="element.valueOrign === 'component'">
      {{ toFixedNumber(_getValueFromComponent(element.componentValueConfig)) }}
    </template>
    <!-- 数据仓库 -->
    <template v-if="element.valueOrign === 'database'">
      <template v-if="dataObj && bindUUID">
        <!-- 绑定值 -->
        <template v-if="databaseType === 'value'">
          {{ getBindValue() }}
        </template>
        <!-- 数据字典(描述文字) -->
        <template v-else-if="databaseType === 'wordbook' && metaData">
          {{ toFixedNumber(metaData.name) }}
        </template>
      </template>
    </template>
    <!-- 映射值 -->
    <template v-if="element.valueOrign === 'valueMap'">
      {{ toFixedNumber(_getValueFromMap()) }}
    </template>
  </p>
</template>

<script>
import eventBus from "@/plugins/eventBus";
import { getComponentById, initParams } from "@/utils/tools";
import { mapState } from "vuex";
import { judgingEventLogic } from "@/utils/tools";
// import baseComponent from '@/custom-component/component-config/newBases.js';

export default {
  name: "CommonText",
  props: {
    // 绑定的值
    element: {
      type: Object,
      default: () => {},
      required: true,
    },
    fullData: {
      // type: Object
    },
    isGroup: {
      type: Boolean,
    },
    groupComponents: {
      type: Array,
      default: () => [],
    },
    componentList: {
      default: null,
    },
    // 循环映射值
    mapData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      that: this,
      text: "",
      autoScrollClass:''
    };
  },
  mounted() {
    //监听行为
    const doComponentBehavior = {
      [this.element.id]: (config) => {
        const { component, list = [] } = config;
        if (this.element.id !== component) return false;
        list.forEach((ele) => {
          const { behaviors, params, logics = [] } = ele;
          const { param = {}, canPost } = initParams(
            params,
            false,
            this.subComponentData,
            []
          );
          if (canPost && judgingEventLogic(logics, this.subComponentData)) {
            // 调用行为方法
            behaviors.forEach((funName) => {
              // window.$EditorDebug.startBehavior(
              // 	this.element,
              // 	baseComponent.componentList.find(ele => ele.component === 'CommonText'),
              // 	funName,
              // 	param
              // );
              try {
                eval(this[funName])(param);
              } catch (err) {
                console.log(err);
              }
            });
          }
        });
      },
    };
    eventBus.$on("doComponentBehavior", doComponentBehavior[this.element.id]);
    this.setAnimation()
  },
  unmounted() {
    eventBus.$off("doComponentBehavior", this.element.id);
  },
  inject: ["EDITOR_pageUUID"],
  computed: {
    ...mapState([
      "componentData",
      "signComponentData",
      "subsidiaryComponentData",
    ]),
    // 取值组件列表
    subComponentData() {
      if (this.EDITOR_pageUUID) {
        let list = this.componentList;
        return (
          this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData ||
          this.componentData ||
          []
        );
      }
      return this.componentData || [];
    },
    // 数据仓库
    database() {
      return this.element.database;
    },
    // 字段对象
    dataObj() {
      if (!this.database?.containerKey) return {};
      let container = getComponentById(
        this.subComponentData,
        this.database.containerKey
      );
      if (!container && this.isGroup && this.groupComponents.length) {
        container = getComponentById(
          this.groupComponents,
          this.database.containerKey
        );
      }
      if ((!container || !container.containerData) && !this.fullData) return {};
      // 列表情况默认返回一个
      if (
        Array.isArray(container.containerData) &&
        container.containerData.length
      ) {
        return container.containerData?.[0] || {};
      }
      // 列表有分页和不分页情况
      if (this.fullData) {
        if (Array.isArray(this.fullData)) {
          // 分页
          return this.fullData[0] || {};
        } else if (Array.isArray(this.fullData?.data)) {
          // 不分页
          return this.fullData?.data?.[0] || {};
        }
        return this.fullData;
      }
      const result = container?.containerData || this.fullData;
      return result;
    },
    // 绑定的uuid
    bindUUID() {
      return this.element?.database?.bindUUID ?? "";
    },
    // 用于获取绑定的数据字典(描述文字)
    metaData() {
      if (!this.element?.database) return null;
      const { fieldList, bindUUID } = this.element.database;
      return fieldList.find((ele) => ele.uuid === bindUUID);
    },
    // 数据绑定类型
    databaseType() {
      if (!this.element?.database) return "value";
      return this.element.database.databaseType ?? "value";
    },
    // 状态配置
    statusConfig() {
      return this.element?.statusConfig;
    },
    // 省略号类
    ellipsisClass() {
      const { ellipsis, clamp } = this.statusConfig;
      if (!ellipsis) return "";
      if (+clamp === 1) {
        return "ellipsis-1";
      }
      return `ellipsis ellipsis-${clamp}`;
    },
    // 空值
    nullText() {
      return this.statusConfig?.nullText || "";
    },
    textVal() {
      return this.text || this.element.propValue;
    },
    style() {
      return {
        fontWeight: this.statusConfig.bold ? "bold" : "",
        fontStyle: this.statusConfig.italic ? "oblique" : "",
      };
    },
  },
  filters: {
    // 来源于组件的值
    getValueFromComponent(config, _this) {
      if (
        !config ||
        !config.component ||
        !config.field ||
        (!_this.componentData && !_this.componentList)
      )
        return "";
      let component = getComponentById(
        _this.componentList || _this.componentData,
        config.component
      );
      if (!component && !_this.isGroup && _this.signComponentData.length) {
        //标记的取值
        component = getComponentById(_this.signComponentData, config.component);
      }
      if (!component && _this.isGroup && _this.groupComponents.length) {
        component = getComponentById(_this.groupComponents, config.component);
      }
      if (!component) return "";
      if (config.field === "propValue") {
        return component.propValue;
      }
      if (!component.customStatus) return "";
      return component.customStatus[config.field];
    },
  },
  updated() {
    this.toResolveData();
    this.setAnimation()
  },
  methods: {
    setAnimation(){
      const { autoScroll,autoScrollAnimationDuration } = this.statusConfig;
			let height=0
			let parentHeight=0
			height=this.$refs[this.element.id]?.clientHeight
			parentHeight=this.$refs[this.element.id]?.parentNode?.parentNode?.clientHeight
			if (autoScroll&&height>parentHeight) {
				this.autoScrollClass='auto-scroll'
				this.$refs[this.element.id].style.animationDuration =(autoScrollAnimationDuration||10)+'s' 
			}
    },
    // 行为开始
    /**
     * @description: 修改文本
     * @param {Object} param 参数
     */
    changeText(param) {
      this.text = param.text;
    },
    /**
     * @description: 改变背景颜色
     * @param {Object} param
     */
    changeBackground(param) {
      if (!param) return;
      const domRef = this.$refs?.[this.element.id];
      if (!domRef) return;
      const { color } = param;
      if (color) {
        domRef.style.backgroundColor = color;
      }
    },
    /**
     * @description: 改变字体
     * @param {Object} param
     */
    changeFont(param) {
      if (!param) return;
      const domRef = this.$refs?.[this.element.id];
      if (!domRef) return;
      const { color, fontSize, fontWeight } = param;
      if (color) {
        domRef.style.color = color;
      }
      if (fontSize) {
        domRef.style.fontSize = isNaN(+fontSize) ? fontSize : `${fontSize}px`;
      }
      if (fontWeight) {
        domRef.style.fontWeight = fontWeight;
      }
    },
    // 行为结束
    getBindValue() {
      if (["resolveLength", "fullLength"].includes(this.bindUUID)) {
        let container = getComponentById(
          this.subComponentData,
          this.database.containerKey
        );
        if (!container && this.isGroup && this.groupComponents.length) {
          container = getComponentById(
            this.groupComponents,
            this.database.containerKey
          );
        }
        if (!container) return 0;
        const { containerData, resolveData } = container;
        if (this.bindUUID === "resolveLength") {
          return Array.isArray(resolveData) ? resolveData.length : 0;
        }
        if (this.bindUUID === "fullLength") {
          return Array.isArray(containerData) ? containerData.length : 0;
        }
      }
      return this.toFixedNumber(this.dataObj[this.bindUUID]) || this.nullText;
    },
    /**
     * @desc: 截取小数点
     */
    toFixedNumber(val) {
      const { useToFixed = false, toFixedCount = 2 } = this.statusConfig;
      if (!useToFixed) {
        return val;
      }
      if (
        val === undefined ||
        val === null ||
        Object.prototype.toString.call(+val) !== "[object Number]"
      ) {
        return val;
      }
      return (+val).toFixed(+toFixedCount);
    },
    /**
     * @desc: 暴露数据
     */
    toResolveData() {
      const { valueOrign = "fixed", componentValueConfig = {} } = this.element;
      if (valueOrign === "fixed") {
        // 固定值
        this.element.resolveData = {
          value: this.element.propValue || "",
        };
      } else if (valueOrign === "component") {
        // 其他组件
        this.element.resolveData = {
          value: this._getValueFromComponent(componentValueConfig),
        };
      } else if (valueOrign === "database") {
        // 数据仓库
        this.element.resolveData = {
          value: this._getDatabaseData(),
        };
      }
      this.$store.commit("updatePageCustomStatus", {
        origin: this.element,
        resolveData: this.element.resolveData,
      });
    },
    /**
     * @desc: 获取数仓数据
     */
    _getDatabaseData() {
      if (!this.dataObj || !this.bindUUID) return "";
      if (this.databaseType === "value") {
        // 绑定值
        return this.dataObj[this.bindUUID] || this.nullText;
      }
      if (this.databaseType === "wordbook" && this.metaData) {
        // 数据字典(描述文字)
        return this.metaData.name || "";
      }
      return "";
    },
    /**
     * @desc: 获取组件来源绑定值
     * @param {Object} config
     */
    _getValueFromComponent(config) {
      if (
        !config ||
        !config.component ||
        !config.field ||
        (!this.subComponentData && !this.componentList)
      )
        return "";
      let component = getComponentById(
        this.componentList || this.subComponentData,
        config.component
      );
      if (!component && !this.isGroup && this.signComponentData.length) {
        //标记的取值
        component = getComponentById(this.signComponentData, config.component);
      }
      if (!component && this.isGroup && this.groupComponents.length) {
        component = getComponentById(this.groupComponents, config.component);
      }
      if (!component) return "";
      if (config.field === "propValue") {
        return component.propValue;
      } else if (component.resolveData && component.resolveData[config.field]) {
        return component.resolveData[config.field];
      }
      if (!component.customStatus) return "";
      return component.customStatus[config.field];
    },
    /**
     * @desc: 获取映射值
     */
    _getValueFromMap() {
      const { bindField } = this.element?.loopMapConfig || {};
      if (!bindField) return this.nullText;
      const { renderData } = this.mapData || {};
      if (!renderData) return this.nullText;
      return renderData?.[bindField] || this.nullText;
    },
  },
};
</script>

<style lang="less" scoped>
.common-text {
  width: 100%;
  &.ellipsis-1 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &.ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    &.ellipsis-2 {
      -webkit-line-clamp: 2;
    }
    &.ellipsis-3 {
      -webkit-line-clamp: 3;
    }
    &.ellipsis-4 {
      -webkit-line-clamp: 4;
    }
    &.ellipsis-5 {
      -webkit-line-clamp: 5;
    }
    &.ellipsis-6 {
      -webkit-line-clamp: 6;
    }
    &.ellipsis-7 {
      -webkit-line-clamp: 7;
    }
    &.ellipsis-8 {
      -webkit-line-clamp: 8;
    }
    &.ellipsis-9 {
      -webkit-line-clamp: 9;
    }
    &.ellipsis-10 {
      -webkit-line-clamp: 10;
    }
  }
}
.auto-scroll{
	animation: 10s loop linear infinite normal;
}
@keyframes loop {
	0% {
	    transform: translateY(0);
	}
	100% {
	    transform: translateY(-100%);
        }
    }
</style>
