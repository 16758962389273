<!--
 * @Author: qinmengyuan
 * @Date: 2024-10-07 09:30:49
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-10-16 11:40:14
 * @FilePath: \dataview-viewer-test\src\custom-component\form\newParser\FormItem\widget-time.vue
-->
<!--
    @name: widget-time
    @description：widget-time
    @author: ZengWei
    @date: 2022-03-25 09:36
-->
<template>
  <div
    :class="{
      'time-container': true,
      custom:
        element.config.__slot__?.prepend || element.config.__slot__?.append,
    }"
  >
    <div
      class="prepend"
      v-if="element.config.__slot__?.prepend"
      :style="{
        border:
          disabled || element.config.disabled ? 'none' : '1px solid #dcdfe6',
      }"
    >
      {{ element.config.__slot__?.prepend }}
    </div>
    <div v-if="disabled || element.config.disabled" class="input-show">
      {{ element.value ? element.value : '-' }}
    </div>
    <el-time-picker
      v-else
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      :type="element.config.type"
      :format="element.config.format"
      :value-format="element.config['value-format']"
      :placeholder="element.config.placeholder"
      style="width: 100%"
    >
    </el-time-picker>
    <div
      class="append"
      v-if="element.config.__slot__?.append"
      :style="{
        border:
          disabled || element.config.disabled ? 'none' : '1px solid #dcdfe6',
      }"
    >
      {{ element.config.__slot__?.append }}
    </div>
  </div>
</template>

<script>
import { TimePicker } from 'element-ui';
import formItemMinix from '@/custom-component/form/newParser/scripts/formItemMinix';

export default {
  name: 'WidgetTime',
  components: {
    'el-time-picker': TimePicker,
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.time-details {
  width: 100%;
  height: 32px;
  padding: 0 12px;
  box-sizing: border-box;
  background: #f2f4f7;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2a2f3d;
  cursor: default;
}
.time-container {
  display: flex;
  align-items: center;
  .input-show {
    flex: 1;
  }
  .el-date-editor {
    width: 100%;
  }
  .prepend {
    background: #f5f7fa;
    padding: 0 20px;
    border: 1px solid #dcdfe6;
    border-radius: 4px 0 0 4px;
    box-sizing: border-box;
    height: 36px;
  }
  .append {
    background: #f5f7fa;
    padding: 0 20px;
    border: 1px solid #dcdfe6;
    border-radius: 0 4px 4px 0;
    box-sizing: border-box;
    height: 36px;
  }
}
.custom {
  .el-date-editor {
    flex: 1;
  }
  :deep(.el-input__inner) {
    border-radius: 0 !important;
  }
  .input-show {
    border-radius: 0;
  }
}
</style>
