<template>
  <div class="common-answer-container">
      <div class="header-container">
        <div class="header-left">
          <div class="back-box" @click="onBack">
            <!-- icona-zuojiantouyuanjiaoL iconfanhui-->
            <i class="iconfont iconc-chevron-left"></i>
            <span class="name">返回</span>
          </div>
          <div class="driver"></div>
          <div class="title-box">
            <div class="title">{{paperInfo?.[safetyKeyConfig?.name]}}</div>
            <div class="sub-title">本试卷满分 {{totalScore || 0}} 分，合格分数 {{getPassScore}} 分，考试时间 {{paperInfo?.[safetyKeyConfig?.timeLength] || 0}} 分钟。{{paperInfo?.[safetyKeyConfig?.precautions]}}</div>
          </div>
        </div>
        <div v-if="!isPaperLog" class="header-right">
          <el-button type="primary" @click="onSubmit">提交试卷</el-button>
        </div>
      </div>
      <div class="content-container">
        <div class="question-container">
          <div v-if="formValidate.items.length" class="question-box">
            <el-form
              ref="QuesParser" size="medium"
              :model="formValidate"
              :label-position="formData.labelPosition"
            >
              <el-col 
                ref="wrapperItem"
                v-for="(item,index) in formValidate.items"
                :key="item.config.__config__.formId"
                :span="24">
                <el-form-item
                  :prop="'items.' + index + '.value'"
                  label-width="0px"
                >
                  <div class="ques-wrapper-item" @click="setActiveId(index)">
                    <div class="ques-item-content">
                      <div class="order">
                        <span>{{ index + 1 }}</span>
                      </div>
                      <QuestionItem @change="checkFinish(index)" @changeVideo="(val)=>changeVideo(val,index,item)" :zIndex="0" class="ques-widget-item" :element="item" :number="index" :disabled="isPaperLog" />
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-form>
          </div>
          <div v-else class="empty-box">
            <el-empty description="请配置试卷数据" />
          </div>
        </div>
        <div class="result-container">
          <div class="result-box">
            <Result 
            ref="resultRef"
            :element="element"
            :isPaperLog="isPaperLog" 
            :queslist="formValidate.items" 
            :paperData="paperData"
            :finishCount="finishCount"
            :cacheTime="cacheTime"
            :exitType="exitType"
            :correctCount="correctCount"
            :errorCount="errorCount"
            :totalScore="totalScore"
            @finishExam="finishExam"
            ></Result>
          </div>
          <div class="question-card">
            <QuestionCard 
            :isPaperLog="isPaperLog"
            :queslist="allQuestion" 
            :activeId="activeId"
            @turnToQues="turnToQues"
            ></QuestionCard>
          </div>
        </div>
      </div>
     <el-dialog
      :title="paperData?.[safetyKeyConfig?.name]"
      :visible.sync="dialogTableVisible"
      append-to-body
      custom-class="constom-paper-submit-success-dialog"
      width="40%"
      @close="onCloseDialog"
    >
      <div v-if="dialogTableVisible" class="submit-success-box">
        <img class="img" src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/936af37cc8d62ca447043196458a8170.png" alt="" srcset="">
        <p class="title">提交成功</p>
        <p class="tip">{{countdown}}s 后自动关闭弹窗，也可点击空白处关闭。</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Empty } from 'element-ui';
import { getComponentById,getLinkByTemplate,openUrl } from '@/utils/tools';
import Result from '@/components/answer/components/result.vue';
import QuestionCard from '@/components/answer/components/questionCard.vue';
import QuestionItem from "@/components/answer/quesParser/components/QuestionItem.vue";
import QuesParserMethods from "@/components/answer/quesParser/scripts/quesParserMethods.js";
import eventBus from "@/plugins/eventBus";
import { dataInterface } from "@/apis/data";
import { mapState } from 'vuex';
import containerMixin from '@/custom-component/container/mixins';
import databaseTriggerDebug from '@/custom-component/mixins/databaseTriggerDebug.js';
export default {
	name: 'AnswerPage',
  components: {
    'el-empty': Empty,
    Result,
    QuestionCard,
    QuestionItem,
    // Skeleton
  },
  inject: ['EDITOR_pageUUID'],
  mixins: [containerMixin, databaseTriggerDebug],
	props: {
    element: {
      type: Object,
      default: () => {}
    },
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    paperInfo: {
      type: Object,
      default: () => {}
    },
    cacheTimeStr:{
      type: [String,Number],
      default: 0
    },
    cacheList:{
      type: Array,
      default: () => []
    }
	},
  data() {
    return {
      skeleton: true,
      outerFormElement:null,
      outerAfterElement:null,
      position:2,
      afterPosition:3,
      outerFormData:null,
      outerNeedFlow: false,
      outerFormUuid: '',
      outerObjectUuid: '',
      outerFormMode: 'add',
      outerFormDisplay: {},
      outerFormType: 1,
      outerFormShowType: 1,
      outerFormVisible:false,
      outerFormTitle: '外部表单',
      userScore: 0,
      indicator:[],

      formValidate:{
        items:[],
      },
      allQuestion:[],
      activeId: 0,
      finishCount: 0,
      dialogTableVisible :false,
      countdown: 5,
      timer: null,
      showCancelButton:false,
      paperStatusTip: '',
      confirmButtonText:'',
      cancelButtonText:'取消',
      rowFinshed: true,
      cacheTime: 0,
      totalScore: 0,
      correctCount:0,
      errorCount:0,
      activeIndex: 0
    }
  },
	computed: {
    ...mapState(['componentData', 'curComponent']),
    statusConfig() {
			return this.element?.statusConfig || {};
		},
    safetyKeyConfig() {
      return this.element?.safetyKeyConfig || {};
		},
		formData() {
      return this.paperInfo?.[this.safetyKeyConfig?.config] || {};
		},
    paperData() {
      return this.paperInfo || {};
		},
    paperLog() {
      return this.paperInfo?.[this.safetyKeyConfig?.answer] || {};
		},
    exitType() {
      return this.paperInfo?.[this.safetyKeyConfig?.exitOperate] || 1;
		},
    isPaperLog() {
			return this.element?.statusConfig?.isPaperLog || false;
		},
    getPassScore(){
      const passCondition = this.paperData?.[this.safetyKeyConfig?.passCondition] ? this.paperData?.[this.safetyKeyConfig?.passCondition] : +this.paperData?.pass_condition;
      if(+passCondition === 1){
        return +this.paperData?.[this.safetyKeyConfig?.passScore]; 
      }else if(+passCondition === 2){
        return this.totalScore;
      }
      return '/';
    }
	},
  created(){
    // 判断是答题还是答题记录
    if(this.isPaperLog){
      this.initQuesFormatData(this.formData?.fields);
      this.allQuestion = this.formValidate.items;
    }else{
      this.cacheTime = 0;
      if(this.exitType === 1 && this.cacheTimeStr){
        this.cacheTime = this.cacheTimeStr;
      }
      this.initQuesFormatData(this.formData?.fields);
      this.checkFinish(0);
    }
    
    if(this.paperLog && Object.keys(this.paperLog).length){
      const score = Object.values(this.paperLog.score)
      this.userScore = eval(score.join("+"))
    }
    if(this.outerFormElement && this.position === 1) {
      this.openOuterForm(this.outerFormElement)
    }
  },
  methods:{
    setActiveId(index){
      this.activeId = index;
    },
    /**
     * @desc: 暴露数据
     */
    onResolve(data) {
      this.element.resolveData = {
        ...data
      }; 
      this.onTrigger(data);
      this.$store.commit('updatePageCustomStatus', {
        origin: this.element,
        resolveData: {
          ...data
        }
      });
    },
    onTrigger(dateValue) {
      eventBus.$emit('databaseTrigger', {
        componentId: this.element.id,
        action: 'any',
        output: dateValue,
        isInit: false,
        noUpdate: false
      });
    },
    async handleSetStorage(){
      const currentTime = this.$refs.resultRef?.seconds;
      const submitData = this.getSubmitData();
      const userInfo = this.$GetUserInfo();
      const searchKey = `${userInfo.phone}-${this.paperData?.id}`;
      const data = {
        paperLog: submitData,
        currentTime: currentTime,
        activeIndex: this.activeIndex
      };
      if(this.cacheList.length){
        this.updateStorage(data,searchKey,'updateData')
      }else{
        this.updateStorage(data,searchKey,'createData')
      }
    },
    deleteStorage(){
      let params = {
        __method_name__: 'deleteData',
        object_uuid: 'object66c3053d1ac38',
        data_id: this.cacheList?.[0]?.id
      }
      dataInterface(params).then(res=>{
        console.log(res);
      });
    },
    async updateStorage(data,searchKey,type){
      let params = {
        __method_name__: type,
        object_uuid:'object66c3053d1ac38',
        view_uuid: 'view66c3058bd6365',
        transcode: 0,
        key: searchKey,
        value: data
      }
      if(type === 'updateData'){
        params.data_id  = this.cacheList[0]?.id
      }
      dataInterface(params).then(res=>{
        console.log(res);
      });
    },
    changeVideo(val,index,item){
      this.activeIndex = index;
      // item.value = val;
      if(!item?.answered && +item.value >= 1){
        item.answered = true
        this.finishCount = this.finishCount + 1;
        this.allQuestion = JSON.parse(JSON.stringify(this.formValidate.items));
      }
    },
    checkFinish(index){
      this.finishCount = 0;
      this.activeIndex = index;
      if(this.formValidate.items.length){
        this.formValidate.items.forEach(item=>{
          item.answered = false;
          if(['checkbox','upload','row','signature'].includes(item.type)){
            if(['checkbox','upload','signature'].includes(item.type) && item.value.length){
              item.answered = true;
              this.finishCount += 1;
            }else if(item.type === 'row' && Array.isArray(item.child) && item.child.length){
              this.rowFinshed = true;
              this.checkRowFinish(item.child);
              if(this.rowFinshed){
                item.answered = true;
                this.finishCount += 1;
              }
            }
          }else if(item.type === 'radio'){
						if(+item.value){
							item.answered = true;
              this.finishCount += 1;
						}
					}else if(item.value){
            item.answered = true;
            this.finishCount += 1;
          }
        });
        this.allQuestion = JSON.parse(JSON.stringify(this.formValidate.items));
      }else{
        this.allQuestion = [];
      }
    },
    checkRowFinish(list){
      list.forEach(el=>{
        if(['checkbox','upload','video','row','signature'].includes(el.type)){
          if(['checkbox','upload','signature'].includes(el.type) && el.config.__config__.required  && el.value.length === 0){
            this.rowFinshed = false;
          }
          if(el.type === 'video' && el.config.__config__.required  && el.value < el?.config?.__ques__?.minimumLimit){
            this.rowFinshed = false;
          }
          if(el.type === 'row' && el.config.__config__.required  && Array.isArray(el.child) && el.child.length){
            this.checkRowFinish(el.child);
          }
        }else if(!el.value){
          this.rowFinshed = false;
        }
      })
    },
    /**
     * @description: 返回主页面
     * @return {*}
     */
    onBack(){
      if(this.isPaperLog){
        this.onAction('back');
        return;
      }
      // 中途退出考试逻辑
      let paperStatusTip = '';
      if(+this.exitType === 1){
        // 暂停考试
        paperStatusTip = `考试过程中退出页面后，将【暂停考试】<br>
        答题内容暂存；考试用时暂停计算，重新进入答题页<br>
        面后，继续计算。<br>
        <span style="color: #c92e2e;">您确定要退出答题吗？<span>`;
      } else if(+this.exitType === 2){
        // 结束考试
        paperStatusTip = `考试过程中退出页面后，将【结束考试】<br>
        以当前做题的情况，提交试卷，计算当前得分<br>
        <span style="color: #c92e2e;">您确定要退出答题吗？<span>`;
        
      }else if(+this.exitType === 3){
        // 作废考试
        paperStatusTip = `考试过程中退出页面后，将【作废考试】<br>
        本次考试作废，得分为0分<br>
        <span style="color: #c92e2e;">您确定要退出答题吗？<span>`;
      }
      this.$confirm(paperStatusTip, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false,
        dangerouslyUseHTMLString: true,
        center: true
      }).then(() => {
        if(+this.exitType === 1){
          // 暂停考试
          this.handleSetStorage();
          this.onAction('back');
        } else if(+this.exitType === 2){
          // 结束考试
          this.submitData(1,2);
        }else{
          // 作废考试
          this.submitData(0,2);
        }
      }).catch(() => {       
      });
    },
   
    /**
		 * @desc: 操作
		 * @param {String} action 操作
		 */
		onAction(action) {
      const comEvents = this.element.actionConfig[action]?.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					this.onActionItem(comEvents[i]);
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList;
				result.forEach((ele) => {
					this.onActionItem(ele);
				});
			}
		},
    /**
		 * @desc: 单个操作
		 * @param {Object} ele
		 */
		onActionItem(ele) {
			if (ele.key === 'click') {
				// 跳转页面
				if (ele.actionType === 'jumpPage') {
					if (ele.linkType === 'projectPage') {
						const query = {};
            
						ele.queryList && ele.queryList.forEach((queryItem) => {
							let component = getComponentById(this.subComponentData, queryItem.componentId);
							if (!component && this.isGroup && this.groupComponents.length) {
								component = getComponentById(this.groupComponents, queryItem.componentId);
							}
							this.$set(
								query,
								queryItem.key,
								component?.resolveData?.[queryItem.feild]
							);
						});
						this.$router.push({
							path: ele.pageId,
							query
						});
						return;
					} else if (ele.linkType === 'outPage') {
						openUrl(ele.linkUrl,ele.linkUrl)
					} else if (ele.linkType === 'custom') {
						const customLink = getLinkByTemplate(ele.linkTemplate)
						openUrl(customLink,customLink)
					}
				}
			}
		},
    onSubmit(){
      this.paperStatusTip = '您确定要交卷吗？';
      this.showCancelButton = true;
      this.confirmButtonText = '确定';
      this.cancelButtonText = '取消';
      this.$refs.resultRef?.stopTimer();
      this.submitPaper(1);
    },
    finishExam(){
      this.paperStatusTip = '考试时长已用完，已自动交卷!';
      this.confirmButtonText = '我知道了！ ';
      this.showCancelButton = false;
      this.cacheTime = 0;
      this.submitData(1,3);
    },
    submitPaper(type){
      this.$confirm(this.paperStatusTip, '提示', {
        confirmButtonText: this.confirmButtonText,
        cancelButtonText: this.cancelButtonText,
        showClose: false,
        showCancelButton: this.showCancelButton,
        center: true
      }).then(() => {
        if(type === 1){
          this.submitData(1,1);
          this.cacheTime = 0;
        }else{
          this.submitSuccess();
        }
      }).catch(() => {   
        this.cacheTime = this.$refs.resultRef?.seconds;
        this.$refs.resultRef?.startTimer();
      });
    },
    submitSuccess(){
      this.countdown = 5;
      this.dialogTableVisible = true;
      this.timer = setInterval(() => {  
        if (this.countdown > 0) {  
          this.countdown--;  
        } else {  
          this.dialogTableVisible = false;
          this.stopCountdown();  
        }  
      }, 1000); // 每秒更新一次
    },
    onCloseDialog(){
      this.dialogTableVisible = false;
      this.stopCountdown();
    },
    stopCountdown() {  
      clearInterval(this.timer);
      this.timer = null;  
      this.toPaperLog();
    },
    toPaperLog(){
      const ele = this?.element?.submitSuccessObj;
      if (ele.linkType === 'projectPage') {
        const query = {};
        ele.queryList && ele.queryList.forEach((queryItem) => {
          let component = getComponentById(this.subComponentData, queryItem?.componentId);
          if (!component && this.isGroup && this.groupComponents.length) {
            component = getComponentById(this.groupComponents, queryItem?.componentId);
          }
          this.$set(
            query,
            queryItem.key,
            component?.resolveData?.[queryItem.feild]
          );
        });
        
        this.$router.push({
          path: ele.routePath,
          query
        });
        return;
      } else if (ele.linkType === 'outPage') {
        openUrl(ele.linkUrl,ele.linkUrl)
      } else if (ele.linkType === 'custom') {
        const customLink = getLinkByTemplate(ele.linkTemplate)
        openUrl(customLink,customLink)
      }
    },
    ...QuesParserMethods
  },
  beforeDestroy() { 
    if (this.timer) {  
      clearInterval(this.timer);  
      this.timer = null;  
    }
  }
};
</script>

<style lang="less" scoped>
.submit-success-box{
  height: calc(100vh - 500px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .img{
    width: 67px;
    height: 67px;
    display: block;
  }
  .title{
    color: #181B22;
    font-family: "MiSans VF";
    font-size: 24px;
    font-style: normal;
    font-weight: 330;
    line-height: 32px; 
    padding: 20px 0 12px 0;
    box-sizing: border-box;
  }
  .tip{
    color: #707786;
    text-align: center;
    font-family: "MiSans VF";
    font-size: 14px;
    font-style: normal;
    font-weight: 330;
    line-height: 22px;
  }
}
:deep(.constom-paper-submit-success-dialog){
  border-radius: 16px;
}
.common-answer-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #F4F6F9;
  .header-container{
    flex-shrink: 0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    background: #fff;
    border-radius: 12px;
    padding: 0 20px;
    box-sizing: border-box;
    overflow: hidden;
    .header-left{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 24px;
      overflow: hidden;
      .back-box{
        height: 83px;
        width: auto;
        display: flex;
        align-items: center;
        gap: 6px;
        flex-shrink: 0;
        cursor: pointer;
        i{
          display: block;
          size: 16px;
        } 
        .name{
          color: #424751;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.5px;
        }
      }
      .driver{
        width: 1px;
        height: 24px;
        background: #F0F1F4;
        flex-shrink: 0;
      }
      .title-box{
        flex: 1;
        height: 100%;
        text-align: left;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2px;
        title{
          width: 100%;
          color: #181B22;
          font-family: "MiSans VF";
          font-size: 16px;
          font-style: normal;
          font-weight: 380;
          line-height: 24px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .sub-title{
          width: 100%;
          color: #707786;
          font-family: "MiSans VF";
          font-size: 13px;
          font-style: normal;
          font-weight: 330;
          line-height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .header-right{
      flex-shrink: 0;
    }
  }
  .content-container{
    flex: 1;
    width: 100%;
    display: flex;
    gap: 16px;
    overflow: hidden;
    .question-container{
      flex: 1;
      height: 100%;
      background: #fff;
      padding: 40px 128px;
      box-sizing: border-box;
      border-radius: 12px;
      overflow: hidden;
      .question-box{
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        .ques-wrapper-item{
          width: 100%;
          .ques-item-content{
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 24px;
            .order{
              width: 20px;
              height: 20px;
              border-radius: 2px;
              background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), var(--semantic-neutral-border, #A4ACBD);
              line-height: 20px;
              text-align: center;
              flex-shrink: 0;
              span{
                color: #FFF;
                font-family: "MiSans VF";
                font-size: 13px;
                font-style: normal;
                font-weight: 450;
                line-height: 20px;
                display: block;
              }
            }
            .ques-widget-item{
              flex: 1;
            }
          }
        }
      }
      .empty-box{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .result-container{
      width: 20%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-bottom: 16px;
      box-sizing: border-box;
      .result-box{
        width: 100%;
        height: 26%;
        background: #fff;
        border-radius: 12px;
        flex-shrink: 0;
      }
      .question-card{
        flex: 1;
        width: 100%;
        background: #fff;
        border-radius: 12px;
        overflow: hidden;
      }
    }
  }
}
</style>
