/*
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2022-12-12 17:40:03
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-10-30 19:18:03
 * @FilePath: \dataview-viewer-test\src\custom-component\container\mixins.js
 * @Description: 容器相关
 */
import { removeEventBus, getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';

export default {
  props: {
    // 组件对象
    element: {
      type: Object,
      default: () => {},
      required: true,
    },
    // 是否为分组
    isGroup: {
      type: Boolean,
    },
    // 当前分组的组件数据
    groupComponents: {
      type: Array,
      default: () => [],
    },
    // 表格默认选中数据
    defaultTableSelectData: {
      type: Array,
      default: () => [],
    },
    componentList: {
      default: null,
    },
  },
  data() {
    return {
      interfaceFinished: true, // s判断是否可以继续请求
    };
  },
  computed: {
    ...mapState(['globalInterval', 'subsidiaryComponentData']),
    // 请求定时器，若存在全局则以全局为准，若无全局则取当前组件自定义
    interfaceInterval() {
      if (this.globalInterval && this.globalInterval.useInterval) {
        return this.globalInterval;
      }
      return this.element?.interfaceInterval;
    },
    // 嵌入页面的参数获取
    subComponentData() {
      if (this.EDITOR_pageUUID) {
        return (
          this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData ||
          this.componentList ||
          this.componentData ||
          []
        );
      }
      return this.componentList || this.componentData || [];
    },
    statusConfig() {
      return this.element?.statusConfig;
    },
  },
  inject: ['EDITOR_pageUUID'],
  methods: {
    /**
     * @description: 设置定时请求
     */
    setInterval() {
      // 清除定时器
      if (this.intervalObj) {
        clearInterval(this.intervalObj);
      }
      if (!this.interfaceInterval) return false;
      const {
        useInterval = false,
        type = 'timeout',
        timeout = 3000,
        times = -1,
        unit = 'day',
        weekDay = '',
        month = '',
        day = '',
        hms = '06:00',
      } = this.interfaceInterval;
      if (!useInterval) return false;
      this.intervalObj = null;
      this.intervalTimes = 0;
      // 定时循环
      if (type === 'timeout') {
        if (timeout <= 0) return;
        this.intervalObj = setInterval(() => {
          if (times !== -1) {
            this.intervalTimes++;
          }
          this.doInterface();
          if (times !== -1 && this.intervalTimes >= times) {
            clearInterval(this.intervalObj);
          }
        }, timeout * 1000);
        return;
      }
      // 周期_时分秒
      if (['second', 'minutes', 'hour'].includes(unit)) {
        let count = 1;
        if (unit === 'minutes') {
          count = 60;
        } else if (unit === 'hour') {
          count = 60 * 60;
        }
        this.intervalObj = setInterval(() => {
          this.intervalTimes++;
          this.doInterface();
          if (times !== -1 && this.intervalTimes >= times) {
            clearInterval(this.intervalObj);
          }
        }, count * 1000);
      } else {
        // 每天 每分钟循环一次
        this.intervalObj = setInterval(() => {
          const date = new Date();
          const currentMonth = date.getMonth() + 1;
          const currentDay = date.getDate();
          const currentWeekDay = date.getDay();
          const hours =
            date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
          const minutes =
            date.getMinutes() < 10
              ? '0' + date.getMinutes()
              : date.getMinutes();
          this.intervalTimes++;
          if (unit === 'day' && hms === `${hours}:${minutes}`) {
            // 日
            this.doInterface();
          } else if (unit === 'week') {
            // 周
            if (currentWeekDay === weekDay && hms === `${hours}:${minutes}`) {
              this.doInterface();
            }
          } else if (unit === 'month') {
            // 月
            if (day === currentDay && hms === `${hours}:${minutes}`) {
              this.doInterface();
            }
          } else if (unit === 'year') {
            // 年
            if (
              month === currentMonth &&
              day === currentDay &&
              hms === `${hours}:${minutes}`
            ) {
              this.doInterface();
            }
          }
          if (times !== -1 && this.intervalTimes >= times) {
            clearInterval(this.intervalObj);
          }
        }, 5000);
      }
    },
    /**
     * @description: 刷新关联组件数据
     */
    updateRelationComponent() {
      const { paramsConfig = [] } = this.element?.database || {};
      if (!Array.isArray(paramsConfig) || !paramsConfig.length) return;
      for (let i = 0; i < paramsConfig.length; i++) {
        const item = paramsConfig[i];
        if (item && !item.sourceType && item.componentId) {
          eventBus.$emit('databaseTrigger', {
            action: 'dataList',
            componentId: item.componentId,
            isInit: item.componentId.indexOf('CommonTree-') > -1,
            isUpdate: true,
            output:
              item.componentId.indexOf('CommonTabs-') > -1
                ? this.getOutput(item.componentId)
                : '',
          });
        }
      }
    },
    /**
     * @desc: 获取数据数据
     */
    getOutput(comId) {
      let componentItem = getComponentById(this.subComponentData, comId);
      if (!componentItem && this.isGroup && this.groupComponents.length) {
        componentItem = getComponentById(this.groupComponents, comId);
      }
      return componentItem?.resolveData || '';
    },
  },
  beforeDestroy() {
    // console.log('销毁', this.element.name, this.EDITOR_pageUUID);
    this.interfaceFinished = true;
    // if (!this.EDITOR_pageUUID) {
      // EDITOR_loopResolve 为循环容器特有
      removeEventBus(
        [
          'exportData',
          'databaseTrigger',
          'EDITOR_loopResolve',
          'doComponentBehavior',
        ],
        this.element.id
      );
    // }
    if (this.intervalObj) {
      clearInterval(this.intervalObj);
    }
  },
};
