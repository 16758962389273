<template>
  <div class="live-com-container" :id="element.id" v-loading="loading">
    <div class="cover-wrap" v-if="!autoplay && !isManualPlay" @click.stop="doPlay">
      <el-image class="cover-img" :src="coverConfig">
        <div slot="error" class="image-slot">
          <el-empty description="暂无封面"></el-empty>
        </div>
        <div slot="placeholder" class="image-slot">
          加载中<span class="dot">...</span>
        </div>
      </el-image>
      <i class="iconfont iconkaishishijian" />
    </div>
    <template v-else>
      <template v-if="!statusConfig.useInCG">
        <div key="video-wrap" v-if="!loading && successed && liveOption.src" class="live-com-inner" v-generateLive="liveOption">
        </div>
        <div key="video-msg" v-if="!loading && !successed || !liveOption.src" class="none-msg">
          <el-empty description="暂无内容"></el-empty>
        </div>
      </template>
      <!-- 呈贡专用H265方案 -->
      <template v-else>
        <el-empty description="视频加载中" v-if="loading"></el-empty>
        <el-empty description="视频链接缺失" v-else-if="!cgUrl"></el-empty>
        <H265Player v-else
          :url="cgUrl"
          :autoPlay="autoplay"
          :styleObj="styleObj"
          @videoAction="doAction"
        ></H265Player>
      </template>
    </template>
  </div>
</template>
<script>
import { EZUIPlayer } from '@/libs/ezuikit.js'
import HLS from '@/libs/HLS.js'
import Minin from './mixin'
import { dataInterface } from '@/apis/data/index'
import eventBus from '@/plugins/eventBus';
import { getComponentById, initParams, getQueryValue, getComponentId } from '@/utils/tools';
import { Empty, Image } from 'element-ui';
import { mapState } from 'vuex';
import databaseTriggerDebug from '@/custom-component/mixins/databaseTriggerDebug.js';
import H265Player from './h265/Index.vue';

export default {
  name: 'LiveCom',
  components: {
		'el-empty': Empty,
    'el-image': Image,
    H265Player
  },
  props: {
		// 循环映射值
		mapData: {
			type: Object,
			default: () => {}
		}
  },
  mixins: [Minin, databaseTriggerDebug],
  data() {
    return {
      liveObj: null,
      player: null,
      url: '',
      loading: false,
      successed: true,
      streamPath: '',
      liveOption: {},
      liveObjCG: null,
      // 呈贡
      cgUrl: '',
      styleObj: {
        height: 600,
        width: 600
      },
      // 非沙特项目的兼容 不出现视频封面
      isManualPlay: true
    }
  },
  inject: ['EDITOR_pageUUID'],
  created() {
		if (this.database && !this.hasComponentParam()) {
      this._initDataDebug();
			const { search = [], param = {}, canPost } = initParams(
        this.element?.database?.paramsConfig || [],
        this.isGroup,
        this.subComponentData,
        this.groupComponents
      );
			if (!canPost) {
        this._failDataDebug('参数必填校验未通过');
        return;
      }
			this.getList(this.database, search, param);
		}
		this.metadata = this.element.metadata || [];
    this.setLiveOption();
	},
  mounted() {
		// 监听请求
		// 配置关联参数的容器才需要监听
    const databaseTrigger = {
      [this.element.id]: (data) => {
        if (data.parentId && data.parentId !== this.element.id) return false;
        // 配置时候触发请求
        if (data.componentId === this.element.id && data.isInit) {
					this._startDataDebug();
          const { search = [], param = {}, canPost } = initParams(
            this.element?.database?.paramsConfig || [],
            this.isGroup,
            this.subComponentData,
            this.groupComponents
          );
          // console.log("配置时候触发请求-------", canPost, search, param);
          if (!canPost) {
            this._failDataDebug('参数必填校验未通过');
            return;
          }
          this.getList(this.element.database, search, param);
          return;
        }
        // 点击操作时候不更新数据
        if (data.noUpdate) return;
        const { paramsConfig } = this.element.database;
        if (!paramsConfig || !paramsConfig.length) return;
        // 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
        this._startDataDebug();
        let { search = [], param = {}, canPost } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.subComponentData,
          this.groupComponents
        );
        // console.log("以下步骤是为了避免有多个来源的search需要进行differ-------", canPost, search, param);
        if (!canPost) {
          this._failDataDebug('参数必填校验未通过');
          return;
        }
        let isTarget = false;
        paramsConfig.forEach((ele) => {
          if (ele.componentId === data.componentId) {
            isTarget = true;
          }
        });
        if (!isTarget && !data.isUpdate) {
          this._failDataDebug('非当前组件或非更新');
          return;
        }
        this.param = param;
        this.getList(this.element.database, search, param);
      }
    }
		eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
	},
  directives: {
    /**
     * @description 用于生成直播流的指令
     * @param el { Object } element
     * @param binding { Object } src:地址; videoType:播放类型; setLiveObj:设置播放对象; setPlayer: 设置播放对象; player: 返回的实例
     */
    generateLive: (() => {
      // 使用闭包暂存处理函数
      const generateLive = (el, binding) => {
        // 如果存在旧值
        if (binding.oldValue) {
          const typeFlag = binding.oldValue.videoType === binding.value.videoType;
          const srcFlag = binding.oldValue.src === binding.value.src;
          const showControlFlag = binding.oldValue.showControl === binding.value.showControl;
          const autoPlayFlag = binding.oldValue.autoplay === binding.value.autoplay;
          // 如果值并没有变化则不进行处理，原因是因为当盒子被点击时compute会重新计算
          if (typeFlag && srcFlag && showControlFlag && autoPlayFlag) {
            return;
          }
        }
        // 如果存在之前的实例，停掉它
        if (binding.value.player && binding.value.player.stop) {
          binding.value.player.stop();
        }
        if (binding.value.player && binding.value.player.destroy) {
          binding.value.player.destroy();
        }
        // 清空el
        el.innerHTML = '';
        const videoObj = document.createElement('video');
        videoObj.style = 'width: 100%; height: 100%;object-fit: fill;';
        if(binding.value.autoplay) {
          videoObj.setAttribute('autoplay', 'autoplay');
        }
        if(binding.value.showControl) {
          videoObj.setAttribute('controls', binding.value.showControl);
        }
        videoObj.setAttribute('playsInline', true);
        videoObj.setAttribute('webkit-playsinline', true);
        videoObj.muted = true;
        const source = document.createElement('source');
        if(binding.value.videoType === 'YS7') {
          source.src = binding.value.src;
          source.setAttribute('type', "application/x-mpegURL");
          videoObj.appendChild(source);
          const player = new EZUIPlayer(videoObj);
          binding.value.setPlayer(player);
        } else if(binding.value.videoType === 'HLS') {
          if(HLS.isSupported()) {
            const hls = new HLS({
              manifestLoadingTimeOut: 30 * 1000
            });
            hls.attachMedia(videoObj);
            hls.on(HLS.Events.MEDIA_ATTACHED, function () {
              console.log('video and hls.js are now bound together !');
              hls.loadSource(binding.value.src);
              hls.on(HLS.Events.MANIFEST_PARSED, function (event, data) {
                console.log('manifest loaded, found ' + data.levels.length + ' quality level');
                videoObj.play();
              })
              hls.on(HLS.ErrorTypes.NETWORK_ERROR, (err, data) => {
                console.error(err, data);
              })
              hls.on(HLS.ErrorTypes.MEDIA_ERROR, (err, data) => {
                console.error(err, data);
              })
              hls.on(HLS.ErrorTypes.OTHER_ERROR, (err, data) => {
                console.error(err, data);
              })
            })
            binding.value.setPlayer(hls);
          }
        } else {
          // TIPS 对上传的内容进行适配
          if(Array.isArray(binding.value.src)&& binding.value.src.length) {
            if(binding.value.src[0].path || binding.value.src[0].url) {
              source.src = binding.value.src[0].path || binding.value.src[0].url
            }
          } else {
            source.src = binding.value.src || '';
          }
          videoObj.appendChild(source);
          videoObj.setAttribute('loop', true);
          if(binding.value.autoplay) {
            videoObj.setAttribute('autoplay', true);
          }
          const DOMId = getComponentId('editor-video');
          videoObj.setAttribute('id', DOMId)
          // 声音，暂时解决
          if(!binding.value.muted) {
            const initCancleMuted = () => {
              videoObj.muted = !videoObj.muted;
            }
            videoObj.addEventListener('click', initCancleMuted);
            setTimeout(() => {
              const videoDom = document.querySelector(`video#${DOMId}`);
              videoDom.click();
              videoObj.removeEventListener('click', initCancleMuted);
            }, 100);
          }
        }
        el.appendChild(videoObj);
        binding.value.setLiveObj(videoObj);
      }
      return (
        {
          inserted: generateLive,
          update: generateLive,
          unbind: (el, binding) => {
            if (binding.value.player && binding.value.player.stop) {
              binding.value.player.stop()
            }
            if (binding.value.player && binding.value.player.destroy) {
              binding.value.player.destroy()
            }
          }
        }
      )
    })()
  },
  computed: {
    ...mapState(['componentData', 'subsidiaryComponentData','signComponentData']),
    // 嵌入页面的参数获取
    subComponentData() {
      if (this.EDITOR_pageUUID) {
        return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentData;
      }
      return this.componentData;
    },
    // 数据仓库配置
		database() {
			return this.element && this.element.database;
		},
    showControl() {
      return this.element && this.element.statusConfig && this.element.statusConfig.showControl;
    },
    autoplay() {
      return (this.element && this.element.statusConfig && this.element.statusConfig.autoplay) || this.element?.statusConfig?.autoplay === undefined || this.isManualPlay; // 为了兼容旧版本
    },
		// 请求类型
		requestType() {
			return  this.element.database.requestType || 'dataList';
		},
    URLConfig() {
      const configData = this.element?.VideoURLConfig
      if(configData) {
        let value = ''
        if (configData.queryType === 'query') {
          value = getQueryValue(configData.value);
        } else if (configData.queryType === "component") {
          let originComponent = getComponentById(this.subComponentData, configData.component);
					if(this.signComponentData?.length){
						if(originComponent){
							// 其他组件取值依赖标记的数据，需要通过resolveId反向查找标记组件
							if(originComponent.resolveId){
								for(let i=0; i<this.signComponentData.length;i++){
									if(this.signComponentData[i].id){
										const idArr = this.signComponentData[i].id.split('-')
										if(this.signComponentData[i].id.includes(configData.component) && originComponent.resolveId === idArr[idArr.length - 1]){
											originComponent = this.signComponentData[i]
										}
									}
								}
							}
						}
					}
          if (originComponent.resolveData) {
            if(Array.isArray(originComponent.resolveData)) {
              value = originComponent.resolveData?.[0]?.[configData.value]
            } else {
              value = originComponent.resolveData[configData.value]
            }
          }
        } else if (configData.queryType === "system") {
          let systemObject = {}
          if (configData.systemKey === 'targetArchi') {
            systemObject = this.$GetTargetArchi()
          } else if (configData.systemKey === 'userInfo') {
            systemObject = this.$GetUserInfo()
          }
          value = systemObject[configData.systemCode]
        } else if (configData.queryType === 'valueMap') {
          // 值映射
          if (!configData?.valueMap?.bindField || !this.mapData?.renderData) {
            this.$message.error('配置错误!');
            return;
          }
          value = this.mapData?.renderData?.[configData?.valueMap?.bindField];
        } else {
          value = configData.value
        }
        return value
      } else {
        return ''
      }
    },
    // 封面映射配置
    coverConfig() {
      const configData = this.element?.VideoCoverConfig
      if(configData) {
        let value = ''
        if (configData.queryType === 'query') {
          value = getQueryValue(configData.value);
        } else if (configData.queryType === "component") {
          let originComponent = getComponentById(this.subComponentData, configData.component);
					if(this.signComponentData?.length){
						if(originComponent){
							// 其他组件取值依赖标记的数据，需要通过resolveId反向查找标记组件
							if(originComponent.resolveId){
								for(let i=0; i<this.signComponentData.length;i++){
									if(this.signComponentData[i].id){
										const idArr = this.signComponentData[i].id.split('-')
										if(this.signComponentData[i].id.includes(configData.component) && originComponent.resolveId === idArr[idArr.length - 1]){
											originComponent = this.signComponentData[i]
										}
									}
								}
							}
						}
					}
          if (originComponent.resolveData) {
            if(Array.isArray(originComponent.resolveData)) {
              value = originComponent.resolveData?.[0]?.[configData.value]
            } else {
              value = originComponent.resolveData[configData.value]
            }
          }
        } else if (configData.queryType === "system") {
          let systemObject = {}
          if (configData.systemKey === 'targetArchi') {
            systemObject = this.$GetTargetArchi()
          } else if (configData.systemKey === 'userInfo') {
            systemObject = this.$GetUserInfo()
          }
          value = systemObject[configData.systemCode]
        } else if (configData.queryType === 'valueMap') {
          // 值映射
          if (!configData?.valueMap?.bindField || !this.mapData?.renderData) {
            this.$message.error('配置错误!');
            return;
          }
          value = this.mapData?.renderData?.[configData?.valueMap?.bindField];
        } else {
          value = configData.value
        }
        return value
      } else {
        return ''
      }
    },
    // 状态路径
    statusPath() {
      return this.statusConfig.src;
    },
    videoType() {
      return this.statusConfig.videoType
    },
    outerNet() {
      return !!this.element.statusConfig.outerNet
    },
    actionConfig() {
      return this.element.actionConfig || []
    },
    muted() {
      return !!this.element.statusConfig.muted
    }
  },
  watch: {
    URLConfig() {
      this.setLiveOption();
    },
    url() {
      this.setLiveOption();
    },
    // statusPath() {
    //   this.setLiveOption();
    // },
    // showControl() {
    //   this.setLiveOption();
    // },
    // videoType() {
    //   this.setLiveOption();
    // }
  },
  methods: {
    /**
     * @description 在 https 下将地址 http 转为 https; 目前只是权宜之计;
     * @param { String } url 需要处理的url
     */
    formatURL(url) {
       if(url && typeof url === 'string') {
         const reg = /http[s]?/i;
         if(window.location.protocol === 'https:') {
           return url.replace(reg, 'https');
         }
       }
       return url
     },
    /**
		 * @desc: 获取渲染列表
		 * @param {Object} database 数据配置对象
		 * @param {Array} search 搜索
		 */
		getList(database, search = [], params = {}) {
			this.search = Array.isArray(search) ? search : [];
			this.sourceData = {};
			if (!this.validDatabase(database)) {
        this._failDataDebug('请求配置错误')
        return;
      }
			this.loading = true;
			const paramsObj = {
				...params
			};
			// console.log('获取渲染列表______表格容器', params, paramsObj);
			// 配置
			let __method_name__ = this.requestType;
			const mapping = database.mapping;
			let configObj = null;
			if (mapping === 'object') {
				configObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					view_uuid: database.viewData.uuid,
					transcode: 0,
					...paramsObj,
					search
				};
			} else if (mapping === 'relation') {
				__method_name__ = 'relationList';
				configObj = {
					__method_name__: 'relationList',
					object_uuid: database.objectData.uuid,
					relationship_uuid: database.relationData.uuid,
					...paramsObj,
					search
				};
			}
			if(this.requestType === 'dataInfo'){
				const arr = this.element.id.split('-');
        const idLastIndex = arr.findIndex(v => v.includes('view') || v.includes('page'));
        if (idLastIndex !== arr.length - 1) {
          configObj.data_id = arr[idLastIndex + 1];
        }
			}
      if(this.statusConfig.screen_sequence) {
        configObj.screen_sequence = this.statusConfig.screen_sequence;
      }
			// 获取表格数据
      this.url = '';
      this.loading = true;
      this.successed = false;
			dataInterface(configObj)
				.then(async (res) => {
					if (res && res.status === 200) {
            // 验证数据格式
            if(Array.isArray(res.data.data) && res.data.data.length) {
              this.url = res.data.data[0].monitor_path;
            } else if(Object.prototype.toString.call(res.data.data) === '[object Object]'){
							this.url = res.data.data.monitor_path;
						}
          }
          this.successed = true;
          this._successDataDebug({
            url: '',
            content: configObj,
            res
          });
				})
				.catch((err) => {
					console.log(err);
          this._errorDataDebug({
            url: '',
            content: configObj,
            err
          });
				}).finally(() => {
          this.loading = false;
        });
		},
    /**
		 * @desc: 验证
		 * @param {Object} database 数据仓库的绑定
		 */
		validDatabase(database) {
			if (!database || typeof database !== 'object') return false;
			if (!database.objectData) return false;
			if (!database.viewData && !database.relationData) return false;
			return true;
		},
    /**
		 * @desc: 判断是否存在依赖其他组件的取值
		 */
		hasComponentParam() {
			if (!this.database.paramsConfig || !this.database.paramsConfig.length) {
				return false;
			}
			for (let i = 0; i < this.database.paramsConfig.length; i++) {
				const { componentId = '', key = '', sourceType = '' } = this.database.paramsConfig[i];
				if ((key !== 'search' || !componentId.includes('CommonForm')) && sourceType !== 'url') {
					// componentId.includes('CommonTableContainer')兼容跨页请求的动态判定
					// 补充不同架构问题
					if (!componentId.includes('CommonTableContainer') && this.paramsSameArchi(componentId)) {
						return true;
					}
				}
			}
			return false;
		},
    /**
		 * @desc: 判断依赖的参数是否在当前架构下启用(@凌志华树形图架构限制)
		 * @param {String} componentId
		 * @return {Boolean}
		 */
		paramsSameArchi(componentId) {
			const comp = getComponentById(this.subComponentData, componentId);
			if (!comp) return false;
			const targetArchi = this.$GetTargetArchi('archiType');
			if (comp && comp.archiLimit && comp.archiLimit.length && comp.archiLimit.includes(targetArchi)) {
				return true;
			}
			return false;
		},
    setLiveObj(obj) {
      this.liveObj = obj
      obj.addEventListener('fullscreenchange', () => {
        if(document.fullscreenElement) {
          this.doAction('fullScreen')
        } else {
          this.doAction('exitFullScreen')
        }
      })
    },
    /**
     * @description: 执行动作，用的是树状图的
     * @param {*} type
     * @return {*}
     */    
    doAction(type) {
      const eventObj = this.actionConfig.find((ele) => ele.key === type);
      const comEvents = eventObj?.eventList || [];
      console.log(type, comEvents)
      for (let i = 0; i < comEvents.length; i++) {
        const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
        const result = pattern === "special" ? specialEventList : eventList;
        result.forEach((ele) => {
          if (ele.key === 'click') {
            ele.effects.forEach((effect) => {
              this.$store.commit('triggerEvents', {
                config: {
                  ...ele,
                ...effect
                },
                element: this.element,
                EDITOR_pageUUID: this.EDITOR_pageUUID
              });
            });
            // 触发组件行为
            const { behaviors } = ele;
              behaviors.forEach(behavior => {
                this.$store.commit('triggerEvents', {
                  config: {
                    behavior,
                    isBehavior: true
                  },
                  element: this.element,
                  EDITOR_pageUUID: this.EDITOR_pageUUID
                });
              });
						}
        });
      }
      return false;
    },
    setPlayer(obj) {
      this.player = obj
      // this.setLiveOption();
    },
    /**
     * @description: 设置直播配置
     */
    async setLiveOption() {
      // 如果不是手动播放 且没用自动播放
      if(!this.isManualPlay && !this.autoplay) return;
      this.loading = true;
      this.cgUrl = '';
      try{
        await this.closeOnlinePath();
      } catch(err) {
        // noting to do
      }
      this.URL = this.url || this.statusConfig.src || this.URLConfig || ''
      const url = await this.getOnlinePath(this.url || this.statusConfig.src || this.URLConfig || '');
      if (this.statusConfig.useInCG) {
        // 呈贡H265方案
        this.$nextTick(() => {
          const box = document.querySelector(`div#component${this.element.id}`);
          this.styleObj = {
            height: box?.offsetHeight || 600,
            width: box?.offsetWidth || 600
          }
          // this.cgUrl = 'http://192.168.2.187:8080/hdl/live/test.flv';
          // this.cgUrl = 'http://192.168.2.187:8080/fmp4/live/test.mp4';
          // this.cgUrl = 'ws://192.168.2.187:8080/jessica/live/test.flv';
          this.cgUrl = url;
          window.cgUrl = this.cgUrl;
          this.loading = false;
        });
        return;
      }
      // 常规方案
      this.liveOption = {
        src: this.formatURL(url),
        videoType: this.url ? 'YS7' : '' || this.videoType || 'other',
        showControl: this.showControl,
        setLiveObj: this.setLiveObj,
        setPlayer: this.setPlayer,
        player: this.player,
        autoplay: this.autoplay,
        muted: this.muted
      }
      this.loading = false;
    },
    /**
     * @description: 获取线上推流地址
     * @param {String} target
     */
    getOnlinePath(target) {
      this.streamPath = `live/${this.getLiveId()}`;
      return new Promise((resolve, reject) => {
        if (!this.statusConfig.useInCG || !target) {
          resolve(target);
          return;
        }
        this.liveObjCG = {
          target,
          streamPath: this.streamPath
        }
        if(this.outerNet) {
          // 沙特外网 传递的是监控的id
          dataInterface({ monitor_id: target }, '/api/hikvision/wvp/getStream', 'post').then(res => {
            resolve(res?.data?.data?.https_flv);
          }).catch(err => {
            console.log(err);
            reject(null);
          });
        } else {
          // 呈贡 沙特 内网方案 传递rtsp 地址 测试发现使用的是FLV(http) 编码为 H264 
          dataInterface(this.liveObjCG, '/api/m7s/play', 'GET').then(res => {
            resolve(res?.data?.data);
          }).catch(err => {
            console.log(err);
            reject(null);
          });
        }
      });
    },
    /**
     * @description: 关闭推流
     */
    closeOnlinePath() {
      if (!this.statusConfig.useInCG || !this.streamPath || !this.liveObjCG) {
        return true;
      }
      this.liveObjCG = null;
      this.cgUrl = '';
      return new Promise((resolve, reject) => {
        try {
          if(this.outerNet) {
            dataInterface({
              monitor_id: this.URL
            }, '/api/hikvision/wvp/stopStream', 'post').then(() => {
              resolve(true);
            }).catch(err => {
              console.log(err);
              reject(false);
            });
          } else {
            dataInterface({
              streamPath: this.streamPath
            }, '/api/m7s/closestream', 'DELETE').then(() => {
              resolve(true);
            }).catch(err => {
              console.log(err);
              reject(false);
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
    },
    /**
     * @description: 获取直播流ID
     */
    getLiveId() {
        var d = new Date().getTime();
        if (window.performance && typeof window.performance.now === "function") {
          d += performance.now(); //use high-precision timer if available
        }
        var uuid = 'xxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;    // d是随机种子
          d = Math.floor(d / 16);
          return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    },
    doPlay() {
      this.isManualPlay = true;
      this.setLiveOption();
    }
  },
  async beforeDestroy() {
    await this.closeOnlinePath();
  }
}
</script>
<style lang="less" scoped>
  .live-com-container{
    width: 100%;
    height: 100%;
    .cover-wrap{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .cover-img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .iconfont {
        font-size: 32px;
        position: relative;
      }
    }
    .live-com-inner{
      width: 100%;
      height: 100%;
    }
    .none-msg{
       width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>