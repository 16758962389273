<template>
  <div class="flow-designer">
    <div class="container">
      <div class="wrapper">
        <div class="content">
          <div class="left" v-if="editable">
            <div v-for="(item, index) in flowTools" :key="index">
              <p class="sub-title">{{ item.name }}</p>
              <div class="cell-wrap">
                <a
                  class="cell-item"
                  v-for="(eleItem, eleInd) in item.components"
                  :key="eleInd"
                  @mousedown="onDrag($event, eleItem)"
                >
                  <img class="cell-icon" :src="eleItem.image" alt="" />
                  <p class="cell-txt">{{ eleItem.name }}</p>
                </a>
              </div>
            </div>
          </div>
          <div class="graph-container" ref="x6Main"></div>
          <div class="action-btn" v-if="editable">
            <el-button-group>
              <el-button plain size="small" @click="btnEvent('basicSetting')">
                全局配置
              </el-button>
              <el-button plain size="small" @click="btnEvent('saveFlow')">
                保存设计
              </el-button>
              <el-button plain size="small" @click="btnEvent('tempPool')">
                模板库
              </el-button>
            </el-button-group>
          </div>
        </div>
        <div
          class="cell-prop"
          v-if="['Step', 'edge', 'Send', 'SubFlow'].includes(cellType)"
        >
          <p class="header">
            <span class="title">流程组件属性配置</span>
            <span class="action-icon">
              <i class="iconfont iconc-close" @click="cellType = 'Start'"></i>
            </span>
          </p>
          <div class="wrapper-prop">
            <component
              ref="flowProp"
              :key="key"
              :is="getWidgetName"
              :archi-id="archi_id"
              :parent-archi-id="parent_archi_id"
              :cur-cell-prop="curCellProp"
              :form-item="formInputItem"
              :user-data="userData"
              :sub-flow-list="subFlowList"
              :form-design="formDesign"
              :disabled="!editable"
              @on-form="addExtendForm"
              @on-change="updateProps"
            ></component>
          </div>
        </div>
        <div class="flow-template" v-if="showPool">
          <p class="header">
            <span class="title">标准流程模板库</span>
            <span class="action-icon" @click="closePool">
              <i class="iconfont iconc-close" @click="showPool = false"></i>
            </span>
          </p>
          <div class="wrapper-prop">
            <h5 class="temp-title">
              流程模板快照
              <el-button type="primary" class="capture" size="mini" icon="el-icon-plus" @click="saveTemplate">
                保存模板
              </el-button>
            </h5>
            <div class="temp-list-item" v-for="(item,index) in tempList" :key="index">
              <div class="temp-image">
                <el-image
                  style="width: 100%"
                  fit="contain"
                  :src="item.temp_data"
                  :preview-src-list="[item.temp_data]"
                ></el-image>
              </div>
              <div class="temp-text">
                <p class="name">{{item.name}}</p>
                <p class="sub-title">{{item.name}}</p>
                <div class="sub-title-1">
                  <div style="font-size: 12px; line-height: 30px; color: #4E8AFB"></div>
                  <el-button size="mini" icon="el-icon-s-help" @click="useTemplate(item.data)">使用模板</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flow-template" v-if="dialogVisible">
          <p class="header">
            <span class="title">流程基本配置</span>
            <span class="action-icon" @click="closePool">
              <i class="iconfont iconc-close" @click="dialogVisible = false"></i>
            </span>
          </p>
          <div class="wrapper-prop basic-setting">
            <el-form ref="flow" :model="flow" :rules="rules" size="small" label-position="left" label-width="80px">
              <el-form-item label="流程名称" prop="name">
                <el-input v-model="flow.name"></el-input>
              </el-form-item>
              <el-form-item label="流程描述">
                <el-input v-model="flow.desc"></el-input>
              </el-form-item>
              <el-form-item label="数据对象">
                <SelectObjectUUID v-model="tempFlowForm.objectUUID" @update="onChange"></SelectObjectUUID>
              </el-form-item>
              <el-form-item label="表单对象">
                <!-- 表单对象 -->
                <el-select
                  style="width: 100%"
                  v-model="tempFlowForm.formUUID"
                  filterable
                  remote
                  :remote-method="onRemoteForm"
                  :loading="formLoading"
                >
                  <el-option
                    v-for="formObj in formList"
                    :key="formObj.id"
                    :label="formObj.name || '默认'"
                    :value="formObj.uuid"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="待办模板">
                <el-select
                  style="width: 100%"
                  v-model="flow.do_msg_template_id"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in msgTempList"
                    :key="item.template_id"
                    :label="item.name"
                    :value="item.template_id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="抄送模板">
                <el-select
                  style="width: 100%"
                  v-model="flow.see_msg_template_id"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in msgTempList"
                    :key="item.template_id"
                    :label="item.name"
                    :value="item.template_id"
                  ></el-option>
                </el-select>
              </el-form-item>
<!--              <el-divider>流程节点表单控制过滤</el-divider>
              <el-form-item
                v-for="item in formInputItem"
                :key="item.formId"
                :label="item.label">
                <el-switch v-model="item.open" style="float: right;margin-top: 5px"></el-switch>
              </el-form-item>-->
            </el-form>
          </div>
          <div slot="footer" class="footer">
            <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="onConfirm">确 定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import X6Graph from './scripts/X6GraphInit';
import CellProp from './component/cell-panel';
import {ButtonGroup,Image,MessageBox,Divider} from 'element-ui'
import {flowEngine} from "@/apis/data/workflow";
import {dataInterface, transFieldsUUid} from "@/apis/data";
import SelectObjectUUID from "@/components/common/SelectObjectUUID.vue";
import {viewGetFormItem} from "@/custom-component/form/newParser/scripts/itemDataCopy";

export default {
  name: 'FlowDesigner',
  components: {
    ...CellProp,
    'el-button-group': ButtonGroup,
    'el-image': Image,
    'el-divider': Divider,
    SelectObjectUUID
  },
  props: {
    objectUuid: {
      type: String,
      default: '',
    },
    formUuid: {
      type: String,
      required: true,
      default: '',
    },
    flowData: {
      type: Object,
      default: () => {},
    },
    formItem: {
      type: Array,
      default: () => [],
    },
    userData: {
      type: Array,
      default: () => [],
    },
    subFlowList: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FlowTemp: 'https://static.bimcc.com/openim/16639288116296D79CD3BF3E1B02CECFD1879581E2797.png',
      flowTools: [
        {
          name: '基础组件',
          components: [
            { name: '开始节点', type: 'Start', image: 'https://static.bimcc.com/openim/1663928620013BE10467DE0FA643E921C88E409E52B32.png' },
            { name: '审批节点', type: 'Step', image: 'https://static.bimcc.com/openim/16639286429162E5B90EB87ADA9658C37F4EB11DA5900.png' },
            { name: '抄送节点', type: 'Send', image: 'https://static.bimcc.com/openim/166392868839080209E812AAAEBDEB8D4DD11337696B0.png' },
            { name: '并行网关', type: 'Gateway', image: 'https://static.bimcc.com/openim/166392872065237AB38971CDC1BC4720636351D708E50.png' },
            { name: '结束节点', type: 'End', image: 'https://static.bimcc.com/openim/16639287328607A511E01BA69C07D1F7DA3FE6A7F97EA.png' },
          ],
        },
        {
          name: '高级组件',
          components: [
            { name: '子流程', type: 'SubFlow', image: 'https://static.bimcc.com/openim/1663928742870F1B69AAD6F8029B2E928D991C7137E7C.png' },
            { name: '系统任务', type: 'Event', image: 'https://static.bimcc.com/openim/16639287590573AEF784ABF87A556CB54131F3D027371.png' },
          ],
        },
      ],
      dialogVisible: false,
      flow: {
        name: '',
        desc: '',
        formUuid: this.formUuid,
        module: this.formUuid,
        do_msg_template_id: '',
        see_msg_template_id: ''
      },
      dialogTempVisible: false,
      formList: [],
      formLoading: false,
      tempFlowForm: {
        objectUUID: this.objectUuid,
        formUUID: this.formUuid,
      },
      rules: {
        name: [{required:true, message:'请输入流程名称', trigger: 'blur'}],
      },
      x6Proxy: null,
      curCell: null,
      curCellProp: null,
      loading: false,
      showProp: false,
      cellType: '',
      key: 'flowprop',
      showPool: false,
      designer: {},
      formDesign: {},
      tempList: [],
      msgTempList: [],
      formInputItem: this.formItem,
      archi_id: '',
      parent_archi_id: '',
    };
  },
  created() {
    this.getMsgTemplate()
  },
  mounted() {
    this.initX6Graph();
    this.templateList()
  },
  computed: {
    getWidgetName() {
      let { cellType } = this;
      if (this.cellType === 'edge') {
        cellType = 'Edge';
      }
      return `${cellType}Prop`;
    },
  },
  methods: {
    getMsgTemplate(){
      const data = {
        __method_name__: 'dataList',
        object_uuid: 'object64462e9f03920',
        view_uuid: 'view66af38d1da89b',
        transcode: 0,
        size: 9999
      }
      dataInterface(data).then(res=>{
        if(res.data.code == 200){
          this.msgTempList = res.data.data.data
        }
      })
    },
    changeFormItem(){
      if(this.tempFlowForm.formUUID) {
        transFieldsUUid(this.tempFlowForm.formUUID).then(res=>{
          const jsonFields = res.data.data?.fields || []
          this.formInputItem = viewGetFormItem(jsonFields)
          this.dialogTempVisible = false
        })
      }
    },
    onChange(){
      if(this.tempFlowForm.objectUUID){
        this.onRemoteForm('')
      }
    },
    onRemoteForm(name) {
      this.formLoading = true;
      dataInterface(
        {
          object_uuid: this.tempFlowForm.objectUUID,
          name
        },
        'api/metadata/getFormDesigns',
        'GET'
      )
        .then(res => {
          this.formList = res?.data?.data || [];
          this.formLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.formList = [];
          this.formLoading = false;
        });
    },
    initX6Graph() {
      let cells = [];
      this.flow.name = this.flowData?.name || '';
      this.flow.desc = this.flowData?.desc || '';
      this.flow.do_msg_template_id = this.flowData?.do_msg_template_id || ''
      this.flow.see_msg_template_id = this.flowData?.see_msg_template_id || ''
      this.archi_id = this.flowData.archi_table + '-' + this.flowData?.archi_id
      this.parent_archi_id = this.flowData?.parent_archi_id
      if(this.flowData?.canvas_data){
        cells = this.flowData.canvas_data.cells;
        // 移除老配置的折线功能
        if (cells?.length) {
          cells.forEach(element => {
            if (element?.shape === 'edge' && element?.tools) {
              delete element.tools;
            }
          });
        }
        if(this.flowData.canvas_data?.objectUUID || this.tempFlowForm.objectUUID){
          if(this.flowData.canvas_data?.objectUUID){
            this.tempFlowForm.objectUUID = this.flowData.canvas_data?.objectUUID
          }
          this.onRemoteForm('')
        }
        if(this.flowData.canvas_data?.formUUID){
          this.tempFlowForm.formUUID = this.flowData.canvas_data?.formUUID
          this.changeFormItem()
        }
      }
      this.x6Proxy = new X6Graph(this, cells);
    },
    onDrag(e, target) {
      this.x6Proxy.handleDrag(e, target);
    },
    updateProps(data) {
      if (this.curCell) {
        if (this.cellType === 'edge') {
          if (data.name) {
            const label = {
              markup: [
                { tagName: 'rect', selector: 'body' },
                { tagName: 'text', selector: 'label' },
              ],
              attrs: {
                text: {
                  text: data.name,
                },
                label: {
                  fill: '#000',
                  fontSize: 14,
                  textAnchor: 'middle',
                  textVerticalAnchor: 'middle',
                  pointerEvents: 'none',
                },
                body: {
                  ref: 'label',
                  fill: '#ffd591',
                  stroke: '#ffa940',
                  strokeWidth: 2,
                  rx: 4,
                  ry: 4,
                  refWidth: '140%',
                  refHeight: '140%',
                  refX: '-20%',
                  refY: '-20%',
                },
              },
              zIndex: 10,
            };
            this.curCell.setLabels([label]);
          } else {
            this.curCell.setLabels([]);
          }
        } else if (['Step', 'Send', 'SubFlow'].includes(this.cellType)) {
          this.curCell.attr('label/text', data.name);
        }
        this.curCell.updateData(data);
      }
    },
    btnEvent(eventName) {
      this[eventName]();
    },
    openTour() {},
    basicSetting(){
      this.dialogVisible = true
    },
    templateList() {
      const data = {
        __method_name__: 'dataList',
        object_uuid: 'object617fbee0b7fc9',
        view_uuid: 'view640163919e10c',
        transcode: 0
      }
      dataInterface(data).then(res=>{
        if(res.data.code == 200){
          this.tempList = res.data.data
        }
      })
    },
    useTemplate(data) {
      if(!data){
        this.$message.error('流程模板不存在')
        return
      }
      const url = process.env.VUE_APP_BASE_URL + data
      fetch(url).then(e => e.json()).then(data=>{
        this.x6Proxy.graph.fromJSON(data)
        this.x6Proxy.graph.centerContent();
      }).catch(() => {
        this.$message.error('流程模板不存在')
      })
    },
    saveTemplate () {
      if(this.x6Proxy){
        MessageBox.prompt('请输入模板名称', '正在保存流程模板', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputErrorMessage: '流程模板名称必须',
          inputValidator: (value) => {
            if(value === '' || value === null){
              return false
            }
          }
        }).then(({ value }) => {
          const valid = this.saveFlow(false)
          if(!valid) return
          this.x6Proxy.graph.toPNG((dataUri) => {
            // dataUri base64图片
            const designData = {
              name: value,
              temp_data: dataUri,
              is_template: 1,
              ...this.designer
            }
            flowEngine.designCreate(designData).then(res => {
              if(res.data.code == 200){
                this.templateList()
              }
            })
          },{
            top: 30,
            right: 30,
            bottom: 30,
            left: 30,
          })
        }).catch(() => {});
      }
    },
    saveFlow(flag = true) {
      this.cellType = ''
      if (this.x6Proxy) {
        this.x6Proxy.graph.cleanSelection();
        const graphData = this.x6Proxy.graph.toJSON();
        const nodeData = this.formatData(graphData.cells);
        const gateways = graphData.cells.filter(item => item.shape === 'Gateway')
        const edges = graphData.cells.filter(item => item.shape === 'edge')
        const dieCircel = edges.find(t=>t.source.cell === t.target.cell)
        if(dieCircel){
          this.$message.error('节点连线不允许自己连自己')
          return false
        }
        if(gateways.length % 2 !== 0) {
          this.$message.error('【并行网关】必须成对出现')
          return false
        }
        for (let gate of gateways) {
          let source = edges.filter(item => item.source.cell === gate.id)
          let target = edges.filter(item => item.target.cell === gate.id)
          if(!((source.length === 1 && target.length>1) || (source.length > 1 && target.length === 1))){
            this.$message.error('【并行网关】必须单入多出或多入单出')
            return false
          }
        }
        for (const node of nodeData.node_data.nodeList) {
          if (node.nodeType === 'Step' && node.userType === 1 && node.targetUser.length === 0) {
            this.$message.error(
              `审批节点：[ ${node.name} ] 责任人不能为空，请选择责任人`,
            );
            return;
          } else if (node.nodeType === 'Step' && node.userType === 5 && node.userSection.length === 0) {
            this.$message.error(
              `审批节点：[ ${node.name} ] 部门不能为空，请选择部门`,
            );
            return;
          } else if (node.nodeType === 'Step' && node.userType === 6 && node.userJob.length === 0) {
            this.$message.error(
              `审批节点：[ ${node.name} ] 岗位不能为空，请选择岗位`,
            );
            return;
          }
        }
        for (const line of nodeData.node_data.lineList) {
          const condition = line.condition.filter(item=>{
            if(item.judgeType === 1 && item.publisher.length){
              return true
            } else if(item.judgeType === 2 && item.field && item.value){
              return true
            }
            return false
          })
          line.condition = condition
        }
        const valid = this.validTaskEngine(graphData.cells);
        if (!valid.startNode || !valid.endNode) {
          this.$message.error('开始或结束节点缺失，请检查后保存！');
          return;
        }
        const start = graphData.cells.find(t=>t.shape === 'Start')
        const end = graphData.cells.find(t=>t.shape === 'End')
        const startEdges =  graphData.cells.filter(t=>t.shape === 'edge' && t.source.cell === start.id)
        const endEdges =  graphData.cells.filter(t=>t.shape === 'edge' && t.target.cell === end.id)
        if(startEdges.length === 0) {
          this.$message.error('开始节点连线缺失，请检查后保存！');
          return;
        }
        if(endEdges.length === 0) {
          this.$message.error('结束节点连线缺失，请检查后保存！');
          return;
        }
        for (const item of graphData.cells) {
          if (
            (item.shape === 'Step' || item.shape === 'Send')
            && (!valid.source.includes(item.id) || !valid.target.includes(item.id))
          ) {
            this.$message.error('流程节点连线未连接完整，请检查后保存！');
            return;
          }
        }
        const saveData = {
          data: graphData,
          ...nodeData,
        };
        if(flag){
          this.dialogVisible = true
        }
        this.designer = saveData
        return true
      }
    },
    onConfirm() {
      const valid = this.saveFlow()
      if(!valid) return
      const designData = {
        ...this.flow,
        ...this.designer
      }
      this.dialogVisible = false
      if(this.tempFlowForm.objectUUID){
        this.designer.data.objectUUID = this.tempFlowForm.objectUUID
      }
      if(this.tempFlowForm.formUUID){
        this.designer.data.formUUID = this.tempFlowForm.formUUID
      }
      this.changeFormItem()
      this.$emit('on-save',designData)
    },
    formatData(graphData) {
      const nodeList = [];
      const lineList = [];
      let start_node = '';
      let end_node = '';
      for (const item of graphData) {
        if (['edge', 'Gateway'].includes(item.shape)) {
          const newData = {
            nodeId: item.id,
            nodeType: item.shape,
            ...item.data,
          };
          if (item.shape === 'edge') {
            newData.from = item.source.cell;
            newData.to = item.target.cell;
            lineList.push(newData);
          } else {
            nodeList.push(newData);
          }
        } else {
          if (item.shape === 'Start') start_node = item.id;
          if (item.shape === 'End') end_node = item.id;
          const newData = {
            nodeId: item.id,
            nodeType: item.shape,
            ...item.data,
          };
          nodeList.push(newData);
        }
      }
      const node_data = { nodeList, lineList };
      return { node_data, start_node, end_node };
    },
    validTaskEngine(graphData) {
      let startNode = false;
      let endNode = false;
      let startNodeId = '';
      let source = [];
      let target = [];
      let firstNode = [];

      for (const cell of graphData) {
        if (cell.shape === 'Start') {
          startNode = true;
          startNodeId = cell.id;
        }
        if (cell.shape === 'End') endNode = true;
        if (cell.shape === 'edge') {
          source = source.concat(cell.source.cell);
          target = source.concat(cell.target.cell);
        }
      }

      const firstEdge = graphData.filter(
        (item) => item.shape === 'edge' && item.source.cell === startNodeId,
      );
      let firstNodeId = [];
      if (firstEdge instanceof Array) {
        firstNodeId = Array.from(firstEdge, (item) => item.target.cell);
        firstNode = graphData.filter(
          (item) => item.shape === 'Step' && firstNodeId.includes(item.id),
        );
      }
      return {
        startNode, endNode, source, target, firstNode,
      };
    },
    tempPool() {
      this.dialogVisible = false;
      this.showPool = true;
    },
    closePool() {
      this.showPool = false;
    },
    changeFLowProp(){
      if(this.$refs.flowProp?.onChange){
        this.$refs.flowProp.onChange()
      }
    },
    addExtendForm(formRecover){
      this.$emit('on-form', formRecover)
    },
    setExtendForm(formDesign){
      this.formDesign = formDesign
    },
  },
};
</script>

<style lang="less" scoped>
:deep(.select-obect-uuid){
  .el-select{
    width: 100%;
  }
}

.flow-designer {
  width: 100%;
  height: 100%;

  .container {
    width: 100%;
    height: 100%;

    .wrapper {
      position: relative;
      height: 100%;
      .content {
        display: flex;
        height: 100%;
        position: relative;

        .left {
          width: 240px;
          height: 100%;
          padding: 0 15px;

          .sub-title {
            text-align: left;
            color: #cccccc;
            font-size: 14px;

            &:first-child {
              margin-top: 10px;
            }
          }

          .cell-wrap {
            margin: 15px 0;
            display: flex;
            flex-wrap: wrap;

            .cell-item {
              text-align: center;
              display: block;
              width: 80px;
              padding: 15px;
              background-color: #fafafb;
              margin: 5px;
              cursor: pointer;

              &:hover {
                background-color: #f7f9ff;
              }

              .cell-icon {
                width: 35px;
                height: 35px;
              }
              .cell-txt {
                font-size: 14px;
              }
            }
          }
        }
        .graph-container {
          height: 100%;
          flex: 1;
          background-color: #f7f9ff;
        }

        .action-btn {
          position: absolute;
          top: 6px;
          left: 285px;
        }
      }

      .cell-prop {
        position: absolute;
        top: 0;
        right: 0;
        width: 380px;
        height: 100%;
        background-color: #ffffff;

        .header {
          font-size: 14px;
          height: 45px;

          .title {
            margin-left: 10px;
            line-height: 53px;
          }

          .action-icon {
            .iconc-close {
              font-size: 20px;
            }
            float: right;
            margin-top: 14px;
            margin-right: 8px;
          }
        }

        .wrapper-prop {
          padding: 5px 10px;
          height: calc(100% - 55px);
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 6px;
            height: 8px;
            margin: 0 3px;
            display: none;
          }
          &::-webkit-scrollbar-track {
            background-color: #ffffff;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #fafafb;
            border-radius: 4px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background-color: #fafafb;
          }
          &::-webkit-scrollbar-thumb:active {
            background-color: #fafafb;
          }
          &::-webkit-scrollbar-corner {
            background-color: #ffffff;
          }
        }
      }
      .flow-template {
        position: absolute;
        top: 0;
        right: 0;
        width: 380px;
        height: 100%;
        background-color: #ffffff;
        text-align: initial;

        .header {
          font-size: 14px;
          height: 45px;

          .title {
            margin-left: 10px;
            line-height: 53px;
          }

          .action-icon {
            .iconc-close {
              font-size: 20px;
            }
            float: right;
            margin-top: 14px;
            margin-right: 8px;
          }
        }

        .footer {
          margin-top: 10px;
          text-align: right;
          margin-right: 10px;
        }

        .wrapper-prop {
          padding: 5px 10px;
          height: calc(100% - 55px);
          overflow-y: auto;

          &.basic-setting {
            height: calc(100% - 110px);
          }

          &::-webkit-scrollbar {
            width: 6px;
            height: 8px;
            margin: 0 3px;
            display: none;
          }

          .temp-title {
            padding-left: 10px;
            margin-bottom: 20px;
            border-left: 3px solid var(--themeColor);
            color: #cccccc;
            .capture {
              float: right;
              margin-top: -4px;
            }
          }

          .temp-list-item {
            margin: 10px 0;
            display: flex;

            .temp-image {
              width: 100px;
              height: 100px;
              border: 1px solid #d9d9d9;
              border-radius: 5px;
              :deep(.el-image) {
                height: 100%;
              }
            }
            .temp-text {
              flex: 1;
              margin-left: 15px;

              .name {
                margin-top: 5px;
                font-size: 14px;
              }

              .sub-title {
                margin-top: 8px;
                font-size: 12px;
                color: #cccccc;
              }
              .sub-title-1{
                margin-top: 18px;
                text-align: right;
                display: flex;
                justify-content: space-between;
              }
            }
          }
        }
      }
    }
  }
}
</style>
