/* eslint-disable */
import {formRequest} from "@/apis/data/form";

export const getSearchData = (filterRule,getFormIdValue) => {
  let searchData=[];
  for (let itemV of filterRule){
    let itemData = { ruleType: itemV.ruleType };
    if(itemV.paramType === 2){
      itemData.field_uuid = itemV.field_uuid;
    }else{
      itemData.code = itemV.code;
    }
    if(itemV.valueType === 2){
      itemData.value = getFormIdValue(itemV.value)
      if(['in','notin'].includes(itemV.ruleType) && !(itemData.value instanceof Array)){
        itemData.value = [itemData.value]
      }
    }else{
      itemData.value = itemV.value
    }
    if(itemV.bodyType !== 2){
      searchData.push(itemData)
    }
  }
  return searchData
}

export const getBodyParams = (filterRule,getFormIdValue) => {
  let bodyParams = {}
  for (let itemV of filterRule){
    let itemData = { ruleType: itemV.ruleType };
    if(itemV.bodyType && itemV.bodyType === 2){
      let paramKey = '', paramVal = ''
      if(itemV.paramType === 2){
        paramKey = itemV.field_uuid;
      }else{
        paramKey = itemV.code;
      }
      if(itemV.valueType === 2){
        paramVal = getFormIdValue(itemV.value)
        if(['in','notin'].includes(itemV.ruleType) && !(itemData.value instanceof Array)){
          paramVal = [itemData.value]
        }
      }else{
        paramVal = itemV.value
      }
      bodyParams[paramKey] = paramVal
    }
  }
  return bodyParams
}

export async function getRequestData(objectUuid,viewUuid,searchData= []) {
	let url, params;
	url = '/api/mapi'
	params = {
		__method_name__: 'dataList',
		object_uuid: objectUuid,
		view_uuid: viewUuid,
		size: 1000,
		search: searchData
		// transcode: 0,
	}

	if(searchData.length) params.search = searchData
	if(objectUuid && viewUuid){
		let response = await formRequest('post', url, params);
		let respData;
		if (response.data.data.data) {
			respData = response.data.data.data
		} else {
			respData = response.data.data
		}
		return respData;
	}
	return [];
}

/**
 * 格式化option(select/treeSelect/cascader)
 * @param originData
 * @param tag 组件类型
 * @param label 标签文字
 * @param value 选项值
 * @param child 递归children
 * @return {*[]}
 */
export function optionData(originData,tag,label,value,child=''){
	let optionData = [];
	if(tag === 'select'){
		for (let element of originData) {
			if (element[label] && element[value]) {
				let item = {label: element[label], value: element[value],allField:element};
				optionData.push(item);
			}
		}
	} else if(tag === 'treeSelect' || tag === 'cascader'){
		function mapTree (data) {
			const haveChildren = Array.isArray(data[child]) && data[child].length > 0;
			const optionData = {
				id: data[value],
				label: data[label],
				value: data[value],
				allField: data,
			}
			if(tag === 'treeSelect' || (tag === 'cascader' && haveChildren)) {
				optionData.children = haveChildren ? data[child].map(i => mapTree(i)) : []
			}
			return optionData
		}
		optionData = originData.map(item => mapTree(item));
	}

	return optionData
}

// json化控件数据
export const parseJson = (value)=>{
	if(!(value instanceof Array)){
			try{
				return JSON.parse(value);
			}catch (e){
				console.warn('json解析错误：',e)
				return value
			}
	}
	return value
}

// 控件数据回显统一格式化处理
export function formatValue(tagIcon,value,multiple=false){
	switch (tagIcon) {
		case 'date-range':
		case 'upload':
		case 'bimList':
		case 'drawingList':
		case 'modelView':
		case 'user':
			return parseJson(value);
		case 'cascader':
		case 'checkbox':
		case 'signature':
			return parseJson(value);
		case 'select':
			if(multiple) return parseJson(value);
			return value + '';
		case 'date':
			if(value === null) return '';
			if(value && value.indexOf('0000-00-00')>-1) return '';
			return value;
		case 'number':
		case 'slider':
		case 'rate':
			return parseInt(value);
		case 'switch':
			return (value == 'true' || value == 1)? true : false;
		case 'treeSelect':
			if(multiple) return parseJson(value);
			return value+'';
		case 'position':
			if (value === '' || value === null || value === undefined){
				value = []
			} else if(typeof value === 'string'){
				value = [value]
			} else {
				value = parseJson(value);
			}
			return value;
		default:
			if(value===null||value===undefined){
				return ''
			}
			return value+''
	}
}

// 小写金额转中文大写金额
export function numToCny(money) {
	// 汉字的数字
	const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
	// 基本单位
	const cnIntRadice = ['', '拾', '佰', '仟'];
	// 对应整数部分扩展单位
	const cnIntUnits = ['', '万', '亿', '兆'];
	// 对应小数部分单位
	const cnDecUnits = ['角', '分', '毫', '厘'];
	// 整数金额时后面跟的字符
	let cnInteger = '整';
	// 整型完以后的单位
	let cnIntLast = '元';
	// 最大处理的数字
	let maxNum = 999999999999999.9999;
	// 金额整数部分
	let integerNum;
	// 金额小数部分
	let decimalNum;
	// 输出的中文金额字符串
	let chineseStr = '';
	// 分离金额后用的数组，预定义
	let parts;
	if (money == '') {
		return '';
	}
	money = parseFloat(money);
	if (money >= maxNum) {
		// 超出最大处理数字
		return '';
	}
	if (money == 0) {
		chineseStr = cnNums[0] + cnIntLast + cnInteger;
		return chineseStr;
	}
	// 转换为字符串
	money = money.toString();
	if (money.indexOf('.') == -1) {
		integerNum = money;
		decimalNum = '';
	} else {
		parts = money.split('.');
		integerNum = parts[0];
		decimalNum = parts[1].substr(0, 4);
	}
	// 获取整型部分转换
	if (parseInt(integerNum, 10) > 0) {
		let zeroCount = 0;
		let IntLen = integerNum.length;
		for (let i = 0; i < IntLen; i++) {
			let n = integerNum.substr(i, 1);
			let p = IntLen - i - 1;
			let q = p / 4;
			let m = p % 4;
			if (n == '0') {
				zeroCount++;
			} else {
				if (zeroCount > 0) {
					chineseStr += cnNums[0];
				}
				// 归零
				zeroCount = 0;
				chineseStr += cnNums[parseInt(n)]
					+ cnIntRadice[m];
			}
			if (m == 0 && zeroCount < 4) {
				chineseStr += cnIntUnits[q];
			}
		}
		chineseStr += cnIntLast;
	}
	// 小数部分
	if (decimalNum != '') {
		let decLen = decimalNum.length;
		for (let i = 0; i < decLen; i++) {
			let n = decimalNum.substr(i, 1);
			if (n != '0') {
				chineseStr += cnNums[Number(n)] + cnDecUnits[i];
			}
		}
	}
	if (chineseStr == '') {
		chineseStr += cnNums[0] + cnIntLast + cnInteger;
	} else if (decimalNum == '') {
		chineseStr += cnInteger;
	}
	return chineseStr;
}

/**
 * 千分位格式化
 * @param number
 * @returns {string}
 */
export const formatThousandsMoney = (number) => {
  // 数字转为字符串，并按照 .分割
  let arr = (number + '').split('.');
  let int = arr[0] + '';
  let fraction = arr[1] || '';
  // 多余的位数
  let f = int.length % 3;
  // 获取多余的位数，f可能是0， 即r可能是空字符串
  let r = int.substring(0, f);
  // 每三位添加','金额对应的字符
  for (let i = 0; i < Math.floor(int.length / 3); i++) {
    r += ',' + int.substring(f + i * 3, f + (i + 1) * 3);
  }
  // 多余的位数，上面
  if (f === 0) {
    r = r.substring(1);
  }
  // 调整部分和小数部分拼接
  return r + (!!fraction ? '.' + fraction : '');
}

/**
 * 按指定格式日期格式化
 * @param {string} fmt  yyyy-MM-dd hh:mm:ss
 * @param {Date} date
 * @returns {string}
 */
export function dateFormat(fmt,date){
  if(!date){
    date = new Date();
  }
  if(typeof date === 'string'){
    date = new Date(date);
  }
  const o = {
    "M+" : date.getMonth()+1, //月份
    "d+" : date.getDate(), //日
    "h+" : date.getHours()%12 === 0 ? 12 : date.getHours()%12, //小时
    "H+" : date.getHours(), //小时
    "m+" : date.getMinutes(), //分
    "s+" : date.getSeconds(), //秒
    "q+" : Math.floor((date.getMonth()+3)/3), //季度
    "S" : date.getMilliseconds() //毫秒
  };
  if(/(y+)/.test(fmt)){
    fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length));
  }
  for(let k in o){
    if(new RegExp("("+ k +")").test(fmt)){
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length===1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
    }
  }

  return fmt;
}

/**
 * 流程控制控件是否在表单控制中
 * @param formItem
 * @param formId
 * @returns {boolean}
 */
export const isFormCtrl = (formItem, formId) => {
  for (let item of formItem){
    let tag = item.__config__.tagIcon
    if(['radio', 'select', 'switch']){
      let interact = item.__config__?.interact?.effect
      if(interact === 'showhide'){
        const condition = item.__config__.interact.condition
        const arr = Array.from(condition,t=>t.comp)
        const formIds = Array.prototype.concat.apply([],arr)
        if(formIds.includes(formId)){
          return true
        }
      }
    }
  }
  return false
}
